import { CornersDto, LatLngDto } from 'apis/VenueApi';
import * as turf from '@turf/turf';

export type ImgSizeDto = { width: number; height: number; angle: number; aspectRatio: number };
export type IXYPair = { x: number; y: number };
export interface IXYLatLngPair extends LatLngDto, IXYPair {}

export namespace PolygonUtil {
    /**
     * Calculate with corners
     */

    export function getRatio(width: number, height: number): ImgSizeDto {
        // Calculate the angle between AOB in radians
        const pointA = { x: 0, y: 0 };
        const pointB = { x: width, y: 0 };
        const pointO = { x: width / 2, y: height / 2 };
        const angleRad =
            Math.atan2(pointB.y - pointO.y, pointB.x - pointO.x) -
            Math.atan2(pointA.y - pointO.y, pointA.x - pointO.x);
        // Convert the angle from radians to degrees
        const angleDeg = (angleRad * 180) / Math.PI;
        return {
            width: width,
            height: height,
            angle: angleDeg,
            aspectRatio: width / height,
        };
    }

    export function fromFourCorner(corners: CornersDto) {
        return turf.polygon([
            [
                [corners.tl.lng, corners.tl.lat],
                [corners.tr.lng, corners.tr.lat],
                [corners.br.lng, corners.br.lat],
                [corners.bl.lng, corners.bl.lat],
                [corners.tl.lng, corners.tl.lat],
            ],
        ]);
    }

    export function fromThreeCorner(corners: CornersDto) {
        // Define the coordinates
        const topLeft = [corners.tl.lng, corners.tl.lat];
        const topRight = [corners.tr.lng, corners.tr.lat];
        const bottomLeft = [corners.bl.lng, corners.bl.lat];

        // Convert to Turf points
        const ptTopLeft = turf.point(topLeft);
        const ptTopRight = turf.point(topRight);
        const ptBottomLeft = turf.point(bottomLeft);

        // Calculate the distance between Top Left and Top Right
        const width = turf.distance(ptTopLeft, ptTopRight);

        // Calculate the bearing from Top Left to Bottom Left
        const bearingTopLeftToBottomLeft = turf.bearing(ptTopLeft, ptBottomLeft);

        // Calculate the bearing from Top Left to Top Right
        const bearingTopLeftToTopRight = turf.bearing(ptTopLeft, ptTopRight);

        // Calculate the angle between the sides of the rectangle
        const angleBetweenSides = bearingTopLeftToTopRight - bearingTopLeftToBottomLeft;

        // Calculate the bearing from Bottom Left to Bottom Right
        const bearingBottomLeftToBottomRight = bearingTopLeftToBottomLeft + angleBetweenSides;

        // Calculate the Bottom Right coordinate
        const bottomRight = turf.destination(ptBottomLeft, width, bearingBottomLeftToBottomRight);

        return turf.polygon([
            [topLeft, topRight, bottomRight.geometry.coordinates, bottomLeft, topLeft],
        ]);
    }

    export function fromTwoCornerNoRotate(corners: CornersDto) {
        function _createFromDiagonal(point1: LatLngDto, point2: LatLngDto) {
            const bboxPolygon = turf.bboxPolygon(
                turf.bbox(
                    turf.lineString([
                        [point1.lng, point1.lat],
                        [point2.lng, point2.lat],
                    ]),
                ),
            );
            return turf.polygon([
                [
                    bboxPolygon.geometry.coordinates[0][3],
                    bboxPolygon.geometry.coordinates[0][2],
                    bboxPolygon.geometry.coordinates[0][1],
                    bboxPolygon.geometry.coordinates[0][0],
                    bboxPolygon.geometry.coordinates[0][3],
                ],
            ]);
        }

        if (!!corners.tl && !!corners.tr) {
            // Case 1: top left + top right
            return _createFromDiagonal(corners.tl, corners.tr);
        } else if (!!corners.tl && !!corners.br) {
            // Case 2: top left + bottom right
            return _createFromDiagonal(corners.tl, corners.br);
        } else if (!!corners.tl && !!corners.bl) {
            // Case 3: top left + bottom left
            return _createFromDiagonal(corners.tl, corners.bl);
        } else if (!!corners.tr && !!corners.br) {
            // Case 4: top right + bottom right
            return _createFromDiagonal(corners.tr, corners.br);
        } else if (!!corners.tr && !!corners.bl) {
            // Case 5: top right + bottom left
            return _createFromDiagonal(corners.tr, corners.bl);
        } else if (!!corners.br && !!corners.bl) {
            // Case 6: bottom right + bottom left
            return _createFromDiagonal(corners.br, corners.bl);
        }
    }

    export function fromTwoCorner(corners: CornersDto, imgSize: ImgSizeDto) {
        const _createFromTwo = () => {
            if (!!corners.tl && !!corners.tr) {
                // Case 1: top left + top right
                return case1_TopLeftTopRight(corners.tl, corners.tr, imgSize);
            } else if (!!corners.tl && !!corners.br) {
                // Case 2: top left + bottom right
                return case2_TopLeftBottomRight(corners.tl, corners.br, imgSize);
            } else if (!!corners.tl && !!corners.bl) {
                // Case 3: top left + bottom left
                return case3_TopLeftBottomLeft(corners.tl, corners.bl, imgSize);
            } else if (!!corners.tr && !!corners.br) {
                // Case 4: top right + bottom right
                return case4_TopRightBottomRight(corners.tr, corners.br, imgSize);
            } else if (!!corners.tr && !!corners.bl) {
                // Case 5: top right + bottom left
                return case5_TopRightBottomLeft(corners.tr, corners.bl, imgSize);
            } else if (!!corners.br && !!corners.bl) {
                // Case 6: bottom right + bottom left
                return case6_BottomRightBottomLeft(corners.br, corners.bl, imgSize);
            }
        };
        const result = _createFromTwo();
        return turf.polygon([
            [
                result!.tl.geometry.coordinates,
                result!.tr.geometry.coordinates,
                result!.br.geometry.coordinates,
                result!.bl.geometry.coordinates,
                result!.tl.geometry.coordinates,
            ],
        ]);
    }

    function case1_TopLeftTopRight(pointTl: LatLngDto, pointTr: LatLngDto, imgSize: ImgSizeDto) {
        // Case 1 top left + top right
        const tl = turf.point([pointTl.lng, pointTl.lat]);
        const tr = turf.point([pointTr.lng, pointTr.lat]);
        const width = turf.distance(tl, tr);
        const height = width / imgSize.aspectRatio;
        const tlToTrBearing = turf.bearing(tl, tr);
        const tlToBlBearing = tlToTrBearing + 90;
        const bl = turf.destination(tl, height, tlToBlBearing);
        const br = turf.destination(tr, height, tlToBlBearing);
        return { tl, tr, br, bl };
    }

    function case2_TopLeftBottomRight(pointTl: LatLngDto, pointBr: LatLngDto, imgSize: ImgSizeDto) {
        // Case 2 top left + bottom right
        const tl = turf.point([pointTl.lng, pointTl.lat]);
        const br = turf.point([pointBr.lng, pointBr.lat]);
        const radius = turf.distance(tl, br) / 2;
        const minPoint = turf.midpoint(tl, br);
        const oToTlBearing = turf.bearing(minPoint, tl);
        const tr = turf.destination(minPoint, radius, oToTlBearing + imgSize.angle);
        const bl = turf.destination(minPoint, radius, oToTlBearing - imgSize.angle);
        return { tl, tr, br, bl };
    }

    function case3_TopLeftBottomLeft(pointTl: LatLngDto, pointBl: LatLngDto, imgSize: ImgSizeDto) {
        // Case 3 top left + bottom left
        const tl = turf.point([pointTl.lng, pointTl.lat]);
        const bl = turf.point([pointBl.lng, pointBl.lat]);
        const height = turf.distance(tl, bl);
        const width = imgSize.aspectRatio * height;
        const blToTlBearing = turf.bearing(bl, tl);
        const tlToTrBearing = blToTlBearing + 90;
        const tr = turf.destination(tl, width, tlToTrBearing);
        const br = turf.destination(bl, width, tlToTrBearing);
        return { tl, tr, br, bl };
    }

    function case4_TopRightBottomRight(
        pointTr: LatLngDto,
        pointBr: LatLngDto,
        imgSize: ImgSizeDto,
    ) {
        // Case 4 top right + bottom right
        const tr = turf.point([pointTr.lng, pointTr.lat]);
        const br = turf.point([pointBr.lng, pointBr.lat]);
        const height = turf.distance(tr, br);
        const width = imgSize.aspectRatio * height;
        const brToTrBearing = turf.bearing(br, tr);
        const brToBlBearing = brToTrBearing - 90;
        const tl = turf.destination(tr, width, brToBlBearing);
        const bl = turf.destination(br, width, brToBlBearing);
        return { tl, tr, br, bl };
    }

    function case5_TopRightBottomLeft(pointTr: LatLngDto, pointBl: LatLngDto, imgSize: ImgSizeDto) {
        // Case 5 top right + bottom left
        const tr = turf.point([pointTr.lng, pointTr.lat]);
        const bl = turf.point([pointBl.lng, pointBl.lat]);
        const radius = turf.distance(tr, bl) / 2;
        const minPoint = turf.midpoint(tr, bl);
        const oToTrBearing = turf.bearing(minPoint, tr);
        const tl = turf.destination(minPoint, radius, oToTrBearing - imgSize.angle);
        const br = turf.destination(minPoint, radius, oToTrBearing + imgSize.angle);
        return { tl, tr, br, bl };
    }

    function case6_BottomRightBottomLeft(
        pointBr: LatLngDto,
        pointBl: LatLngDto,
        imgSize: ImgSizeDto,
    ) {
        // Case 6 bottom right + bottom left
        const br = turf.point([pointBr.lng, pointBr.lat]);
        const bl = turf.point([pointBl.lng, pointBl.lat]);
        const width = turf.distance(br, bl);
        const height = width / imgSize.aspectRatio;
        const blToBrBearing = turf.bearing(bl, br);
        const brToRrBearing = blToBrBearing - 90;
        const tl = turf.destination(bl, height, brToRrBearing);
        const tr = turf.destination(br, height, brToRrBearing);
        return { tl, tr, br, bl };
    }

    /**
     * Calculate with anchors
     */

    function getBearing(a: [number, number], b: [number, number]) {
        const mAngle = Math.atan2(b[1] - a[1], b[0] - a[0]);
        const mBearing = ((mAngle * 180) / Math.PI + 90) % 360;
        return mBearing;
    }

    function calculateCorners(
        o1: IXYLatLngPair,
        o2: IXYLatLngPair,
        imgWidth: number,
        imgHeight: number,
    ) {
        const localCorners: [number, number][] = [
            [0, 0],
            [imgWidth, 0],
            [imgWidth, imgHeight],
            [0, imgHeight],
        ];

        // Extract the first three points
        const { x: x1, y: y1, lat: lat1, lng: lng1 } = o1;
        const { x: x2, y: y2, lat: lat2, lng: lng2 } = o2;

        const localPoint1: [number, number] = [x1, y1];
        const localPoint2: [number, number] = [x2, y2];
        const localBearing = getBearing([x1, y1], [x2, y2]);
        const localDistance = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);

        const geoPoint1 = turf.point([lng1, lat1]);
        const geoPoint2 = turf.point([lng2, lat2]);
        const geoBearing = turf.bearing(geoPoint1, geoPoint2);
        const geoDistance = turf.distance(geoPoint1, geoPoint2, {
            units: 'meters',
        });

        const pxInMeter = localDistance / geoDistance;
        const meterInPx = 1 / pxInMeter;
        const angle = 360 - (localBearing - geoBearing);

        const calculateGeoPos = (
            localPt: [number, number],
            geoPt: turf.Coord,
            targetCorner: [number, number],
        ) => {
            const mDistance = Math.sqrt(
                (localPt[0] - targetCorner[0]) ** 2 + (localPt[1] - targetCorner[1]) ** 2,
            );
            const mAngle = getBearing(localPt, targetCorner) + angle;

            return turf.destination(geoPt, mDistance * meterInPx, mAngle, { units: 'meters' });
        };

        const tl_pt1 = calculateGeoPos(localPoint1, geoPoint1, localCorners[0]);
        const tr_pt1 = calculateGeoPos(localPoint1, geoPoint1, localCorners[1]);
        const br_pt1 = calculateGeoPos(localPoint1, geoPoint1, localCorners[2]);
        const bl_pt1 = calculateGeoPos(localPoint1, geoPoint1, localCorners[3]);

        const tl_pt2 = calculateGeoPos(localPoint2, geoPoint2, localCorners[0]);
        const tr_pt2 = calculateGeoPos(localPoint2, geoPoint2, localCorners[1]);
        const br_pt2 = calculateGeoPos(localPoint2, geoPoint2, localCorners[2]);
        const bl_pt2 = calculateGeoPos(localPoint2, geoPoint2, localCorners[3]);

        return {
            tl: [tl_pt1, tl_pt2],
            tr: [tr_pt1, tr_pt2],
            br: [br_pt1, br_pt2],
            bl: [bl_pt1, bl_pt2],
        };
    }

    export function fromAnchors(
        anchors: IXYLatLngPair[],
        imgSize: Omit<ImgSizeDto, 'angle' | 'aspectRatio'>,
    ) {
        const cornersArray: any = { tl: [], tr: [], br: [], bl: [] };
        for (let i = 0; i < anchors.length; i++) {
            if (i + 1 < anchors.length) {
                const p1 = anchors[i],
                    p2 = anchors[i + 1];
                const res = calculateCorners(p1, p2, imgSize.width, imgSize.height);
                cornersArray.tl.push(...res.tl);
                cornersArray.tr.push(...res.tr);
                cornersArray.br.push(...res.br);
                cornersArray.bl.push(...res.bl);
            }
        }

        const centroidTl = turf.centroid(turf.featureCollection(cornersArray.tl)).geometry
            .coordinates;
        const centroidTr = turf.centroid(turf.featureCollection(cornersArray.tr)).geometry
            .coordinates;
        const centroidBr = turf.centroid(turf.featureCollection(cornersArray.br)).geometry
            .coordinates;
        const centroidBl = turf.centroid(turf.featureCollection(cornersArray.bl)).geometry
            .coordinates;

        return turf.polygon([[centroidTl, centroidTr, centroidBr, centroidBl, centroidTl]]);
    }
}
