import L from 'leaflet';
import { useLeafletContext } from '@react-leaflet/core';
import { ControlPosition } from 'leaflet';
import { LayerGroup, LayersControl, TileLayer } from 'react-leaflet';
import { memo, useEffect, useRef } from 'react';
import MeasurementTool, { MeasurementToolRef } from './MeasurementTool';

export type MapTileControlProps = {
    position?: ControlPosition;
    initTier?: string;
    editable?: boolean;
    visible?: boolean;
    scale?: boolean;
    ruler?: boolean;
};

export const tilesList = [
    {
        key: 'CartoDB.Voyager',
        name: 'CartoDB Voyager',
        url: 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
        attribution: '&copy; <a href="https://carto.com/attributions">CARTO</a>',
    },
    {
        key: 'CartoDB.Positron',
        name: 'CartoDB Positron',
        url: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
        attribution: '&copy; <a href="https://carto.com/attributions">CARTO</a>',
    },
    {
        key: 'OpenStreetMap.Mapnik',
        name: 'Open Street Map - Street',
        url: 'http://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png',
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    },
    {
        key: 'Mapbox.dark',
        name: 'Mapbox - Dark',
        url:
            'https://api.mapbox.com/styles/v1/mapbox/dark-v11/tiles/{z}/{x}/{y}?access_token=' +
            process.env.REACT_APP_MAPBOX_ACCESS_KEY,
        attribution: '&copy; <a href="https://www.mapbox.com/" target="_blank">Mapbox</a>',
    },
    {
        key: 'Mapbox.SatelliteStreet',
        name: 'Mapbox - Satellite',
        url:
            'https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12/tiles/{z}/{x}/{y}?access_token=' +
            process.env.REACT_APP_MAPBOX_ACCESS_KEY,
        attribution: '&copy; <a href="https://www.mapbox.com/" target="_blank">Mapbox</a>',
    },
    {
        key: 'Mapbox.Street',
        name: 'Mapbox - Street',
        url:
            'https://api.mapbox.com/styles/v1/mapbox/streets-v12/tiles/{z}/{x}/{y}?access_token=' +
            process.env.REACT_APP_MAPBOX_ACCESS_KEY,
        attribution: '&copy; <a href="https://www.mapbox.com/" target="_blank">Mapbox</a>',
    },
    {
        key: 'Google.Street',
        name: 'Google - Street',
        url: 'https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        attribution: '&copy; <a href="https://mapsplatform.google.com/" target="_blank">Google</a>',
    },
    {
        key: 'HK.LandsD',
        name: 'Lands Department HK',
        url: 'https://mapapi.geodata.gov.hk/gs/api/v1.0.0/xyz/basemap/WGS84/{z}/{x}/{y}.png',
        overlayUrl:
            'https://mapapi.geodata.gov.hk/gs/api/v1.0.0/xyz/label/hk/en/WGS84/{z}/{x}/{y}.png',
        attribution:
            '<a href="https://api.portal.hkmapservice.gov.hk/disclaimer" target="_blank" class="copyright-url">© Map information from Lands Department</a>',
    },
];

const MapTileControl: React.FC<MapTileControlProps> = (props) => {
    const measurementRef = useRef<MeasurementToolRef>();
    const context = useLeafletContext();
    const showScale = props.scale !== false && props.ruler !== false;
    useEffect(() => {
        if (context.map && props.scale !== false) {
            const scaleControl = L.control
                .scale({ position: 'bottomright', imperial: false })
                .addTo(context.map);
            const scaleEl = scaleControl.getContainer();
            if (props.ruler !== false && scaleEl) {
                scaleEl.title = 'Measure distance';
                scaleEl.classList.add('cursor-pointer');
                scaleEl.addEventListener('click', (e) => {
                    if (measurementRef.current) {
                        setTimeout(() => {
                            measurementRef.current?.startMeasurement();
                        }, 500);
                    }
                });
            }
        }
    }, [measurementRef]);
    const _getDisplayTier = () => {
        return props.initTier
            ? tilesList.find((el) => el.key === props.initTier) ?? tilesList[0]
            : tilesList[0];
    };
    if (props.visible === false) {
        const mTarget = _getDisplayTier();
        return (
            <LayerGroup>
                <TileLayer
                    maxZoom={25}
                    url={mTarget.url}
                    attribution={mTarget.attribution}
                    subdomains={mTarget.subdomains ?? 'abc'}
                />
                {mTarget.overlayUrl ? <TileLayer url={mTarget.overlayUrl} /> : undefined}
                {/* {showScale ? <MeasurementTool ref={measurementRef as any} /> : undefined} */}
            </LayerGroup>
        );
    }
    const mFilteredList = props.editable === false ? [_getDisplayTier()] : tilesList;
    return (
        <LayersControl position={props.position ?? 'bottomleft'}>
            {mFilteredList.map((el, index) => {
                return (
                    <LayersControl.BaseLayer
                        key={el.key}
                        name={el.name}
                        checked={props.initTier ? props.initTier === el.name : index === 0}
                    >
                        <LayerGroup>
                            <TileLayer
                                maxZoom={25}
                                url={el.url}
                                attribution={el.attribution}
                                subdomains={el.subdomains ?? 'abc'}
                            />
                            {el.overlayUrl ? <TileLayer url={el.overlayUrl} /> : undefined}
                        </LayerGroup>
                    </LayersControl.BaseLayer>
                );
            })}
            {/* {showScale ? <MeasurementTool ref={measurementRef as any} /> : undefined} */}
        </LayersControl>
    );
};

export default memo(MapTileControl);