import {
    HomeFilled,
    IdcardFilled,
    ProductFilled,
    PlaySquareFilled,
    BuildFilled,
} from '@ant-design/icons';

export function getPrivateRoute(activeProject: any, isSuperAdmin: boolean, isEditor: boolean) {
    return [
        {
            path: '/home',
            name: 'Home',
            icon: <HomeFilled />,
            component: './home',
        },
        {
            path: '/auth',
            name: 'Access',
            icon: <IdcardFilled />,
            hideInMenu: !isSuperAdmin,
            routes: [
                {
                    path: '/auth/users',
                    name: 'IAM',
                    component: './auth/users',
                },
            ],
        },
        {
            path: '/project',
            name: 'Project',
            icon: <ProductFilled />,
            hideInMenu: !activeProject,
            routes: [
                {
                    path: '/project/',
                    name: 'Overview',
                    component: './project',
                },
                {
                    path: '/project/permission',
                    name: 'Permissions',
                    component: './project/permission',
                },
                {
                    path: '/project/license',
                    name: 'Licenses',
                    component: './project/license',
                },
                {
                    path: '/project/venue',
                    name: 'Venues',
                    component: './project/venue',
                },
            ],
        },
        {
            path: '/analysis/session',
            name: 'View session',
            icon: <PlaySquareFilled />,
            component: './analysis/session',
        },
        {
            path: '/crowd-source',
            name: 'Crowdsource',
            icon: <BuildFilled />,
            hideInMenu: !isSuperAdmin,
            component: './crowd-source',
        },
    ];
}
