import React, { useEffect, useState, useRef, CSSProperties } from 'react';
import { CaretRightOutlined, PauseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Row, Button, Typography, Dropdown, Tooltip, Flex, Tag } from 'antd';
const { Text } = Typography;
import { formatTime } from 'utils/DateUtils';

const PLAYBACK_STEP = 100; // Update progress every 100 milliseconds
let _progressInterval: NodeJS.Timer;
let processingTime = 0;

type PlayerControlProps = {
    title: string;
    duration: number; // ms
    style?: CSSProperties;
    overrideMs: number;
    onProgressChange?: (ratio: number, ms: number) => void;
    onUpdateSpeed: (speed: number) => void;
    onUpdateIsPlaying: (isPlaying: boolean) => void;
};

export const PlayerTooltips: React.FC<any> = (props) => {
    return (
        <Tooltip
            placement="bottom"
            title={
                <Flex vertical>
                    keyboard shortcuts:
                    <span>
                        <Tag title="Right arrow">→</Tag>: Seek forward 5 seconds
                    </span>
                    <span>
                        <Tag title="Left arrow">←</Tag>: Seek backward 5 seconds
                    </span>
                    <span>
                        <Tag title="Right angle brackets">{'>'}</Tag>: Seek forward 1 seconds
                    </span>
                    <span>
                        <Tag title="Left angle brackets">{'<'}</Tag>: Seek backward 1 seconds
                    </span>
                    <span>
                        <Tag title="Space bar">{'␣'}</Tag>: Play / Pause
                    </span>
                </Flex>
            }
        >
            <InfoCircleOutlined style={{ color: 'rgba(0, 0, 0, 0.45)' }} />
        </Tooltip>
    );
};

export const PlayerControl: React.FC<PlayerControlProps> = (props) => {
    const playerRef = useRef<HTMLDivElement>(null);
    const progressBarRef = useRef<HTMLDivElement>(null);
    const [playing, setPlaying] = useState<boolean>(false);
    const [speed, setSpeed] = useState<number>(1);
    // const [processingTime, setCurrentTime] = useState<number>(0);
    const [isDragging, setIsDragging] = useState<boolean>(false);

    useEffect(() => {
        const handleKeyDown = (e: any) => {
            // Offset 1s
            const key = e.key;
            if (key === 'ArrowLeft') {
                e.preventDefault();
                processingTime = Math.max(processingTime - 5000, 0);
            } else if (key === 'ArrowRight') {
                e.preventDefault();
                processingTime = Math.min(processingTime + 5000, props.duration);
            } else if (key === ',') {
                e.preventDefault();
                processingTime = Math.max(processingTime - 1000, 0);
            } else if (key === '.') {
                e.preventDefault();
                processingTime = Math.min(processingTime + 1000, props.duration);
            } else if (key === ' ') {
                e.preventDefault();
                setPlaying((val) => !val);
            }
            checkIfProgressChanged();
        };
        window.addEventListener('mouseup', _handleMouseUp);
        document.addEventListener('keydown', handleKeyDown, true);
        return () => {
            window.removeEventListener('mouseup', _handleMouseUp);
            document.removeEventListener('keydown', handleKeyDown, true);
            clearInterval(_progressInterval);
        };
    }, []);

    /**
     * Player helper function
     */
    useEffect(() => {
        clearInterval(_progressInterval);
        if (playing) {
            // if (processingTime === 0 || processingTime >= props.duration) {
            //     // Start play
            //     //setCurrentTime(0);
            //     processingTime = 0;
            //     _updateProgress();
            //     _progressInterval = setInterval(_updateProgress, PLAYBACK_STEP);
            // } else {
            //     // Resume
            //     _progressInterval = setInterval(_updateProgress, PLAYBACK_STEP);
            // }
            _progressInterval = setInterval(_updateProgress, PLAYBACK_STEP);

            checkIfProgressChanged();
        } else {
            // Pause play do nothing
        }

        props.onUpdateIsPlaying(playing);
    }, [playing]);


    function checkIfProgressChanged(){
        const ratio = processingTime / props.duration;
        if (props.onProgressChange) {
            props.onProgressChange(ratio, processingTime);
        }
    }

    function stop(reset: boolean = true) {
        clearInterval(_progressInterval);
        if (reset) {
            //setCurrentTime(0);
            processingTime = 0;
        }
        _updateProgress();
        setPlaying(false);
    }

    /**
     * Handle change speed
     */
    useEffect(() => {
        if (_progressInterval && playing) {
            clearInterval(_progressInterval);
            _progressInterval = setInterval(_updateProgress, PLAYBACK_STEP);
        }
    }, [speed]);

    /**
     * Player progress control
     */
    function _updateProgress() {
        // Increment by `PLAYBACK_STEP` milliseconds multiplied by playback speed

        if (processingTime + PLAYBACK_STEP * speed > props.duration) {
            processingTime = props.duration;
        } else {
            processingTime += PLAYBACK_STEP * speed;
        }

        if (progressBarRef.current && props.duration > 0) {
            const ratio = processingTime / props.duration;
            if (props.onProgressChange) {
                props.onProgressChange(ratio, processingTime);
            }
        }
        // if (processingTime >= props.duration) {
        //     clearInterval(_progressInterval);
        //     setPlaying(false);
        // }
    }

    useEffect(() => {
        processingTime = props.overrideMs;
        if (progressBarRef.current && props.duration > 0) {
            let progress = props.overrideMs / props.duration;
            progressBarRef.current.style.width = `${progress * 100}%`;
        }
    }, [props.overrideMs]);

    /**
     * Player progress control (drag and drop)
     */
    function _handleMouseMove(event: any) {
        if (!isDragging) return;
        _adjustCurrentTime(event);
    }
    function _handleMouseUp(event: any) {
        setIsDragging(false);
    }
    function _handleMouseDown(event: any) {
        setIsDragging(true);
        _adjustCurrentTime(event);
    }
    function _adjustCurrentTime(event: any) {
        if (!playerRef.current || !progressBarRef.current) return;
        const playerEl = playerRef.current;
        const progressWidth = Math.max(
            0,
            Math.min(playerEl.offsetWidth, event.clientX - playerEl.getBoundingClientRect().left),
        );
        const ratio = progressWidth / playerEl.offsetWidth;
        // setCurrentTime(ratio * props.duration);
        processingTime = ratio * props.duration;

        _updateProgress();
    }
    useEffect(() => {
        window.addEventListener('mousemove', _handleMouseMove);
        return () => {
            window.removeEventListener('mousemove', _handleMouseMove);
        };
    }, [_handleMouseMove]);

    const updateSpeed = (speed: number) => {
        setSpeed(speed);
        props.onUpdateSpeed(speed);
    };

    const _renderSpeedControl = () => {
        return (
            <Dropdown
                menu={{
                    selectedKeys: [`${speed}`],
                    items: [
                        { key: '1', label: '1x', onClick: () => updateSpeed(1) },
                        { key: '2', label: '2x', onClick: () => updateSpeed(2) },
                        { key: '5', label: '5x', onClick: () => updateSpeed(5) },
                        { key: '10', label: '10x', onClick: () => updateSpeed(10) },
                    ],
                }}
                placement="top"
            >
                <Button style={{ width: '4em' }}>{speed}x</Button>
            </Dropdown>
        );
    };

    return (
        <>
            {props.title ? (
                <Row>
                    <Text>{props.title}</Text>
                </Row>
            ) : undefined}
            <Flex gap="middle">
                <Button
                    icon={playing ? <PauseOutlined /> : <CaretRightOutlined />}
                    type={playing ? 'default' : 'primary'}
                    style={{ paddingLeft: '2em', paddingRight: '2em' }}
                    onClick={() => setPlaying((val) => !val)}
                />
                <div
                    ref={playerRef}
                    className="player"
                    style={{ flex: 1 }}
                    onMouseDown={_handleMouseDown}
                >
                    <div ref={progressBarRef} className="player-progress"></div>
                </div>
                <Text
                    style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        minWidth: '8em',
                    }}
                >
                    <span>{formatTime(props.overrideMs)}</span>/
                    <span>{formatTime(props.duration)}</span>
                </Text>
                {_renderSpeedControl()}
            </Flex>
        </>
    );
};
