import React from 'react';
import { Tag, Typography } from 'antd';
import { AppleFilled, MobileOutlined, AndroidFilled, ProductFilled } from '@ant-design/icons';

export interface VersionRangeTagProps {
    type?: string;
    from?: string;
    to?: string;
}

export const VersionRangeTag: React.FC<VersionRangeTagProps> = (props) => {
    const { type, from, to } = props;

    const _getText = () => {
        const _validVersion = (input: any) => {
            if (!input || typeof input !== 'string') return false;
            return new RegExp(/^\d+(\.\d+)*$/).test(input);
        };
        const isValidFrom = _validVersion(from);
        const isValidTo = _validVersion(to);

        if (isValidFrom && isValidTo) {
            if (from === to) {
                return from;
            } else {
                return `${from} - ${to}`;
            }
        } else if (isValidFrom && !isValidTo) {
            return `≥ ${from}`;
        } else if (!isValidFrom && isValidTo) {
            return `≤ ${to}`;
        } else if (!isValidFrom && !isValidTo) {
            return `All`;
        }
    };

    let mIcon, mBgColor;
    if (type === 'IOS') {
        mIcon = <AppleFilled title="iOS" color="white" />;
        mBgColor = 'rgba(0, 0, 0, 0.88)';
    } else if (type === 'AOS') {
        mIcon = <AndroidFilled title="iOS" color="white" />;
        mBgColor = '#4faf53';
    } else if (type === 'SDK') {
        mIcon = <ProductFilled title="SDK" color="white" />;
        mBgColor = '#0958d9';
    } else {
        mIcon = <MobileOutlined title="ANY" color="white" />;
        mBgColor = '#8c8c8c';
    }

    return (
        <Tag icon={mIcon} color={mBgColor} bordered={false} style={{ borderRadius: 20 }} title={props.type ?? 'Any'}>
            {_getText()}
        </Tag>
    );
};
