import React, { useEffect, useState, useRef, CSSProperties } from 'react';
import { ProForm, ProFormText, ProFormSwitch, ProFormDigit } from '@ant-design/pro-components';
import { Row, Button, Typography, Dropdown, Tooltip, Flex, Tag } from 'antd';
import { CaretRightOutlined, PauseOutlined, InfoCircleOutlined } from '@ant-design/icons';
const { Text } = Typography;
import { formatTime } from 'utils/DateUtils'

const PLAYBACK_STEP = 100; // Update progress every 100 milliseconds
let playingVideoInterval:NodeJS.Timer;
let processingTime = 0;

export type VideoRecordControlProps = {
    videoRecord: any;
    duration : number;
    playSpeed : number;
    currentVideoTime : number;
    isSynchronizePlaying : boolean;
    isBlueDotPlaying : boolean;
    onUpdateVideo: (time : number) => void;
    onUpdateIsSynchronizePlaying: (isSync : boolean) => void;
};

export const VideoRecordControl: React.FC<VideoRecordControlProps> = (props) => {
    const [isPlaying, setIsPlaying] = useState<Boolean>(false);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const videoRecordControlBarElement = useRef<any>();
    const videoRecordProgressElement = useRef<any>();

    // const [latestTime, setLatestTime] = useState<number>(0);

    useEffect(() => {
        window.addEventListener('mouseup', handleMouseUp);
        return () => {
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, []);

    useEffect(() => {
        //reset
        processingTime = 0;
        props.onUpdateVideo(processingTime);
        onChnageSynchronizePlaying(false);
    }, [props.videoRecord]);

    useEffect(() => {
        if(isPlaying){
            processingTime = props.currentVideoTime;
            playingVideoInterval = setInterval(()=>{
                if(videoRecordControlBarElement.current == null){
                    clearInterval(playingVideoInterval);
                }

                if(processingTime + PLAYBACK_STEP * props.playSpeed >= props.duration){
                    processingTime = props.duration;
                }
                else{
                    processingTime += PLAYBACK_STEP * props.playSpeed;
                }
                //processingTime += PLAYBACK_STEP * props.playSpeed;

                //setLatestTime(processingTime);
                if(videoRecordProgressElement.current){
                    videoRecordProgressElement.current.style.width = `${processingTime / props.duration * 100}%`;
                }
                props.onUpdateVideo(processingTime);
            },PLAYBACK_STEP);
        }
        else{
            clearInterval(playingVideoInterval);
        }
    }, [isPlaying]);


    useEffect(() => {
        if(!isDragging){
            if(videoRecordControlBarElement.current && videoRecordProgressElement.current){
                let progress = props.currentVideoTime / props.duration;
                if(progress < 0){
                    videoRecordProgressElement.current.style.width = "0%";
                }
                else{
                    videoRecordProgressElement.current.style.width = `${progress  * 100}%`;
                }
            }
        }
    }, [props.currentVideoTime]);

    const handleMouseUp = (event: any) => {
        setIsDragging(false);
    }

    const handleMouseDown = (event: any)=>{
        setIsDragging(true);
        updateProgessBar(event);
    }

    const handleMouseMove = (event: any)=>{
        if(isDragging){
            updateProgessBar(event);
        }
    }

    const updateProgessBar = (event: any)=>{
        if(videoRecordControlBarElement.current && videoRecordProgressElement.current){
            const progressWidth = Math.max(
                0,
                Math.min(videoRecordControlBarElement.current.offsetWidth, event.clientX - videoRecordControlBarElement.current.getBoundingClientRect().left),
            );
            let progress = progressWidth / videoRecordControlBarElement.current.offsetWidth;
            videoRecordProgressElement.current.style.width = `${progress  * 100}%`;
            processingTime = props.duration * progress;
            if(!isPlaying){
                //setLatestTime(processingTime);
                props.onUpdateVideo(processingTime);
            }
        }
    }

    const onChnageSynchronizePlaying = (isSync : boolean) => {
        props.onUpdateIsSynchronizePlaying(isSync);
        if(isSync){
            setIsPlaying(false);
        }
    } 

    return (
        <>
        
            <Row>
                <Text>
                    Record video
                </Text>
            </Row>
            <Flex gap="middle">
                <Button
                    icon={isPlaying ? <PauseOutlined /> : <CaretRightOutlined />}
                    type={isPlaying ? 'default' : 'primary'}
                    style={props.isSynchronizePlaying ? {paddingLeft: '2em', paddingRight: '2em', visibility:"hidden"} : {paddingLeft: '2em', paddingRight: '2em'}}
                    onClick={() => {
                        setIsPlaying((val) => !val)
                    }}
                    disabled={props.isSynchronizePlaying}
                    
                />
                <div
                    ref={videoRecordControlBarElement}
                    className="player"
                    style={{ flex: 1, }}
                    onMouseDown={(event) =>{
                        handleMouseDown(event);
                    }}
                    onMouseMove={(event) =>{
                        handleMouseMove(event);
                    }}
                >
                    <div ref={videoRecordProgressElement} className="player-progress"></div>
                </div>

                <Text style={{ display: 'inline-flex', alignItems: 'center', minWidth:"8em" }}>
                    <span>{formatTime(props.currentVideoTime)}</span>/<span>{formatTime(props.duration)}</span>
                </Text>
                <div style={{width: '4em'}}/>
            </Flex>

            <Row>
                <ProFormSwitch
                    name="enabled"
                    fieldProps={{
                        onChange: (e) => {
                            onChnageSynchronizePlaying(e);
                        },
                    }}
                    // label={"Synchronize " +  (props.isBlueDotPlaying ? " (Pause playback to change) " : "")}
                    label={"Synchronize"}
                    disabled={props.isBlueDotPlaying}
                />
            </Row>
        </>
    );
};
