import React from 'react';
import { Link } from 'react-router-dom';

const NoFoundPage: React.FC = () => (
    <div style={{ margin: '5rem', color: '#aaa' }}>
        <h1>Page not found :(</h1>
        <Link to="/" style={{ color: '#aaa' }}>
            Go Home
        </Link>
    </div>
);

export default NoFoundPage;
