import { PageContainer } from '@ant-design/pro-components';
import React, { useEffect, useState } from 'react';
import { useAuth } from 'providers/AuthProvider';
import { useAppState } from 'providers/AppStateProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import { Alert, Descriptions, Empty, Flex } from 'antd';
import VenueContextProvider from 'providers/VenueProvider';
import RuntimeConfigSection from './venue/RuntimeConfigSection';

const LicenseConfigScreen: React.FC = () => {
    const { isSuperAdmin } = useAuth();
    const { project } = useAppState();
    const { state } = useLocation();
    const navigate = useNavigate();
    const [licenseId, setLicenseId] = useState<string | undefined>();

    useEffect(() => {
        if (!!state?.licenseId) {
            setLicenseId(state.licenseId);
        }
        return () => setLicenseId(undefined);
    }, [state]);

    const isAllowed = licenseId && project && isSuperAdmin();
    const mKeyParts = (project?.licenses.find((el) => el.id === licenseId)?.accessKey ?? '').split(
        '.',
    );
    return (
        <PageContainer
            onBack={() => navigate('/project/license', { replace: true })}
            title={'License - Runtime config'}
            breadcrumb={{
                items: [{ title: 'Project' }, { title: 'License' }, { title: 'Config' }],
            }}
            content={
                <Flex vertical>
                    <Descriptions>
                        <Descriptions.Item label="Key prefix">
                            {mKeyParts?.length > 0 ? `${mKeyParts[0]}...` : ''}
                        </Descriptions.Item>
                    </Descriptions>
                    <Alert
                        type="warning"
                        message={'Noted: venue runtime config might override the config below.'}
                    />
                </Flex>
            }
        >
            {isAllowed ? (
                <VenueContextProvider>
                    <RuntimeConfigSection parentId={licenseId} enableSimulate isLicense />
                </VenueContextProvider>
            ) : (
                <Empty image={false} description="Not available. please go back and try again." />
            )}
        </PageContainer>
    );
};

export default LicenseConfigScreen;
