import { CheckCard, ProCard, ProDescriptions } from '@ant-design/pro-components';
import React, { useEffect } from 'react';
import {
    Space,
    Button,
    message,
    Tag,
    Divider,
    Spin,
    Empty,
    Typography,
    Flex,
    Dropdown,
} from 'antd';
import {
    DeleteOutlined,
    EditOutlined,
    PlusOutlined,
    DragOutlined,
    ExclamationCircleOutlined,
    SettingOutlined,
} from '@ant-design/icons';
import { useAuth } from 'providers/AuthProvider';
import { useAppState } from 'providers/AppStateProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import { ResourceRole } from 'apis/UserApi';
import { useVenueState } from 'providers/VenueProvider';
import {
    AddProjVenueMapModule,
    DeleteProjVenueMapModule,
} from 'components/dialogs/HandleProjVenueMapDialog';
import FloorPlanMapView from 'components/maps/FloorPlanMapView';
import AlignInfoDialog from 'components/dialogs/AlignInfoDialog';

const VenueMapScreen: React.FC = () => {
    const { hasAccess } = useAuth();
    const { fetchVenue, venue, selectWorkingMap, workingMap } = useVenueState();
    const { activeProject, project, refreshProject, isMobile, isTablet } = useAppState();
    const { state } = useLocation();
    const navigate = useNavigate();
    const isEditor = hasAccess(ResourceRole.EDITOR, activeProject?.projId);

    useEffect(() => {
        if (!!state?.mapId && !!venue) {
            selectWorkingMap(venue.maps.find((el) => el.id === state.mapId));
        } else if (!!venue && !workingMap) {
            selectWorkingMap(venue.maps[0]);
        }
    }, []);

    function _renderVenueMaps() {
        return (venue?.maps ?? []).map((el, index) => {
            return (
                <CheckCard
                    key={`map-card-${index}`}
                    checked={workingMap?.id === el.id}
                    onClick={() => selectWorkingMap(el)}
                    style={{ width: '100%', padding: 0, paddingInline: 0 }}
                    title={el.name}
                    subTitle={<Typography.Text type="secondary">Level: {el.level}</Typography.Text>}
                    description={
                        <Typography.Text
                            className="text-08"
                            copyable={{ text: el.id }}
                        >{`ID: ${el.id}`}</Typography.Text>
                    }
                />
            );
        });
    }

    function _renderAlignMap() {
        if (!isEditor || !workingMap?.mapImg) return undefined;
        return (
            <Flex align="center" gap={4}>
                <Button
                    key="et-map-btn-1"
                    className={isMobile ? 'w100' : undefined}
                    icon={<DragOutlined />}
                    onClick={() => {
                        navigate(`/project/venue/${workingMap.venueId}/map/align`, {
                            state: workingMap,
                        });
                    }}
                >
                    Alignment
                </Button>
                {isMobile ? undefined : <Divider type="vertical" />}
            </Flex>
        );
    }

    function _renderEditMap(isBtn: boolean = true) {
        if (!isEditor || !project || !venue || !workingMap) return undefined;
        return (
            <AddProjVenueMapModule
                key={'btn-edit-venueMap'}
                projectId={project.id}
                isEdit={true}
                venueId={workingMap.venueId}
                editingVenueMap={workingMap}
                trigger={
                    isBtn ? (
                        <Button icon={<EditOutlined />}>Edit</Button>
                    ) : (
                        <Space>
                            <EditOutlined />
                            <span>Edit</span>
                        </Space>
                    )
                }
                onSuccess={() => {
                    fetchVenue(venue.id);
                    message.success('Venue map edited');
                }}
            />
        );
    }

    function _renderDeleteMap(isBtn: boolean = true) {
        if (!isEditor || !activeProject) return undefined;
        return (
            <DeleteProjVenueMapModule
                key="btn-delete-venueMap"
                processingVenueMap={workingMap}
                onSuccess={() => {
                    fetchVenue();
                    selectWorkingMap(undefined);
                    message.success('Map deleted');
                }}
                trigger={
                    isBtn ? (
                        <Button danger icon={<DeleteOutlined />}>
                            Delete
                        </Button>
                    ) : (
                        <Space>
                            <DeleteOutlined />
                            Delete
                        </Space>
                    )
                }
            />
        );
    }

    function _renderActionMenu() {
        if (!workingMap || !isEditor) return undefined;
        return (
            <Dropdown
                menu={{
                    items: [
                        {
                            key: 'Alignment',
                            label: 'Alignment',
                            icon: <DragOutlined />,
                            onClick: () => {
                                navigate(`/project/venue/${workingMap.venueId}/map/align`, {
                                    state: workingMap,
                                });
                            },
                        },
                        { key: 'Edit', label: _renderEditMap(false) },
                        { key: 'Delete', label: _renderDeleteMap(false), danger: true },
                    ],
                }}
                trigger={['click']}
            >
                <SettingOutlined />
            </Dropdown>
        );
    }

    function _renderMapInfo() {
        if (!workingMap) return undefined;
        const tags = (
            <Space size="small">
                {!workingMap.mapImg && !workingMap.boundary ? (
                    <Tag icon={<ExclamationCircleOutlined />} color="warning">
                        No floor plan
                    </Tag>
                ) : undefined}
                {workingMap.mapImg ? (
                    <Tag color="blue">Floor Plan</Tag>
                ) : workingMap.boundary ? (
                    <Tag color="purple">Polygon</Tag>
                ) : undefined}
            </Space>
        );

        return (
            <div style={{ position: 'absolute', padding: 16, zIndex: 500 }} className="w100">
                <ProCard boxShadow>
                    <Flex align="center" justify="space-between">
                        <Flex gap={8} wrap="wrap">
                            <Typography.Text strong>{workingMap?.name}</Typography.Text>
                            {isTablet ? undefined : tags}
                        </Flex>
                        <Flex
                            key="map-info-extra-1"
                            gap={8}
                            wrap="wrap"
                            justify="end"
                            align="center"
                        >
                            <AlignInfoDialog
                                name={workingMap?.name}
                                imgUrl={workingMap?.mapImg}
                                alignResult={workingMap.mapAlign}
                                precise
                            />
                            {isTablet || isMobile ? (
                                _renderActionMenu()
                            ) : (
                                <>
                                    {_renderAlignMap()}
                                    {_renderEditMap()}
                                    {_renderDeleteMap()}
                                </>
                            )}
                        </Flex>
                    </Flex>
                </ProCard>
            </div>
        );
    }

    if (!project || !venue) return <Spin />;
    return (
        <ProCard
            style={{ height: '100%' }}
            split={isMobile ? 'horizontal' : 'vertical'}
            className="full-content-height"
        >
            <ProCard
                colSpan={isMobile ? undefined : '35%'}
                className="venue-panel"
                title="Maps"
                style={{ height: '100%' }}
                bodyStyle={{ padding: 0, overflow: 'auto' }}
                extra={
                    isEditor
                        ? [
                              <AddProjVenueMapModule
                                  key="btn-add-venueMap"
                                  projectId={project.id}
                                  isEdit={false}
                                  venueId={venue!.id}
                                  editingVenueMap={{} as any}
                                  trigger={
                                      <Button type="dashed" icon={<PlusOutlined />}>
                                          Add venue map
                                      </Button>
                                  }
                                  onSuccess={() => {
                                      refreshProject(activeProject?.projId);
                                      fetchVenue(venue!.id);
                                      message.success('Venue map added');
                                  }}
                              />,
                          ]
                        : undefined
                }
            >
                <div style={{ padding: 20 }}>{_renderVenueMaps()}</div>
            </ProCard>
            <div
                style={{
                    height: '100%',
                    width: '100%',
                    backgroundColor: '#ddd',
                    position: 'relative',
                }}
            >
                {_renderMapInfo()}
                {venue && ((workingMap?.mapAlign && workingMap?.mapImg) || workingMap?.boundary) ? (
                    <FloorPlanMapView
                        imageUrl={workingMap.mapImg}
                        geojson={!workingMap.mapImg ? workingMap.boundary : undefined}
                        mapAlign={workingMap.mapAlign}
                        initCenter={workingMap?.mapAlign?.center ?? venue.center}
                    />
                ) : (
                    <Empty
                        image={false}
                        description={
                            workingMap?.mapImg && !workingMap?.mapAlign
                                ? 'Please align the floor plan image using alignment tool.'
                                : 'No map data :('
                        }
                        style={{ marginTop: '30%' }}
                    />
                )}
            </div>
        </ProCard>
    );
};

export default VenueMapScreen;
