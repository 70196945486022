import { Checkbox, Flex, message, Space, Typography } from 'antd';
import React, { useImperativeHandle, useRef, useState } from 'react';
import { ConvertAlignDto, convertAlignment, VenueMapMetaDto } from 'apis/VenueApi';
import { ModalForm } from '@ant-design/pro-components';
import { ExclamationCircleFilled } from '@ant-design/icons';
interface AlignChangedModuleProps {
    onClose?: () => void;
}

export type AlignChangedModuleRef = {
    open: (oldMeta: VenueMapMetaDto) => void;
};

const AlignChangedModule = React.forwardRef<AlignChangedModuleRef, AlignChangedModuleProps>(
    (props, ref) => {
        const [messageApi, contextHolder] = message.useMessage();
        const oldMetaRef = useRef<VenueMapMetaDto | undefined>(undefined);
        const [moduleVisible, setModuleVisible] = useState<boolean>(false);
        const [checkedItem, setCheckedItem] = useState<ConvertAlignDto>({
            masks: false,
            beacons: false,
        });

        useImperativeHandle(ref, () => ({
            open(oldMeta: VenueMapMetaDto) {
                oldMetaRef.current = oldMeta;
                setModuleVisible(true);
            },
        }));

        const handleCancel = () => {
            if (props.onClose) props.onClose();
            setModuleVisible(false);
            oldMetaRef.current = undefined;
        };

        const _handleSubmit = async () => {
            if (!oldMetaRef.current?.id || !oldMetaRef.current?.mapAlign)
                return Promise.reject(false);
            try {
                const mAlignConf = oldMetaRef.current.mapAlign;
                const updateMask = checkedItem.masks && !!oldMetaRef.current.boundary;
                const updateBeacons = checkedItem.beacons && !!oldMetaRef.current.beaconPlanId;
                const resp = await convertAlignment(oldMetaRef.current.id, {
                    mapAlign: {
                        size: mAlignConf.size,
                        sizeInMeters: mAlignConf.sizeInMeters,
                        corners: mAlignConf.corners,
                    },
                    masks: updateMask,
                    beacons: updateBeacons,
                });
                if (resp.data) {
                    const msg = `${resp.data.masks ? 'masks' : ''}${resp.data.masks && resp.data.beacons ? ' and ' : ' '}${resp.data.beacons ? 'beacon plan' : ''} updated.`;
                    messageApi.success(msg);
                }
                handleCancel();
                return true;
            } catch (ex) {
                /* do nothing */
                handleCancel();
                return false;
            }
        };

        const _buildContent = (hasMask: boolean, hasBeacon: boolean) => {
            return (
                <Flex vertical>
                    <Typography.Paragraph>
                        We have detected existing {hasMask ? <b>floor plan masks</b> : undefined}
                        {hasMask && hasBeacon ? ' and ' : ' '}
                        {hasBeacon ? <b>beacon plan</b> : undefined} on this map that may need to be
                        updated based on the new alignment. Would you like to auto-update the above
                        to match the new configuration?
                    </Typography.Paragraph>

                    <span>Select items you would like to update:</span>
                    {hasMask ? (
                        <Checkbox
                            onChange={(flag) => {
                                setCheckedItem((x) => ({ ...x, masks: flag.target.checked }));
                            }}
                        >
                            Floor plan masks
                        </Checkbox>
                    ) : undefined}
                    {hasBeacon ? (
                        <Checkbox
                            onChange={(flag) => {
                                setCheckedItem((x) => ({ ...x, beacons: flag.target.checked }));
                            }}
                        >
                            Published beacon plan
                        </Checkbox>
                    ) : undefined}
                </Flex>
            );
        };

        return (
            <>
                {contextHolder}
                <ModalForm
                    title={
                        <Space>
                            <ExclamationCircleFilled style={{ color: 'orange' }} />
                            <span>Alignment config updated</span>
                        </Space>
                    }
                    open={moduleVisible}
                    submitter={{
                        searchConfig: {
                            submitText: 'Update Now',
                            resetText: 'No, thanks',
                        },
                        submitButtonProps: { disabled: !checkedItem.masks && !checkedItem.beacons },
                        resetButtonProps: { type: 'text' },
                    }}
                    width={800}
                    onOpenChange={(isOpen) => {
                        if (!isOpen && moduleVisible) handleCancel();
                    }}
                    onFinish={_handleSubmit}
                >
                    {_buildContent(
                        !!oldMetaRef.current?.boundary,
                        !!oldMetaRef.current?.beaconPlanId,
                    )}
                </ModalForm>
            </>
        );
    },
);
export default AlignChangedModule;
