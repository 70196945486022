import { Button, Space, Tag } from 'antd';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { CompressOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { IXYPair } from 'utils/PolygonUtil';

interface IScaleConf {
    imgSize: [number, number];
    canvasSize: [number, number];
    canvasImgSize: [number, number];
    scale: [number, number];
}

interface IMarkerConf {
    id: number;
    x: number;
    y: number;
    xPx: number;
    yPx: number;
}

interface AnchorPlaygroundProps {
    imageUrl: string;
    onChange?: (id: number, result: IXYPair) => void;
    onSelect?: (id?: number) => void;
}

export type AnchorPlaygroundRef = {
    addMarker: (id: number) => void;
    remove: (id?: number) => void;
    update: (id: number, coord: IXYPair) => void;
    getConfig: () => { markers: IMarkerConf[]; playground: IScaleConf };
};

const SCALE_MULTIPLIER = 0.8;
const AnchorPlayground = React.forwardRef<AnchorPlaygroundRef, AnchorPlaygroundProps>(
    (props, ref) => {
        const wrapperRef = useRef<any>();
        const canvasRef = useRef<HTMLCanvasElement>();
        const imageRef = useRef(new Image());
        // const [scaleConf, setScaleConf] = useState<IScaleConf>({
        const scaleConf = useRef<IScaleConf>({
            imgSize: [0, 0],
            canvasSize: [0, 0],
            canvasImgSize: [0, 0],
            scale: [0, 0],
        });
        const scale = useRef<number>(1.0);
        const isMouseDown = useRef<boolean>(false);
        const imgOffset = useRef<IXYPair>({ x: 0, y: 0 });
        const translatePos = useRef<IXYPair>({ x: 0, y: 0 });
        // Marker related
        const markers = useRef<IMarkerConf[]>([]);
        const draggingMarker = useRef<IMarkerConf>();
        const markerOffset = useRef<IXYPair>({ x: 0, y: 0 });
        const selectedMarker = useRef<number | undefined>(undefined);
        const [hintVisible, setHinVisible] = useState<boolean>(true);

        useImperativeHandle(ref, () => ({
            addMarker(id: any) {
                const imgCenter = [
                    scaleConf.current.imgSize[0] / 2,
                    scaleConf.current.imgSize[1] / 2,
                ];
                const newMarker = {
                    id: id,
                    x: imgCenter[0] / scaleConf.current.scale[0],
                    y: imgCenter[1] / scaleConf.current.scale[1],
                    xPx: imgCenter[0],
                    yPx: imgCenter[1],
                };
                markers.current.push(newMarker);
                draw(scale.current, translatePos.current);
                handleMarkerPosChanged(newMarker);
            },
            remove(id) {
                markers.current =
                    id === undefined ? [] : markers.current.filter((x) => x.id !== id);
                draw(scale.current, translatePos.current);
            },
            update(id, coord) {
                const markerIndex = markers.current.findIndex((marker) => marker.id === id);
                if (markerIndex !== -1) {
                    markers.current[markerIndex].xPx = coord.x;
                    markers.current[markerIndex].yPx = coord.y;
                    markers.current[markerIndex].x = coord.x / scaleConf.current.scale[0];
                    markers.current[markerIndex].y = coord.y / scaleConf.current.scale[1];
                    draw(scale.current, translatePos.current);
                }
            },
            getConfig() {
                return { markers: markers.current, playground: scaleConf.current };
            },
        }));

        useEffect(() => {
            // Init canvas
            _initCanvas();

            // Keyboard event
            const handleKeyDown = (event: KeyboardEvent) => {
                if (event.key === '=' || event.key === '+') {
                    handleZoom(true);
                } else if (event.key === '-') {
                    handleZoom(false);
                }
            };
            document.addEventListener('keydown', handleKeyDown);

            // Hint
            setHinVisible(true);
            setTimeout(() => setHinVisible(false), 4000);

            return () => {
                document.removeEventListener('keydown', handleKeyDown);
            };
        }, []);

        useEffect(() => {
            const wrapperH = wrapperRef.current.clientHeight;
            const wrapperW = wrapperRef.current.clientWidth;
            imageRef.current.src = props.imageUrl;
            imageRef.current.onload = () => {
                const { width: imgWidth, height: imgHeight } = imageRef.current;
                // calculate resized image in canvas
                const scale = Math.min(wrapperW / imgWidth, wrapperH / imgHeight);
                const canvasImgSize: [number, number] = [imgWidth * scale, imgHeight * scale];
                canvasRef.current!.width = wrapperW;
                canvasRef.current!.height = wrapperH;
                scaleConf.current = {
                    imgSize: [imgWidth, imgHeight],
                    canvasSize: [canvasRef.current!.width, canvasRef.current!.height],
                    canvasImgSize: canvasImgSize,
                    scale: [imgWidth / canvasImgSize[0], imgHeight / canvasImgSize[1]],
                };
                // draw image with init scale config
                const initOffset = {
                    x: (wrapperW - canvasImgSize[0]) / 2,
                    y: (wrapperH - canvasImgSize[1]) / 2,
                };
                translatePos.current = initOffset;
                imgOffset.current = Object.assign({}, initOffset);
                draw(1, translatePos.current);
            };
        }, [props.imageUrl]);

        const draw = (scale: number, translatePos: IXYPair) => {
            if (!canvasRef.current) return;
            const ctx = canvasRef.current.getContext('2d');
            if (!ctx) return;

            // clear canvas
            ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
            ctx.fillStyle = '#eeeeee';
            ctx.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height);

            // translate and scale
            ctx.save();
            ctx.translate(translatePos.x, translatePos.y);
            ctx.scale(scale, scale);

            // draw image and markers
            ctx.drawImage(
                imageRef.current,
                0,
                0,
                scaleConf.current.canvasImgSize[0],
                scaleConf.current.canvasImgSize[1],
            );
            drawMarker(ctx);
            ctx.restore();

            //Draw meta
            ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
            ctx.font = `8px monospace`;
            ctx.textAlign = 'right';
            ctx.fillText(
                `Image size: ${scaleConf.current.imgSize[0]}x${scaleConf.current.imgSize[1]}`,
                scaleConf.current.canvasSize[0] - 12,
                scaleConf.current.canvasSize[1] - 12,
            );
        };

        const drawMarker = (ctx: CanvasRenderingContext2D) => {
            const lineLen = 12 / scale.current;
            const dotRadius = 8 / scale.current;
            const labelSize = 10 / scale.current;

            for (let i = 0; i < markers.current.length; i++) {
                const marker = markers.current[i];
                // Cross background
                ctx.strokeStyle = 'rgba(0, 0, 0, 0.7)';
                ctx.lineWidth = 1 / scale.current;
                ctx.beginPath();
                ctx.moveTo(marker.x, marker.y - lineLen);
                ctx.lineTo(marker.x, marker.y + lineLen);
                ctx.moveTo(marker.x - lineLen, marker.y);
                ctx.lineTo(marker.x + lineLen, marker.y);
                ctx.stroke();

                // Fill circle
                const isSelected = marker.id === selectedMarker.current;
                ctx.fillStyle = isSelected ? 'rgba(255, 0, 0, 0.5)' : 'rgba(0, 0, 255, 0.5)';
                ctx.beginPath();
                ctx.arc(marker.x, marker.y, dotRadius, 0, Math.PI * 2);
                ctx.fill();

                // Number label
                ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
                ctx.font = `${labelSize}px Arial`;
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';
                ctx.fillText(marker.id.toString(), marker.x, marker.y);
            }
        };

        const _initCanvas = () => {
            if (!canvasRef.current) return;
            const mCanvas = canvasRef.current;

            // add event listeners to handle screen drag
            mCanvas.addEventListener('click', (evt) => {
                const rect = canvasRef.current!.getBoundingClientRect();
                const x = (evt.clientX - rect.left - translatePos.current.x) / scale.current; // Adjusted for scale
                const y = (evt.clientY - rect.top - translatePos.current.y) / scale.current; // Adjusted for scale
                const dotRadius = 8 / scale.current;

                const currSelected = markers.current.find(
                    (marker) =>
                        x >= marker.x - dotRadius &&
                        x <= marker.x + dotRadius &&
                        y >= marker.y - dotRadius &&
                        y <= marker.y + dotRadius,
                )?.id;
                if (currSelected !== selectedMarker.current) handleMarkerSelected(currSelected);
                selectedMarker.current = currSelected;
                draw(scale.current, translatePos.current);
            });
            mCanvas.addEventListener('mousedown', function (evt) {
                // Move marker
                const rect = canvasRef.current!.getBoundingClientRect();
                const x = (evt.clientX - rect.left - translatePos.current.x) / scale.current; // Adjusted for scale
                const y = (evt.clientY - rect.top - translatePos.current.y) / scale.current; // Adjusted for scale
                const dotRadius = 8 / scale.current;

                // Check if a marker is clicked for dragging
                for (let i = 0; i < markers.current.length; i++) {
                    const marker = markers.current[i];
                    if (
                        x >= marker.x - dotRadius &&
                        x <= marker.x + dotRadius &&
                        y >= marker.y - dotRadius &&
                        y <= marker.y + dotRadius
                    ) {
                        draggingMarker.current = marker;
                        markerOffset.current = { x: x - marker.x, y: y - marker.y };
                        mCanvas.style.cursor = 'move';
                        return;
                    }
                }

                isMouseDown.current = true;
                if (!imgOffset.current?.x && !imgOffset.current?.y) {
                    imgOffset.current.x = evt.clientX;
                    imgOffset.current.y = evt.clientY;
                } else {
                    imgOffset.current.x = evt.clientX - translatePos.current.x;
                    imgOffset.current.y = evt.clientY - translatePos.current.y;
                }
                mCanvas.style.cursor = 'grabbing';
            });

            mCanvas.addEventListener('mouseup', function (evt) {
                mCanvas.style.cursor = 'auto';
                isMouseDown.current = false;
                draggingMarker.current = undefined;
            });
            mCanvas.addEventListener('mouseover', function (evt) {
                mCanvas.style.cursor = 'auto';
                isMouseDown.current = false;
            });
            mCanvas.addEventListener('mouseleave', () => {
                mCanvas.style.cursor = 'auto';
                draggingMarker.current = undefined;
            });
            mCanvas.addEventListener('mouseout', function (evt) {
                mCanvas.style.cursor = 'auto';
                isMouseDown.current = false;
            });
            mCanvas.addEventListener('mousemove', function (evt) {
                if (draggingMarker.current) {
                    const rect = canvasRef.current!.getBoundingClientRect();
                    const x = (evt.clientX - rect.left - translatePos.current.x) / scale.current; // Adjusted for scale
                    const y = (evt.clientY - rect.top - translatePos.current.y) / scale.current; // Adjusted for scale
                    draggingMarker.current.x = x - markerOffset.current.x;
                    draggingMarker.current.y = y - markerOffset.current.y;
                    draggingMarker.current.xPx =
                        draggingMarker.current.x * scaleConf.current.scale[0];
                    draggingMarker.current.yPx =
                        draggingMarker.current.y * scaleConf.current.scale[1];
                    draw(scale.current, translatePos.current);
                    handleMarkerPosChanged(draggingMarker.current);
                } else if (isMouseDown.current) {
                    translatePos.current.x = evt.clientX - imgOffset.current.x;
                    translatePos.current.y = evt.clientY - imgOffset.current.y;
                    draw(scale.current, translatePos.current);
                }
            });
        };

        const handleZoom = (zoomIn: boolean) => {
            if (zoomIn) {
                scale.current /= SCALE_MULTIPLIER;
            } else {
                scale.current *= SCALE_MULTIPLIER;
            }
            draw(scale.current, translatePos.current);
        };
        const handleFitWindow = () => {
            scale.current = 1;
            translatePos.current = {
                x: (scaleConf.current.canvasSize[0] - scaleConf.current.canvasImgSize[0]) / 2,
                y: (scaleConf.current.canvasSize[1] - scaleConf.current.canvasImgSize[1]) / 2,
            };
            draw(scale.current, translatePos.current);
        };

        const handleMarkerPosChanged = (marker: IMarkerConf) => {
            if (props.onChange) props.onChange(marker.id, { x: marker.xPx, y: marker.yPx });
        };
        const handleMarkerSelected = (id?: number) => {
            if (props.onSelect) props.onSelect(id);
        };

        // prettier-ignore
        const _renderOverlayHint = () => {
            return (
                <div className={'overlay-hint ' + (hintVisible ? ' ' : ' fade-out')}>
                    <table style={{ paddingBottom: 24 }}>
                        <tbody>
                            <tr><td>Move anchor / map</td><td>Drag and drop</td></tr>
                            <tr><td>Zoom in</td><td><Tag>+</Tag></td></tr>
                            <tr><td>Zoom out</td><td><Tag>-</Tag></td></tr>
                        </tbody>
                    </table>
                    <a onClick={() => setHinVisible(false)}>Ok</a>
                </div>
            );
        };

        return (
            <div
                ref={wrapperRef}
                style={{ backgroundColor: '#eee', flex: 1, position: 'relative' }}
            >
                {_renderOverlayHint()}
                <Space direction="vertical" style={{ position: 'absolute', right: 12, top: 20 }}>
                    <Space.Compact direction="vertical">
                        <Button
                            icon={<PlusOutlined />}
                            onClick={() => handleZoom(true)}
                            title="Zoom in"
                        />
                        <Button
                            icon={<MinusOutlined />}
                            onClick={() => handleZoom(false)}
                            title="Zoom out"
                        />
                    </Space.Compact>
                    <Button
                        icon={<CompressOutlined />}
                        onClick={handleFitWindow}
                        title="Fit window"
                    />
                </Space>
                <canvas ref={canvasRef as any} style={{ border: '2px #eee solid' }}></canvas>
            </div>
        );
    },
);
export default React.memo(AnchorPlayground);
