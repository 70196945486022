import { PageContainer, ProForm, ProFormSelect, ProFormText } from '@ant-design/pro-components';
import React, { useEffect, useState } from 'react';
import { Card, Form, message } from 'antd';
import { CreateUserDto, createUser } from 'apis/UserApi';
import { useAuth } from 'providers/AuthProvider';
import { useLocation, useNavigate } from 'react-router-dom';

export type UserInputScreenProp = {
    mode: 'CREATE' | 'EDIT';
};

const UserInputScreen: React.FC<UserInputScreenProp> = (props) => {
    const { isSuperAdmin } = useAuth();
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const { state } = useLocation();
    const navigate = useNavigate();
    const [redirectPath, setRedirectPath] = useState<string>('/home');
    const [userInputForm] = Form.useForm();

    useEffect(() => {
        if (!!state?.createUserRedirect) {
            setRedirectPath(state.createUserRedirect);
        }
        if (!!state?.inputEmail) {
            userInputForm.setFieldValue('email', state.inputEmail);
        }
    }, []);

    async function _submit(values: any) {
        setFormLoading(true);
        const mData: CreateUserDto = {
            name: values.name,
            role: isSuperAdmin() ? values.role : 'USER',
            resources: [],
            enabled: true,
            email: values.email,
            password: values.password,
        };
        try {
            const resp = await createUser(mData);
            if (resp.response?.status === 201) {
                message.success('User created!');
                navigate(redirectPath, { replace: true });
                return true;
            }
        } catch (ex) {
            /* do nothing */
        }
        setFormLoading(false);
        return false;
    }

    const title = props.mode === 'CREATE' ? 'Create New User' : 'Update User';

    return (
        <PageContainer
            title={title}
            ghost={true}
            header={{
                breadcrumb: {
                    items: [{ title: 'IAM' }, { title: 'Create User' }],
                },
            }}
        >
            <Card>
                <ProForm
                    form={userInputForm}
                    readonly={false}
                    name="validate_other"
                    initialValues={{ role: 'USER' }}
                    loading={formLoading}
                    onFinish={_submit}
                >
                    <ProFormText
                        width="lg"
                        name="name"
                        label="Display name"
                        placeholder="Enter user name"
                        rules={[{ required: true, message: 'Please enter user name!' }]}
                    />
                    <ProFormText
                        width="lg"
                        name="email"
                        label="Email address"
                        placeholder="Enter email address"
                        rules={[
                            { required: true, message: 'Please enter email address!' },
                            {
                                pattern: RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$'),
                                message: 'Invalid email address!',
                            },
                        ]}
                    />

                    <ProFormText.Password
                        width="lg"
                        name="password"
                        label="Password"
                        placeholder="Enter password"
                        tooltip="At least 8 characters"
                        rules={[
                            { required: true, message: 'Please enter a password!' },
                            {
                                type: 'string',
                                min: 8,
                                message: 'Password must be at least 8 characters.',
                            },
                        ]}
                    />
                    {isSuperAdmin() ? (
                        <ProFormSelect
                            width="lg"
                            name="role"
                            label="Role"
                            valueEnum={{
                                USER: 'User',
                                SUPER_ADMIN: 'Super admin',
                            }}
                            placeholder="Please select a role"
                            rules={[{ required: true, message: 'Please select your role!' }]}
                        />
                    ) : undefined}
                </ProForm>
            </Card>
        </PageContainer>
    );
};

export default UserInputScreen;
