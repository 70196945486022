import { useEffect, useRef, useState } from 'react';
import { formatTime, formatTimeEvenNagative } from 'utils/DateUtils'
import { Space ,Typography} from 'antd';
const { Text } = Typography;

export type VideoRecordViewProps = {
    videoRecord: any;
    ms : number;
    onUpdateVideoDuration : (duration: number) => void;
    currentVideoTime : number;
};

export const VideoRecordView: React.FC<VideoRecordViewProps> = (props) => {

    const videoRecordElement = useRef<any>();
    const [videoRecordDuration , setVideoRecordDuration] = useState<number>(0);

    useEffect(() => {
        setTimeout(() => {
            if(videoRecordElement.current){
                props.onUpdateVideoDuration(videoRecordElement.current.duration * 1000);
                setVideoRecordDuration(videoRecordElement.current.duration * 1000);
            }
        }, 100);
    }, [videoRecordElement]);


    useEffect(() => {
        if(videoRecordElement.current){
            if(props.currentVideoTime / 1000 < videoRecordElement.current.duration){
                videoRecordElement.current.currentTime = props.currentVideoTime / 1000;
            }
        }
    }, [props.currentVideoTime]);

    return (
        <>
            {
            !props.videoRecord ? <></> :
                <div>
                    <div style={{width: "250px" , height:"30em", display: props.videoRecord ? "block" : "none", backgroundColor:"black"}}>
                        <div style={{position:"relative", top:0,left:0, width: '100%', height:"100%", justifyContent: 'center'}}>
                            <video style={{position:"absolute", width:"100%", height: '100%'}} ref={videoRecordElement} width="100%">
                                <source src={URL.createObjectURL(props.videoRecord)}/>
                            </video>
                            {
                                props.currentVideoTime < 0 ?
                                    <Space style={{position:"absolute", width: '100%', height:"100%", backgroundColor:"black", justifyContent:"center", color:"white", fontSize:20, opacity:0.7}}>
                                        <Text style={{color:"white",fontSize:20, textAlign:"center"}}>
                                            <div>Video start in</div>
                                            <div>{formatTimeEvenNagative(props.currentVideoTime - 1000)}</div>
                                        </Text>
                                    </Space>
                                : props.currentVideoTime > videoRecordDuration ? 
                                    <Space style={{position:"absolute", width: '100%', height:"100%", backgroundColor:"black", justifyContent:"center", color:"white", fontSize:20, opacity:0.7}}>
                                        <Text style={{color:"white",fontSize:20, textAlign:"center"}}>
                                            <div>Video finished</div>
                                            <div>{formatTimeEvenNagative(props.currentVideoTime - videoRecordDuration)}</div>
                                        </Text>
                                    </Space>
                                : <></>
                            }
                            <div style={{position:"absolute", width:"100%", height: '100%'}}></div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};
