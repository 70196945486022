import { ProCard } from '@ant-design/pro-components';
import {
    Alert,
    Button,
    Checkbox,
    Col,
    Collapse,
    Divider,
    Flex,
    Form,
    InputNumber,
    Row,
    Select,
    Slider,
    Space,
    Typography,
    message,
} from 'antd';
import {
    DoubleRightOutlined,
    DoubleLeftOutlined,
    RightOutlined,
    LeftOutlined,
    ExpandOutlined,
    FullscreenOutlined,
    PushpinOutlined,
} from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import { SliderSingleProps } from 'antd/lib';
import AlignmentMapView, {
    AlignmentMapViewRef,
    AlignResult,
} from 'components/maps/AlignmentMapView';
import { CornerInputModule } from 'components/dialogs/CornerInputDialog';
import { useAppState } from 'providers/AppStateProvider';
import { useNavigate } from 'react-router-dom';
import { useVenueState } from 'providers/VenueProvider';
import { toCornersDto, updateVenueMapAlign, VenueMapMetaDto } from 'apis/VenueApi';
import PositionOffsetPanel from 'components/maps/PositionOffsetPanel';
import AlignInfoDialog from 'components/dialogs/AlignInfoDialog';
import CustomIcon from 'components/CustomIcon';
import MeasurementTool, { MeasurementToolRef } from 'components/maps/MeasurementTool';
import ImageOverlayRotated from 'components/maps/ImageOverlayRotated';
import AlignChangedModule, { AlignChangedModuleRef } from 'components/dialogs/AlignChangedDialog';
import { AnchorInputModule } from 'components/dialogs/AnchorInputDialog';
const { Text } = Typography;

const w100 = { width: '100%' };
const mb0 = { marginBottom: 0 };

const AdjustSlider: React.FC<
    SliderSingleProps & { value: number; onChange: (val: number) => void }
> = (props) => {
    const [value, setValue] = useState(props.value);
    useEffect(() => {
        setValue(props.value);
    }, [props.value]);
    function _handleOnChange(val: number) {
        if (props.onChange) props.onChange(val);
    }
    function _adjustSlider(offset: number) {
        _handleOnChange(props.value + offset);
    }
    const mStep = props?.step ?? 1;
    return (
        <Flex align="center" gap="small">
            <Button
                icon={<DoubleLeftOutlined />}
                size="small"
                title={`${-1 * mStep * 100}`}
                onClick={() => _adjustSlider(-1 * mStep * 100)}
            />
            <Button
                icon={<LeftOutlined />}
                size="small"
                title={`${-1 * mStep * 10}`}
                onClick={() => _adjustSlider(-1 * mStep * 10)}
            />
            <Slider
                style={w100}
                min={props.min}
                max={props.max}
                step={props.step}
                value={value}
                onChange={_handleOnChange}
            />
            <Button
                icon={<RightOutlined />}
                size="small"
                title={`+${mStep * 10}`}
                onClick={() => _adjustSlider(mStep * 10)}
            />
            <Button
                icon={<DoubleRightOutlined />}
                size="small"
                title={`+${mStep * 100}`}
                onClick={() => _adjustSlider(mStep * 100)}
            />
        </Flex>
    );
};

interface IAlignOption {
    angle: number;
    scale: number;
    opacity: number;
    stroke?: boolean;
    showBound?: boolean;
    isPrecise: boolean;
    grayscale?: boolean;
}

const AlignmentScreen: React.FC = () => {
    const { venue, fetchVenue, workingMap } = useVenueState();
    const initMapData = useRef<VenueMapMetaDto>();
    const changeDialogRef = useRef<AlignChangedModuleRef>();
    const alignMapRef = useRef<AlignmentMapViewRef>();
    const measurementRef = useRef<MeasurementToolRef>();
    const [alignOptions, setAlignOptions] = useState<IAlignOption>({
        angle: workingMap?.mapAlign?.angle ?? 0,
        scale: workingMap?.mapAlign?.scale ?? 100,
        opacity: 1,
        isPrecise: false,
        grayscale: false,
        stroke: true,
        showBound: false,
    });
    const [refMapId, setRefMapId] = useState<string | undefined>();
    const [alignResult, setAlignResult] = useState<AlignResult>();
    const { isMobile } = useAppState();
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        if (!!workingMap?.mapAlign) initMapData.current = workingMap;
        () => (initMapData.current = undefined);
    }, [workingMap]);

    useEffect(() => {
        if (alignMapRef && !alignOptions.isPrecise) {
            alignMapRef.current?.updateConfig(alignOptions);
        } else {
            console.log('Skip this update, config is precise');
        }
    }, [
        alignOptions?.angle,
        alignOptions?.scale,
        alignOptions?.opacity,
        alignOptions?.showBound,
        alignOptions?.stroke,
        alignOptions?.grayscale,
    ]);

    function _handleUpdateAlignment() {
        if (!alignResult || !workingMap) {
            messageApi.info('Alignment no change.');
            navigate(-1);
        } else {
            const hasBeaconOrMask = !!workingMap.beaconPlanId || !!workingMap.boundary;
            updateVenueMapAlign(workingMap.venueId, {
                id: workingMap.id,
                venueId: workingMap.venueId,
                mapAlign: {
                    center: alignResult.center,
                    angle: alignResult.angle,
                    scale: alignResult.scale,
                    corners: toCornersDto(alignResult.corners),
                    size: { width: alignResult.size.width, height: alignResult.size.height },
                    sizeInMeters: alignResult.sizeInMeters,
                },
            }).then((res) => {
                fetchVenue(venue?.id);
                messageApi.success('Alignment saved.');
                if (hasBeaconOrMask && !!changeDialogRef.current && !!initMapData.current) {
                    changeDialogRef.current.open(initMapData.current);
                } else {
                    navigate(-1);
                }
            });
        }
    }
    function _handleBack() {
        if (confirm('You have unsaved changes, are you sure you want to leave?') == true) {
            navigate(-1);
        }
    }

    function _renderOffsetTool() {
        return (
            <Collapse
                className="collapse-offset-tool"
                size="small"
                style={{ padding: 0, margin: '4px 0 16px 0' }}
                expandIcon={() => <ExpandOutlined title="offset position" rotate={45} />}
                expandIconPosition="end"
                items={[
                    {
                        key: 'pos-offset-panel',
                        label: <Text>Offset position</Text>,
                        children: (
                            <PositionOffsetPanel
                                keyboard={false}
                                onOffset={alignMapRef.current?.offset}
                            />
                        ),
                    },
                ]}
            />
        );
    }

    const _renderApproxForm = () => {
        if (!workingMap || !venue) return undefined;
        return (
            <Form style={{ paddingBottom: 16 }}>
                {contextHolder}
                <Space.Compact style={w100}>
                    <div className="input-group">
                        <label htmlFor="latitude" title="Center latitude">
                            Latitude
                        </label>
                        <InputNumber
                            style={w100}
                            disabled
                            name="latitude"
                            title="Center latitude"
                            defaultValue={workingMap?.mapAlign?.center.lat ?? venue?.center.lat}
                            value={alignResult?.center.lat}
                        />
                    </div>
                    <div className="input-group">
                        <label htmlFor="longitude" title="Center longitude">
                            Longitude
                        </label>
                        <InputNumber
                            style={w100}
                            disabled
                            name="longitude"
                            title="Center longitude"
                            defaultValue={workingMap?.mapAlign?.center.lng ?? venue?.center.lng}
                            value={alignResult?.center.lng}
                        />
                    </div>
                </Space.Compact>
                {_renderOffsetTool()}
                <div className="input-group" style={{ marginBottom: 16 }}>
                    <label htmlFor="angle" title="angle">
                        Angle
                    </label>
                    <InputNumber
                        name="angle"
                        min={0}
                        max={360}
                        style={w100}
                        size="small"
                        suffix="°"
                        defaultValue={workingMap.mapAlign?.angle ?? 0}
                        value={alignOptions.angle}
                        onChange={(e) =>
                            e !== null
                                ? setAlignOptions((x) => ({ ...x, angle: e, isPrecise: false }))
                                : undefined
                        }
                    />
                    <AdjustSlider
                        min={0}
                        max={360}
                        step={0.0001}
                        value={alignOptions.angle}
                        onChange={(e) =>
                            e !== null
                                ? setAlignOptions((x) => ({ ...x, angle: e, isPrecise: false }))
                                : undefined
                        }
                    />
                </div>

                <div className="input-group">
                    <label htmlFor="scale" title="scale">
                        Scale
                    </label>
                    <InputNumber
                        name="scale"
                        min={0}
                        style={w100}
                        size="small"
                        suffix="%"
                        defaultValue={workingMap.mapAlign?.scale ?? 100}
                        value={alignOptions.scale}
                        onChange={(e) =>
                            e !== null
                                ? setAlignOptions((x) => ({ ...x, scale: e, isPrecise: false }))
                                : undefined
                        }
                    />
                    <AdjustSlider
                        min={0}
                        max={200}
                        step={0.0001}
                        value={alignOptions.scale}
                        onChange={(e) =>
                            e !== null
                                ? setAlignOptions((x) => ({ ...x, scale: e, isPrecise: false }))
                                : undefined
                        }
                    />
                </div>
                <Divider style={{ margin: 12 }} />
                <div className="input-group">
                    <label>Advance alignment</label>
                    {workingMap.mapImg ? (
                        <Row gutter={8}>
                            <CornerInputModule
                                imageUrl={workingMap.mapImg}
                                positions={
                                    alignResult?.corners ?? workingMap.mapAlign?.corners ?? []
                                }
                                trigger={
                                    <Col sm={{ flex: '100%' }} xxl={{ flex: '50%' }}>
                                        <Button
                                            className="w100"
                                            size="small"
                                            icon={<FullscreenOutlined />}
                                        >
                                            Align with corners
                                        </Button>
                                    </Col>
                                }
                                onSuccess={({ cornerMode, corners, polygon }) => {
                                    alignMapRef.current?.calculateFromCorners(polygon, true);
                                }}
                            />
                            <AnchorInputModule
                                imageUrl={workingMap.mapImg}
                                trigger={
                                    <Col sm={{ flex: '100%' }} xxl={{ flex: '50%' }}>
                                        <Button
                                            className="w100"
                                            size="small"
                                            icon={<PushpinOutlined />}
                                        >
                                            Align with anchors
                                        </Button>
                                    </Col>
                                }
                                onSuccess={(result) => {
                                    alignMapRef.current?.calculateFromCorners(result, true);
                                }}
                            />
                        </Row>
                    ) : undefined}
                </div>
                <Divider style={{ margin: 12 }} />
                <Flex justify="center" align="center" gap={6}>
                    <span>Opacity (%): </span>
                    <Slider
                        style={{ flex: 1 }}
                        min={0}
                        max={1}
                        step={0.01}
                        tooltip={{ formatter: (val) => Math.floor((val ?? 0) * 100) + '%' }}
                        defaultValue={1}
                        value={alignOptions.opacity}
                        onChange={(e) =>
                            setAlignOptions((x) => ({ ...x, opacity: e, isPrecise: false }))
                        }
                    />
                </Flex>
                <Space style={{ margin: '0.5rem 0' }}>
                    <Form.Item style={mb0}>
                        <Checkbox
                            checked={alignOptions.grayscale}
                            onChange={(e) =>
                                setAlignOptions((x) => ({
                                    ...x,
                                    grayscale: e.target.checked,
                                    isPrecise: false,
                                }))
                            }
                        >
                            Grayscale
                        </Checkbox>
                    </Form.Item>
                    <Form.Item style={mb0}>
                        <Checkbox
                            checked={alignOptions.stroke}
                            onChange={(e) =>
                                setAlignOptions((x) => ({
                                    ...x,
                                    stroke: e.target.checked,
                                    isPrecise: false,
                                }))
                            }
                        >
                            Border
                        </Checkbox>
                    </Form.Item>
                    {workingMap.boundary ? (
                        <Form.Item style={mb0}>
                            <Checkbox
                                checked={alignOptions.showBound}
                                onChange={(e) =>
                                    setAlignOptions((x) => ({
                                        ...x,
                                        showBound: e.target.checked,
                                        isPrecise: false,
                                    }))
                                }
                            >
                                Boundary
                            </Checkbox>
                        </Form.Item>
                    ) : undefined}
                </Space>
                <Form.Item label="Reference map" name="refMapId">
                    <Select
                        size="small"
                        className="w100"
                        onChange={setRefMapId}
                        placeholder="Select a reference map"
                        allowClear
                        options={venue.maps.map((x) => ({
                            label: x.name,
                            value: x.id,
                            disable: !x.mapAlign,
                        }))}
                    />
                </Form.Item>
                <Button
                    size="small"
                    title="Measure distance"
                    className="w100"
                    onClick={() => {
                        if (!!measurementRef.current) measurementRef.current.startMeasurement();
                    }}
                >
                    <Flex align="center" justify="center" gap={16}>
                        <CustomIcon icon="ruler" size={18} />
                        <span>Measure distance</span>
                    </Flex>
                </Button>
                <Divider style={{ margin: 12 }} />
                <Form.Item>
                    <Flex justify="end" gap="middle">
                        <Button htmlType="reset" onClick={_handleBack}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit" onClick={_handleUpdateAlignment}>
                            Save
                        </Button>
                    </Flex>
                </Form.Item>
            </Form>
        );
    };

    const _renderMap = () => {
        if (!workingMap?.mapImg || !venue) {
            return (
                <Space>
                    <Alert message="No floor plan for preview." type="error" showIcon />
                </Space>
            );
        }
        const refMapInfo = venue.maps.find((x) => x.id === refMapId);
        return (
            <div style={{ height: '100%', width: '100%', backgroundColor: 'grey' }}>
                <AlignmentMapView
                    ref={alignMapRef as any}
                    editable={true}
                    imageUrl={workingMap.mapImg}
                    geojsonData={workingMap.boundary}
                    initCenter={workingMap?.mapAlign?.center ?? venue.center}
                    onChange={(result, precise) => {
                        setAlignResult(result);
                        if (precise === true) {
                            setAlignOptions((x) => ({
                                ...x,
                                angle: result.angle,
                                scale: result.scale,
                                isPrecise: true,
                            }));
                        }
                    }}
                >
                    {refMapInfo?.mapImg && refMapInfo.mapAlign ? (
                        <ImageOverlayRotated
                            imgSrc={refMapInfo.mapImg}
                            opacity={0.7}
                            positions={[
                                refMapInfo.mapAlign.corners.tl,
                                refMapInfo.mapAlign.corners.tr,
                                refMapInfo.mapAlign.corners.br,
                                refMapInfo.mapAlign.corners.bl,
                            ]}
                            fitBounds={false}
                        />
                    ) : (
                        <></>
                    )}
                    <MeasurementTool ref={measurementRef as any} />
                </AlignmentMapView>
            </div>
        );
    };

    return (
        <ProCard
            style={{ height: '100%' }}
            split={isMobile ? 'horizontal' : 'vertical'}
            className="full-content-height"
        >
            <ProCard
                title={<b>{workingMap?.name}</b>}
                colSpan="30%"
                className="venue-panel"
                headStyle={{ backgroundColor: '#e6f4ffaa', padding: '8px 12px 4px 24px' }}
                headerBordered
                extra={[
                    <AlignInfoDialog
                        key="extra-btn-01"
                        name={workingMap?.name}
                        imgUrl={workingMap?.mapImg}
                        alignResult={alignResult}
                        precise={alignOptions.isPrecise}
                    />,
                ]}
            >
                {_renderApproxForm()}
            </ProCard>
            {_renderMap()}
            <AlignChangedModule ref={changeDialogRef as any} onClose={() => navigate(-1)} />
        </ProCard>
    );
};

export default AlignmentScreen;
