import { TableOutlined } from '@ant-design/icons';
import { Button, Drawer, Space, Table } from 'antd';
import { useState } from 'react';
import { BaseDialogProps } from './BaseDialogProps.type';

export type ConfigTableProp = BaseDialogProps & {
    variables: [string, string | number | boolean][];
};

export const ConfigTableModule: React.FC<Partial<ConfigTableProp>> = (props) => {
    const [open, setOpen] = useState(false);
    const showDrawer = () => setOpen(true);
    const onClose = () => setOpen(false);

    return (
        <>
            <Button onClick={showDrawer}>
                <Space>
                    <TableOutlined />
                    <span>Config</span>
                </Space>
            </Button>
            <Drawer title="Config" onClose={onClose} open={open} width={'50%'}>
                <Table
                    columns={[
                        { title: 'Key', width: 100, render: (val, record) => record[0] },
                        { title: 'Value', render: (val, record) => record[1] },
                    ]}
                    rowKey={(record) => `conf-key-${record[0]}`}
                    dataSource={props.variables}
                    pagination={false}
                    size="small"
                />
            </Drawer>
        </>
    );
};
