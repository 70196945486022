import { Button, Col, Flex, Row } from 'antd';
import { UpOutlined, DownOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { CSSProperties, useEffect, useRef, useState } from 'react';

const panelStyle: Record<string, CSSProperties> = {
    box: {
        width: 100,
        height: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 12,
    },
    input: {
        width: '80%',
        border: 'none',
        backgroundColor: '#eeeeee',
        padding: '4px 8px',
        borderRadius: 4,
    },
};
export type Direction = 'N' | 'E' | 'S' | 'W';
export interface PositionOffsetPanelProps {
    keyboard?: boolean;
    onOffset?: (direction: Direction, meter: number) => void;
    style?: CSSProperties;
}
const PositionOffsetPanel: React.FC<PositionOffsetPanelProps> = (props) => {
    const [offsetMeter, setOffsetMeter] = useState<number>(1);
    const _handleOffset = (direction: Direction) => {
        if (props.onOffset) {
            props.onOffset(direction, offsetMeter);
        }
    };

    useEffect(() => {
        const handleKeyDown = (e: any) => {
            if (props.keyboard === false) return;
            // Offset 1s
            const key = e.key;
            if (key === 'ArrowLeft') {
                e.preventDefault();
                _handleOffset('W');
            } else if (key === 'ArrowRight') {
                e.preventDefault();
                _handleOffset('E');
            } else if (key === 'ArrowUp') {
                e.preventDefault();
                _handleOffset('N');
            } else if (key === 'ArrowDown') {
                e.preventDefault();
                _handleOffset('S');
            }
        };
        document.addEventListener('keydown', handleKeyDown, true);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <div style={props.style}>
            <Row>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <Button
                        icon={<UpOutlined />}
                        onClick={() => _handleOffset('N')}
                        title="North"
                    />
                </Col>
            </Row>
            <Row justify="center" align="middle">
                <Col style={{ textAlign: 'right' }}>
                    <Button
                        icon={<LeftOutlined />}
                        onClick={() => _handleOffset('W')}
                        title="West"
                    />
                </Col>
                <Flex justify="center" align="center" style={{ margin: 2 }}>
                    <div
                        style={{ ...panelStyle.box, flexDirection: 'column' }}
                        className="square-bg"
                    >
                        <span>Offset</span>
                        <div>
                            <input
                                type="number"
                                style={panelStyle.input}
                                value={offsetMeter}
                                onChange={(e) => setOffsetMeter(Number(e.target.value))}
                            />
                            <span>m</span>
                        </div>
                    </div>
                </Flex>
                <Col style={{ textAlign: 'left' }}>
                    <Button
                        icon={<RightOutlined />}
                        onClick={() => _handleOffset('E')}
                        title="East"
                    />
                </Col>
            </Row>
            <Row>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <Button
                        icon={<DownOutlined />}
                        onClick={() => _handleOffset('S')}
                        title="South"
                    />
                </Col>
            </Row>
        </div>
    );
};

export default PositionOffsetPanel;
