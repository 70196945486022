import { Avatar } from 'antd';
import React from 'react';

type AliasAvatarProps = {
    name: string;
    length?: number;
    shape?: 'circle' | 'square' | undefined;
};

const AliasAvatar: React.FC<AliasAvatarProps> = (props) => {
    const mLength = props.length ?? 1;
    const getAliasIndex = () => {
        const mName = props.name.replaceAll(' ', '');
        let mIndex = mName.toUpperCase().charCodeAt(0);
        if (mName.length > 3) {
            mIndex += mName.toUpperCase().charCodeAt(2);
            mIndex += mName.toUpperCase().charCodeAt(4);
        }
        return mIndex % 26;
    }
    const colorSet = [
        '#ab0bff',
        '#3283ff',
        '#85660c',
        '#782ab7',
        '#565656',
        '#1c8356',
        '#18ff33',
        '#f1d99f',
        '#e3e3e3',
        '#1dbf4e',
        '#c5441c',
        '#dfa1fe',
        '#fd00f9',
        '#315a9b',
        '#ffaf18',
        '#f8a19f',
        '#92ad1b',
        '#f6232f',
        '#1effcf',
        '#2fd9ff',
        '#b10da1',
        '#bf75a5',
        '#fd1cbf',
        '#b10068',
        '#ffeb3b',
        '#fb0087',
    ];
    const mColor = colorSet[Math.max(0, Math.min(25, getAliasIndex()))];
    return (
        <Avatar style={{ backgroundColor: mColor, verticalAlign: 'middle' }} size="large" shape={props.shape ?? 'circle'}>
            {props.name.substring(0, mLength).toUpperCase()}
        </Avatar>
    );
};

export default AliasAvatar;
