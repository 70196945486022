import { Flex, Space, Spin, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { memo } from 'react';

interface ILoadingOverlayProps {
    visible?: boolean;
    spin?: boolean;
}

const LoadingOverlay: React.FC<ILoadingOverlayProps> = (props) => {
    if (!props.visible) return null;
    return (
        <Space
            style={{
                position: 'absolute',
                top: 0,
                width: '100%',
                height: '100%',
                flex: 1,
                backgroundColor: 'white',
                opacity: 0.7,
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999,
            }}
        >
            <Flex style={{ justifyContent: 'center', alignItems: 'center' }}>
                {props.spin ? (
                    <Spin />
                ) : (
                    <Typography.Text style={{ color: 'white', fontSize: 100, textAlign: 'center' }}>
                        <LoadingOutlined style={{ color: '#AAAAAA' }} />
                    </Typography.Text>
                )}
            </Flex>
        </Space>
    );
};

export default memo(LoadingOverlay);
