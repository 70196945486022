import { useLeafletContext } from '@react-leaflet/core';
import 'leaflet-imageoverlay-rotated';
import L, { ImageOverlay, LatLngExpression } from 'leaflet';
import { useEffect, useRef, useState } from 'react';

export interface ImageOverlayRotatedProps {
    imgSrc: string | HTMLImageElement | HTMLCanvasElement;
    positions: [LatLngExpression, LatLngExpression, LatLngExpression, LatLngExpression];
    topLeft?: LatLngExpression;
    topRight?: LatLngExpression;
    bottomLeft?: LatLngExpression;
    opacity?: number;
    fitBounds?: boolean;
    grayscale?: boolean;
}

export default function ImageOverlayRotated(props: ImageOverlayRotatedProps) {
    const context = useLeafletContext();
    const [overlay, setOverlay] = useState<ImageOverlay.Rotated>();
    const fixedForImg = useRef<string>();

    useEffect(() => {
        const _overlay = L.imageOverlay.rotated(
            props.imgSrc,
            props.topLeft ?? props.positions[0],
            props.topRight ?? props.positions[1],
            props.bottomLeft ?? props.positions[3],
            {
                opacity: props.opacity ?? 1,
                interactive: false,
            },
        );
        const container = context.layerContainer || context.map;
        container.addLayer(_overlay)
        if (fixedForImg.current != props.imgSrc && props.fitBounds !== false && context.map) {
            fixedForImg.current = `${props.imgSrc}`;
            setTimeout(() => {
                try {
                    context.map.fitBounds(_overlay.getBounds(), { padding: [50, 50] });
                } catch (ex) {
                    /** Cannot fit bounds..., do nothing */
                }
            }, 10);
        }
        _updateGrayScale(_overlay);
        setOverlay(_overlay);
        return () => {
            container.removeLayer(_overlay);
        };
    }, [props.imgSrc, props.opacity]);

    useEffect(() => {
        _updateGrayScale();
    }, [props.grayscale]);

    useEffect(() => {
        if (!!overlay) {
            overlay.reposition(
                props.topLeft ?? props.positions[0],
                props.topRight ?? props.positions[1],
                props.bottomLeft ?? props.positions[3],
            );
        }
    }, [props.positions, overlay]);

    const _updateGrayScale = (targetOverlay: ImageOverlay.Rotated | undefined = overlay) => {
        if (!!targetOverlay) {
            if (props.grayscale) {
                targetOverlay.getElement()?.classList.add('grayscale-filter');
            } else {
                targetOverlay.getElement()?.classList.remove('grayscale-filter');
            }
        }
    };

    return null;
}
