import { PageContainer } from '@ant-design/pro-components';
import React, { useEffect, useState } from 'react';
import {
    Space,
    Typography,
    Button,
    Dropdown,
    Table,
    TableProps,
    message,
    Popconfirm,
    Tag,
} from 'antd';
import { ProjectUserDto, getProjectUsers } from 'apis/ProjectApi';
import { useAppState } from 'providers/AppStateProvider';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import AliasAvatar from 'components/AliasAvatar';
import { MenuProps } from 'antd/lib';
import { BasicListTier } from 'components/BasicTier';
import { ResourceRole, deleteResource, updateResource } from 'apis/UserApi';
import { AddProjUserModule } from 'components/dialogs/AddProjUserDialog';
import { useAuth } from 'providers/AuthProvider';
import { ResetPasswordModule } from 'components/dialogs/ResetPasswordDialog';
const { Text } = Typography;

const usePermissionHook = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const { activeProject } = useAppState();
    const [projUsers, setProjUsers] = useState<ProjectUserDto[]>();
    const [isProjectUsersLoading, setIsProjectuserLoading] = useState<boolean>();


    function refreshProjUser() {
        setIsProjectuserLoading(true);
        if (!!activeProject) {
            getProjectUsers(activeProject?.projId)
                .then((resp) => {
                    setProjUsers(resp.data ?? []);
                    setIsProjectuserLoading(false);
                })
                .catch(() => {
                    setProjUsers([]);
                    setIsProjectuserLoading(false);
                });
        }
    }

    function updateUserAccess(record: ProjectUserDto, role: ResourceRole) {
        if (record.role === role) return;
        updateResource(record.userId, {
            projId: activeProject!.projId,
            role: role,
        }).then(() => {
            messageApi.success('Role updated!');
            refreshProjUser();
        });
    }

    function deleteUserForProj(record: ProjectUserDto) {
        deleteResource(record.userId, {
            projId: activeProject!.projId,
            role: record.role,
        }).then(() => {
            messageApi.success('User removed!');
            refreshProjUser();
        });
    }

    return {
        contextHolder,
        activeProject,
        projUsers,
        refreshProjUser,
        isProjectUsersLoading,
        updateUserAccess,
        deleteUserForProj,
    };
};

const PermissionScreen: React.FC = () => {
    const { isProjectLoading } = useAppState();
    const { hasAccess } = useAuth();
    const {
        contextHolder,
        activeProject,
        projUsers,
        refreshProjUser,
        isProjectUsersLoading,
        updateUserAccess,
        deleteUserForProj,
    } = usePermissionHook();
    useEffect(() => {
        refreshProjUser();
    }, [activeProject]);

    function getMenuProps(record: ProjectUserDto, index: number): MenuProps {
        return {
            items: [
                { label: 'Admin', key: ResourceRole.ADMIN },
                { label: 'Editor', key: ResourceRole.EDITOR },
                { label: 'Viewer', key: ResourceRole.VIEWER },
            ],
            onClick: (e) => {
                updateUserAccess(record, e.key as ResourceRole);
            },
        };
    }
    const isAdmin = hasAccess(ResourceRole.ADMIN, activeProject?.projId);
    const columns: TableProps<ProjectUserDto>['columns'] = [
        {
            title: 'Name',
            dataIndex: 'userId',
            key: 'userId',
            width: 400,
            render: (_, record) => (
                <BasicListTier
                    avatar={<AliasAvatar name={record.name} shape="square" />}
                    title={record.name}
                    description={record.email}
                />
            ),
        },
        {
            title: 'Access role',
            dataIndex: 'role',
            key: 'role',
            render: (_, record, index) =>
                isAdmin ? (
                    <Dropdown menu={getMenuProps(record, index)} trigger={['click']}>
                        <Button>
                            <Space>
                                {record.role}
                                <DownOutlined />
                            </Space>
                        </Button>
                    </Dropdown>
                ) : (
                    <Tag>{record.role}</Tag>
                ),
        },
        {
            title: 'Actions',
            dataIndex: 'userId',
            key: 'userId',
            hidden: !isAdmin,
            width: 250,
            render: (_, record) => (
                <>
                    <ResetPasswordModule
                        key="btn-reset-password"
                        userId={record.userId}
                        trigger={
                            <Button>
                                Reset password
                            </Button>
                        }
                        onSuccess={() => {
                            message.success('Password updated');
                        }}
                    />

                    <Popconfirm
                        title="Remove this user"
                        description={
                            <span>
                                Are you sure to remove <b>{record.name}</b> from this project?
                            </span>
                        }
                        onConfirm={() => deleteUserForProj(record)}
                        okText="Remove"
                        cancelText="Cancel"
                    >
                        <Button danger type="text" title="remove">
                            Remove
                        </Button>
                    </Popconfirm>
                </>

                
            ),
        },
    ];

    return (
        <PageContainer
            title="Project permission"
            breadcrumb={{
                items: [{ title: 'Project' }, { title: 'Permission' }],
            }}
            extra={[
                isAdmin ? (
                    <AddProjUserModule
                        key="btn-add-user"
                        projectId={activeProject!.projId}
                        trigger={
                            <Button type="primary" icon={<PlusOutlined />}>
                                Add user
                            </Button>
                        }
                        onSuccess={() => {
                            message.success('User added');
                            refreshProjUser();
                        }}
                    />
                ) : undefined,
            ]}
        >
            {contextHolder}
            <Table
                rowKey="userId"
                columns={columns}
                dataSource={projUsers}
                loading={isProjectUsersLoading || isProjectLoading}
                pagination={false}
                size="small"
            />
        </PageContainer>
    );
};

export default PermissionScreen;
