import { memo, useRef, useEffect } from 'react';
import mapboxgl, { ImageSource } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'styles/map-style.css';
import { useMapBox } from './MapBoxContainer';

export type LngLatPair = [number, number] | { lat: number; lng: number };

export interface MapBoxImageLayerProps {
    imgSrc: string;
    positions: [LngLatPair, LngLatPair, LngLatPair, LngLatPair];
    opacity?: number;
    fitBounds?: boolean;
    grayscale?: boolean; //TODO
}

const MapBoxImageLayer: React.FC<MapBoxImageLayerProps> = (props) => {
    const layerIdRef = useRef<string>('');
    const layerLoaded = useRef<boolean>(false);
    const lastImg = useRef<string>('');
    const { map } = useMapBox();

    useEffect(() => {
        layerLoaded.current = false;
        _initImgLayer();
    }, [map]);

    useEffect(() => {
        if (layerLoaded.current) {
            _renderOverlay();
        }
    }, [props.imgSrc, props.positions]);

    function _getLngLatArr(): [number, number][] {
        const _toLngLatArr = (input: LngLatPair): [number, number] => {
            const mInput = input as any;
            return Array.isArray(mInput) ? [mInput[0], mInput[1]] : [mInput.lng, mInput.lat];
        };
        return [
            _toLngLatArr(props.positions[0]), // Top left
            _toLngLatArr(props.positions[1]), // Top right
            _toLngLatArr(props.positions[2]), // Bottom right
            _toLngLatArr(props.positions[3]), // Bottom left [longitude, latitude]
        ];
    }

    function _initImgLayer() {
        if (!map) return;
        layerIdRef.current = `img-overlay-${(Math.random() * 1000).toFixed(0)}`;
        map.addSource(`${layerIdRef.current}-source`, {
            type: 'image',
            url: props.imgSrc,
            coordinates: _getLngLatArr(),
        });
        map.addLayer({
            id: `${layerIdRef.current}-layer`,
            type: 'raster',
            source: `${layerIdRef.current}-source`,
            paint: {
                'raster-fade-duration': 0,
            },
        });
        if (!layerLoaded.current) {
            _renderOverlay();
        }
        layerLoaded.current = true;
    }

    function _renderOverlay() {
        if (!map) return;
        const coordinates = _getLngLatArr();
        (map.getSource(`${layerIdRef.current}-source`) as ImageSource).updateImage({
            url: props.imgSrc,
            coordinates: coordinates,
        });

        if (props.opacity !== undefined) {
            map.setPaintProperty(`${layerIdRef.current}-layer`, 'raster-opacity', props.opacity);
        }

        if (props.fitBounds && lastImg.current !== props.imgSrc) {
            const bounds = coordinates.reduce(
                (bounds, coord: any) => {
                    return bounds.extend(coord);
                },
                new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]),
            );
            map.fitBounds(bounds, { padding: 20 });
        }
        lastImg.current = props.imgSrc;
    }

    return null;
};

export default memo(
    MapBoxImageLayer,
    (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr),
);
