import React from 'react';
import { Divider, Flex, Space, Switch } from 'antd';
import { CONTROL_POSITION_CLASSES } from 'services/BaseMapEngine';

type LayerToolbarProps = {
    options: { name: string; key: string; defaultChecked?: boolean }[];
    onChange?: (key: string, checked: boolean) => void;
    visible?: boolean;
    position?: string;
};

const LayerToolbar: React.FC<LayerToolbarProps> = (props) => {
    function _onChange(key: string, checked: boolean) {
        if (props.onChange) props.onChange(key, checked);
    }

    if (props.visible === false) return <></>;
    return (
        <div className={props.position ?? CONTROL_POSITION_CLASSES.bottomleft}>
            <Flex className="leaflet-control map-toolbar" gap={12}>
                {props.options.map((el, i) => {
                    return (
                        <Space key={el.name}>
                            <label>{el.name}</label>
                            <Switch
                                size="small"
                                onChange={(e) => _onChange(el.key, e)}
                                defaultChecked={el.defaultChecked}
                            />
                            {i + 1 === props.options.length ? undefined : (
                                <Divider type="vertical" />
                            )}
                        </Space>
                    );
                })}
            </Flex>
        </div>
    );
};

export default LayerToolbar;
