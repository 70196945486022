import {
    ModalForm,
    ProFormText,
    ProFormTextArea,
    ProFormCheckbox,
} from '@ant-design/pro-components';
import { Form, Modal, Button, Typography, message, Alert } from 'antd';
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { BaseDialogProps } from './BaseDialogProps.type';
import {
    ProjectEditDto,
    ProjectInfoDto,
    ProjectDetailDto,
    cloneProjectDto,
    createProject,
    updateProject,
    cloneProject,
    deleteProject,
} from 'apis/ProjectApi';
import { useAppState } from 'providers/AppStateProvider';
import { useAuth } from 'providers/AuthProvider';
const { Text } = Typography;

export type ProjectInputProps = BaseDialogProps & {
    initialValues?: ProjectInfoDto;
    mode: 'ACTION_CREATE' | 'ACTION_EDIT';
};

export const ProjectInputModal: React.FC<ProjectInputProps> = (props) => {
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [createUserForm] = Form.useForm();
    const isEdit = props?.mode === 'ACTION_EDIT' && !!props.initialValues?.id;

    return (
        <ModalForm
            form={createUserForm}
            title={isEdit ? 'Update project info' : 'New project'}
            trigger={props.trigger}
            initialValues={props.initialValues ?? {}}
            submitter={{
                searchConfig: {
                    submitText: isEdit ? 'Update' : 'Create',
                    resetText: 'Cancel',
                },
            }}
            loading={formLoading}
            onOpenChange={(isOpen) => {
                if (isOpen) {
                    createUserForm.resetFields();
                    createUserForm.setFieldValue('name', props.initialValues?.name);
                    createUserForm.setFieldValue('description', props.initialValues?.description);
                }
            }}
            onFinish={async (values) => {
                setFormLoading(true);
                const mData: ProjectEditDto = {
                    name: values.name,
                    description: values.description,
                };
                let result = false;
                try {
                    if (isEdit) {
                        const resp = await updateProject(props.initialValues!.id, mData);
                        if (resp.response?.status === 200) {
                            if (props.onSuccess) props.onSuccess();
                            result = true;
                        }
                    } else {
                        const resp = await createProject(mData);
                        if (resp.response?.status === 201) {
                            if (props.onSuccess) props.onSuccess();
                            result = true;
                        }
                    }
                } catch (ex) {
                    /* do nothing */
                }
                setFormLoading(false);
                return result;
            }}
        >
            <ProFormText
                name="name"
                label="Project name"
                placeholder="Enter project name"
                rules={[
                    { required: true, message: 'Please enter project name!' },
                    { type: 'string', max: 100, message: 'Name cannot longer than 100 characters' },
                ]}
            />
            <ProFormTextArea
                name="description"
                label="Description"
                placeholder="A short description (Max 500 characters)"
                rules={[
                    {
                        type: 'string',
                        max: 500,
                        message: 'description cannot longer than 500 char',
                    },
                ]}
                fieldProps={{ rows: 5 }}
            />
        </ModalForm>
    );
};

export const ProjectDeleteDialog: React.FC<BaseDialogProps> = (props) => {
    const [modal, contextHolder] = Modal.useModal();
    const { project, switchProject } = useAppState();
    const { refreshUser } = useAuth();
    if (!project) return <></>;

    const handleDeleteProj = async () => {
        if (!project) return Promise.reject(false);
        try {
            await deleteProject(project.id);
            switchProject(undefined);
            refreshUser();
            if (props.onSuccess) props.onSuccess();
            return Promise.resolve(true);
        } catch (ex) {
            return Promise.reject(false);
        }
    };

    return (
        <>
            {contextHolder}
            <Button
                key="p-ex-del"
                danger
                icon={<DeleteOutlined />}
                onClick={() => {
                    modal
                        .confirm({
                            okButtonProps: { danger: true },
                            title: `Delete project '${project.name}'`,
                            content: (
                                <p>
                                    Are you sure to delete <b>{project.name}</b>?
                                </p>
                            ),
                        })
                        .then(
                            (confirmed) => {
                                return confirmed ? handleDeleteProj() : false;
                            },
                            () => {},
                        );
                }}
            >
                Delete
            </Button>
        </>
    );
};

export type ProjectCloneProps = BaseDialogProps & {
    cloningProject?: ProjectDetailDto;
};

export const ProjectCloneModal: React.FC<ProjectCloneProps> = (props) => {
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [cloneProjectForm] = Form.useForm();

    const [selectedVenues, setSelectedVenues] = useState<string[]>([]);
    const [isSelectAll, setIsSelectAll] = useState<boolean>();

    const updateSelectedAll = (isSelectAll: boolean) => {
        setIsSelectAll(isSelectAll);
        if (!isSelectAll) {
            setSelectedVenues([]);
        } else {
            let allVenueArray = [];
            if (props.cloningProject?.venues != null) {
                for (
                    let venueLoop = 0;
                    venueLoop < props.cloningProject?.venues.length;
                    venueLoop++
                ) {
                    allVenueArray.push(props.cloningProject?.venues[venueLoop].id);
                }
            }
            setSelectedVenues([...allVenueArray]);
        }
    };

    const updateSelectedVenue = (venueId: string) => {
        let latestArray = selectedVenues;
        if (latestArray.includes(venueId)) {
            latestArray.splice(latestArray.indexOf(venueId), 1);
            setSelectedVenues([...latestArray]);
        } else {
            latestArray.push(venueId);
            setSelectedVenues([...latestArray]);
        }
        setIsSelectAll(latestArray.length == props.cloningProject?.venues.length);
    };

    return (
        <ModalForm
            form={cloneProjectForm}
            title={'Clone project'}
            trigger={props.trigger}
            submitter={{
                searchConfig: {
                    submitText: 'Copy',
                    resetText: 'Cancel',
                },
            }}
            loading={formLoading}
            onOpenChange={(isOpen) => {
                if (isOpen) {
                    cloneProjectForm.resetFields();
                    setIsSelectAll(false);
                    setSelectedVenues([]);
                    cloneProjectForm.setFieldValue('name', props.cloningProject?.name + ' (copy)');
                    cloneProjectForm.setFieldValue(
                        'description',
                        props.cloningProject?.description,
                    );
                }
            }}
            onFinish={async (values) => {
                setFormLoading(true);
                let result = false;
                if (props.cloningProject) {
                    const mData: cloneProjectDto = {
                        name: values.name,
                        description: values.description,
                        venueIds: selectedVenues,
                    };
                    try {
                        const resp = await cloneProject(props.cloningProject.id, mData);
                        if (resp.response?.status === 200 || resp.response?.status === 201) {
                            if (props.onSuccess) props.onSuccess();
                            result = true;
                            setFormLoading(false);
                            return result;
                        }
                    } catch (ex: any) {
                        message.error('error');
                        setFormLoading(false);
                        return result;
                    }
                }
            }}
        >
            <Alert
                type="info"
                showIcon
                description={
                    <ul style={{ margin: 0 }}>
                        <li>
                            The 'Clone project' function will copy all venue's floor plan metadata
                            (map image and alignment), masks, beacon plans, survey data, the latest
                            site package, and venue configuration.
                        </li>
                        <li>
                            None of the cloned content will go live immediately after copying;
                            please republish the content if necessary.{' '}
                        </li>
                        <li>License-related configurations will not be cloned.</li>
                    </ul>
                }
            />
            <ProFormText
                name="name"
                label="Project name"
                placeholder="Enter project name"
                rules={[
                    { required: true, message: 'Please enter project name!' },
                    { type: 'string', max: 100, message: 'Name cannot longer than 100 characters' },
                ]}
            />
            <ProFormTextArea
                name="description"
                label="Description"
                placeholder="A short description (Max 500 characters)"
                rules={[
                    {
                        type: 'string',
                        max: 500,
                        message: 'description cannot longer than 500 char',
                    },
                ]}
                fieldProps={{ showCount: true, maxLength: 500 }}
            />

            {props.cloningProject?.venues.length == 0 ? undefined : (
                <>
                    <Text>Select venue to copy</Text>
                    <ProFormCheckbox
                        fieldProps={{
                            onChange: (e) => {
                                updateSelectedAll(e.target.checked);
                            },
                            checked: isSelectAll,
                        }}
                    >
                        Select all
                    </ProFormCheckbox>

                    {props.cloningProject?.venues.map((venue, index) => (
                        <div key={`proj-${index}`}>
                            <Button
                                type={selectedVenues.includes(venue.id) ? 'primary' : undefined}
                                style={{ marginRight: 10, marginBottom: 10 }}
                                onClick={() => {
                                    updateSelectedVenue(venue.id);
                                }}
                            >
                                {selectedVenues.includes(venue.id) ? <CheckOutlined /> : undefined}{' '}
                                {venue.name}
                            </Button>
                        </div>
                    ))}
                </>
            )}
        </ModalForm>
    );
};
