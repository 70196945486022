import React, { useEffect, useImperativeHandle, useRef } from 'react';
import { useLeafletContext } from '@react-leaflet/core';
import * as L from 'leaflet';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'styles/map-style.css';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import { FloorPlanMaskItem } from 'apis/VenueApi';

export interface IMaskItem extends FloorPlanMaskItem {
    polygon: number[][]; // [lat, lng]
    groupId: string;
    layer?: L.Polygon;
    visible: boolean;
}

export type PolygonDrawingLayerRef = {
    resetAll: () => void;
    addPolygon: (id: string | number, groupId: string) => void;
    importPolygon: (id: string | number, groupId: string, points: number[][]) => void;
    removeById: (id: string | number) => void;
    getAllLayers: () => IMaskItem[];
    findLayerById: (id: string | number) => IMaskItem | undefined;
    setHoverItem: (id: string | number | undefined) => void;
    setVisible: (id: string | number | (string | number)[], visible: boolean) => void;
    updateName: (id: string | number, name: string) => void;
};

type PolygonDrawingLayerProps = {
    ref?: PolygonDrawingLayerRef;
    initData?: IMaskItem[];
    editable?: boolean;
    onClick?: (id: string | number | undefined) => void;
    onHover?: (id: string | number | undefined) => void;
    onCreated?: (item: IMaskItem) => void;
    onRemoved?: (item: IMaskItem) => void;
    onChanged?: (item: IMaskItem) => void;
};

const HoverPolygonStyle: L.PathOptions = {
    color: '#f71b7e',
    opacity: 1,
    dashOffset: '20px',
    fillColor: '#f71b7e',
    fillOpacity: 0.3,
    weight: 3,
};
const ColorSet: Record<string, string> = {
    BOUNDARY: '#597ef7',
    ACCESSIBLE: '#7cb305',
    INACCESSIBLE: '#faad14',
    SWITCHINGZONE: '#9254de',
};
const _getPolygonStyle = (groupId: string): L.PathOptions => {
    const mColor = ColorSet[groupId] ?? 'blue';
    return {
        opacity: 0.7,
        fillOpacity: 0.2,
        weight: 2,
        color: mColor,
        fillColor: mColor,
    };
};

const PolygonDrawingLayer = React.forwardRef<PolygonDrawingLayerRef, PolygonDrawingLayerProps>(
    (props, ref) => {
        const drawingMeta = useRef<{ id: string | number; name: string; groupId: string }>();
        const context = useLeafletContext();
        const maskLayers = useRef<IMaskItem[]>([]);

        const toLatLngRingArray = (polygon: L.Polygon) => {
            const pos = polygon.getLatLngs();
            const latLngPolygon = (pos[0] as any).map((x: any) => [x.lat, x.lng]);
            latLngPolygon.push(latLngPolygon[0]);
            return latLngPolygon;
        };

        const _removeById = (id: string | number) => {
            const mIndex = maskLayers.current.findIndex((el) => el.id === id);
            if (mIndex > -1) {
                const mItem = maskLayers.current[mIndex];
                if (mItem.layer) mItem.layer.remove();
                if (props.onRemoved) props.onRemoved(mItem);
                maskLayers.current.splice(mIndex, 1);
            }
        };

        const _handleOnHover = (id?: string | number) => {
            if (drawingMeta.current !== undefined) return;
            maskLayers.current.forEach((el) => {
                const isHover = el.id === id;
                if (el.layer) {
                    el.layer.setStyle(isHover ? HoverPolygonStyle : _getPolygonStyle(el.groupId));
                }
            });
            if (props.onHover) props.onHover(id);
        };

        const _handleUpdatePolygon = (id: string | number, polygon: L.Polygon) => {
            const mIdx = maskLayers.current.findIndex((x) => x.id === id);
            maskLayers.current[mIdx].layer = polygon;
            maskLayers.current[mIdx].polygon = toLatLngRingArray(polygon);
            if (props.onChanged) props.onChanged(maskLayers.current[mIdx]);
        };

        const _setupPolygonHint = (item: IMaskItem, msg?: string) => {
            if (!item.layer) return;
            const popupMsg = `<b>${item.name}</b><br>Group: ${item.groupId}`;
            item.layer.bindTooltip(msg ?? popupMsg, { direction: 'top' });
            item.layer.on('mouseover', function (e) {
                _handleOnHover(item.id);
            });
            item.layer.on('mouseout', function (e) {
                _handleOnHover(undefined);
            });
            item.layer.on('click', function (e) {
                if (props.onClick) props.onClick(item.id);
            });
        };

        const _setPolygonVisible = (id: string | number, visible: boolean) => {
            const mIndex = maskLayers.current.findIndex((el) => el.id === id);
            if (mIndex > -1 && maskLayers.current[mIndex].layer) {
                maskLayers.current[mIndex].visible = !visible;
                const container = context.map;
                if (visible) {
                    maskLayers.current[mIndex].layer!.addTo(container);
                    maskLayers.current[mIndex].layer!.setStyle(
                        _getPolygonStyle(maskLayers.current[mIndex].groupId),
                    );
                } else {
                    maskLayers.current[mIndex].layer!.removeFrom(container);
                }
            }
        };

        const _setPolygonName = (id: string | number, name: string) => {
            const mIndex = maskLayers.current.findIndex((el) => el.id === id);
            if (mIndex > -1) {
                maskLayers.current[mIndex].name = name;
                _setupPolygonHint(maskLayers.current[mIndex]);
            }
        };

        const _initAllLayers = () => {
            const container = context.map;
            maskLayers.current.filter((x) => !!x.layer).forEach((el) => container.removeLayer(el.layer!));

            // setup init polygon
            if (props.initData) {
                const maskArr = [];
                for (const item of props.initData) {
                    if (item.polygon != null) {
                        item.layer = new L.Polygon(item.polygon as L.LatLngExpression[]);
                        item.layer.on('pm:update', (e) =>
                            _handleUpdatePolygon(item.id, item.layer!),
                        );
                        item.layer.setStyle(_getPolygonStyle(item.groupId));
                        _setupPolygonHint(item);
                        if (item.visible) item.layer.addTo(container);
                        maskArr.push(item);
                    }
                }
                maskLayers.current = maskArr;
            } else {
                maskLayers.current = [];
            }
        };

        function _onPolygonCreated(polygon: L.Polygon, addToMap?: boolean) {
            if (!!drawingMeta.current && !!polygon) {
                // Setup listeners
                const item: IMaskItem = {
                    id: drawingMeta.current.id,
                    groupId: drawingMeta.current.groupId,
                    name: drawingMeta.current.name,
                    enabled: true,
                    polygon: toLatLngRingArray(polygon),
                    visible: true,
                    layer: polygon,
                };
                // Update style
                polygon.on('pm:update', (e) => _handleUpdatePolygon(item.id, item.layer!));
                item.layer!.setStyle(_getPolygonStyle(drawingMeta.current.groupId));
                // setup tooltips
                _setupPolygonHint(item);
                // Insert new layer
                maskLayers.current.push(item);
                if (props.onCreated) props.onCreated(item);
                if (addToMap) {
                    item.layer!.addTo(context.map);
                }
            }
            drawingMeta.current = undefined;
        }

        useEffect(() => {
            L.PM.setOptIn(false);
            const container = context.map;

            // Setup listeners
            container.on('pm:create', (e) => {
                if (e.shape !== 'Polygon') return;
                const mPolygon = e.layer as L.Polygon;
                _onPolygonCreated(mPolygon);
            });

            // Setup controls
            if (container.pm && props.editable !== false) {
                container.pm.addControls({
                    position: 'topright',
                    drawControls: false,
                    editPolygon: true,
                    deleteLayer: false,
                    cutPolygon: false,
                });
            } else {
                console.warn('L.PM not ready...');
            }
            return () => L.PM.setOptIn(false);
        }, []);

        useImperativeHandle(ref, () => ({
            resetAll() {
                _initAllLayers();
            },
            addPolygon(id: string | number, groupId: string) {
                _removeById(id);
                drawingMeta.current = {
                    id,
                    groupId,
                    name: groupId.toString().toLowerCase() + '-' + id.toString().slice(-3),
                };
                const container = context.map;
                container.pm.enableDraw('Polygon', {});
            },
            importPolygon(id: string | number, groupId: string, points: number[][]) {
                drawingMeta.current = {
                    id,
                    groupId,
                    name: groupId.toString().toLowerCase() + '-' + id.toString().slice(-3),
                };
                const mPolygon = L.polygon(points.map((x) => new L.LatLng(x[1], x[0])));
                _onPolygonCreated(mPolygon, true);
            },
            removeById(id: string | number) {
                _removeById(id);
            },
            getAllLayers() {
                return maskLayers.current;
            },
            findLayerById(id: string | number) {
                return maskLayers.current.find((x) => x.id === id);
            },
            setHoverItem(id) {
                _handleOnHover(id);
            },
            setVisible(id, visible) {
                if (Array.isArray(id)) {
                    id.forEach((x) => _setPolygonVisible(x, visible));
                } else {
                    _setPolygonVisible(id, visible);
                }
            },
            updateName(id: string | number, name: string) {
                _setPolygonName(id, name);
            },
        }));
        return null;
    },
);

export default PolygonDrawingLayer;
