import { ModalForm, ProFormTextArea } from '@ant-design/pro-components';
import { Form, Button } from 'antd';
import { BaseDialogProps } from './BaseDialogProps.type';
import { useEffect, useRef, useState } from 'react';

export type PolygonInputProp = BaseDialogProps & {
    getPosition?: () => number[][] | undefined;
    positions?: number[][];
    targetId?: number | string;
};

export const PolygonInputModule: React.FC<PolygonInputProp> = (props) => {
    const [polygonInputForm] = Form.useForm();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [fixBtn, setFixBtn] = useState<boolean>(false);
    const [initPositions, setInitPosition] = useState<number[][]>();
    useEffect(() => {
        const pos = (props?.getPosition ? props.getPosition() : props.positions) ?? props.positions;
        setInitPosition(pos);
    }, [props.positions])

    useEffect(() => {
        if (isOpen && initPositions) {
            if (Array.isArray(initPositions) && initPositions.length >= 3) {
                polygonInputForm.setFieldsValue({
                    polygon: JSON.stringify(initPositions),
                });
            }
        }
    }, [isOpen, initPositions]);

    const _fixLastEl = () => {
        const objArr = JSON.parse(polygonInputForm.getFieldValue('polygon'));
        objArr.push(objArr[0]);
        polygonInputForm.setFieldValue('polygon', JSON.stringify(objArr));
        polygonInputForm.validateFields();
    };

    return (
        <ModalForm
            form={polygonInputForm}
            title={'Import polygon'}
            trigger={props.trigger}
            width={'80%'}
            submitter={{
                render: (submitterProps) => {
                    return (
                        <Button type="primary" onClick={submitterProps.submit}>
                            Preview
                        </Button>
                    );
                },
            }}
            onOpenChange={setIsOpen}
            onFinish={async (values) => {
                const strPolygon = values['polygon'];
                try {
                    const polygonArrObj = JSON.parse(strPolygon);
                    const shifted = polygonArrObj.map((el: any) => {
                        return [el[1], el[0]];
                    });
                    if (props.onSuccess)
                        props.onSuccess({ coordinates: shifted, id: props.targetId });
                } catch (ex) {
                    console.error(ex);
                }
                return true;
            }}
        >
            <p>
                Input polygon coordinate array. i.e. <i>{'[[lng, lat], [lng, lat], ...]'}</i>
            </p>
            <ProFormTextArea
                name="polygon"
                label="Coordinates"
                fieldProps={{ rows: 15 }}
                initialValue={initPositions ? JSON.stringify(initPositions, null, 3) : ''}
                rules={[
                    { required: true },
                    () => ({
                        validator(_, value) {
                            try {
                                const arrObj = JSON.parse(value);
                                if (!Array.isArray(arrObj)) throw new Error('Not array');
                                const mLength = arrObj.length;
                                for (let i = 0; i < mLength; i++) {
                                    if (arrObj[i].length !== 2) throw new Error('Invalid length');
                                    if (
                                        typeof arrObj[i][0] !== 'number' ||
                                        typeof arrObj[i][0] !== 'number'
                                    )
                                        throw new Error('Invalid data type');
                                }
                                if (
                                    arrObj[0][0] !== arrObj[mLength - 1][0] ||
                                    arrObj[0][1] !== arrObj[mLength - 1][1]
                                ) {
                                    setFixBtn(true);
                                    throw new Error('The first and last coordinate must be the same.');
                                }
                                setFixBtn(false);
                                return Promise.resolve();
                            } catch (ex) {
                                return Promise.reject(new Error('Invalid coordinate array: ' + ex));
                            }
                        },
                    }),
                ]}
                placeholder={`[\n  [lng, lat],\n  [lng, lat],\n  ...\n]`}
            />
            {fixBtn ? (
                <Button type="link" size="small" onClick={_fixLastEl}>
                    Fix it
                </Button>
            ) : undefined}
        </ModalForm>
    );
};
