import 'mapbox-gl/dist/mapbox-gl.css';
import 'styles/map-style.css';
import React, { CSSProperties, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { LatLngDto } from 'apis/VenueApi';
import BaseMapEngine, { HK_CENTER } from 'services/BaseMapEngine';

export type LocationPickerRef = {
    getCenter: () => LatLngDto | null;
    flyTo: (center: LatLngDto) => void;
};

export type LocationPickerProps = {
    style?: CSSProperties;
    center?: LatLngDto;
    onChange?: (center: LatLngDto) => void;
    editable?: boolean;
};

const LocationPicker = React.forwardRef<LocationPickerRef, LocationPickerProps>((props, ref) => {
    const mapEngineRef = useRef(new BaseMapEngine());
    useEffect(() => {
        const initCenter = props.center ?? HK_CENTER;
        mapEngineRef.current.init('map-view', false, initCenter);
    }, []);
    useEffect(() => {
        const initCenter = props.center ?? HK_CENTER;
        mapEngineRef.current.showCenterMarker(
            true,
            initCenter,
            props.onChange && props.editable !== false
                ? (center) => {
                      props.onChange!({ lat: center.lat, lng: center.lng });
                  }
                : undefined,
            props.editable,
        );
    }, [props.center]);

    useImperativeHandle(ref, () => ({
        getCenter() {
            return mapEngineRef.current.getCenter();
        },
        flyTo(center) {
            return mapEngineRef.current.flyTo(center);
        },
    }));

    return <div id="map-view" style={{ width: '100%', height: '100%', ...props.style }}></div>;
});
export default LocationPicker;
