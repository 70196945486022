import { ProCard } from '@ant-design/pro-components';
import React, { useEffect, useState, useRef } from 'react';
import {
    Button,
    Spin,
    Empty,
    Typography,
    Flex,
    Space,
    Tooltip,
    Checkbox,
    Tree,
    message,
    Badge,
    Alert,
} from 'antd';
import {
    FolderOpenFilled,
    MobileOutlined,
    NodeIndexOutlined,
    SwapOutlined,
    WarningOutlined,
} from '@ant-design/icons';
import { useAppState } from 'providers/AppStateProvider';
import { useVenueState } from 'providers/VenueProvider';
import FloorPlanMapView from 'components/maps/FloorPlanMapView';
import { updateSurveyRevision } from 'apis/VenueApi';
import SurveyPathLayer from 'components/maps/SurveyPathLayer';
import moment from 'moment';
import LoadingOverlay from 'components/LoadingOverlay';
import LayerToolbar from 'components/maps/LayerToolbar';
import SurveyQualityLayer from 'components/maps/SurveyQualityLayer';
import FloorSelector from 'components/FloorSelector';
import { useAuth } from 'providers/AuthProvider';
import { ResourceRole } from 'apis/UserApi';

const { Text } = Typography;

const VenueSurveyScreen: React.FC = () => {
    const { hasAccess } = useAuth();
    const [messageApi, contextHolder] = message.useMessage();
    const {
        venue,
        workingMap,
        surveySessions,
        fetchSurveyData,
        isLoading,
        lastRevisionAt,
        surveyQuality,
        analyzeSurvey,
    } = useVenueState();
    const { project, isMobile } = useAppState();
    const [checkedPaths, setCheckedPaths] = useState<string[]>([]);
    const [layerConfig, setLayerConfig] = useState<any>({
        waypoint: true,
        arrow: true,
        path: true,
        grayscale: false,
    });
    const [speedMode, setSpeedMode] = useState<number>(0);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [selectedPath, setSelectedPath] = useState<string>();

    const allPathName = useRef<string[]>([]);
    const enabledSnapshot = useRef<string[]>([]);
    const isEditor = hasAccess(ResourceRole.EDITOR, project?.id);

    useEffect(() => {
        fetchSurveyData();
        if (!!venue && !!workingMap) {
            setCheckedPaths([]);
            setSelectedPath(undefined);
        }
    }, [venue, workingMap]);

    useEffect(() => {
        const mAllPaths = [];
        const enabledPathName = [];
        if (surveySessions && surveySessions?.length > 0) {
            for (const el of surveySessions) {
                for (const p of el.paths) {
                    mAllPaths.push(p.name);
                    if (p.enabled) enabledPathName.push(p.name);
                }
            }
        }
        enabledSnapshot.current = enabledPathName.sort();
        allPathName.current = mAllPaths;
        setCheckedPaths(enabledPathName);
        setSelectedPath(undefined);
    }, [surveySessions]);

    function _hasChange() {
        return enabledSnapshot.current.join(',') !== checkedPaths.sort().join(',');
    }
    function _handleReset() {
        setCheckedPaths(enabledSnapshot.current);
    }
    function _handleSubmit() {
        if (!workingMap) return;
        const disabled = allPathName.current.filter((el) => !checkedPaths.includes(el));
        setIsUpdating(true);
        updateSurveyRevision(workingMap.id, {
            mapId: workingMap.id,
            blacklist: disabled,
        })
            .then((resp) => {
                if (resp.response?.status ?? 999 < 400) {
                    messageApi.success('Survey revision updated.');
                    fetchSurveyData();
                }
            })
            .finally(() => {
                setIsUpdating(false);
            });
    }
    function handleAnalyzeClick() {
        if (!!workingMap && !!surveyQuality && surveyQuality.analyzeState !== 'ANALYZING') {
            analyzeSurvey();
        }
    }
    function scrollToElement(name: string) {
        const element = document.querySelector(`[title="${name}"]`);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    function _renderSpeedInfo(distance: number, duration: number, error?: string) {
        const sec = duration / 1000;
        const meterSpeed = distance / sec;
        const getText = () => {
            switch (speedMode) {
                case 0:
                    return `${meterSpeed.toFixed(1)}m/s`;
                case 1:
                    return `${distance.toFixed(1)}m / ${sec.toFixed(1)}s`;
                case 2:
                    const ft = distance * 3.28084;
                    return `${ft.toFixed(1)}ft / ${sec.toFixed(1)}s`;
                case 3:
                    const _ft = distance * 3.28084;
                    const ftSpeed = _ft / sec;
                    return `${ftSpeed.toFixed(1)}ft/s`;
                default:
                    return undefined;
            }
        };
        const errorMsg = !!error ? (
            <span>
                Invalid survey data, please redo this path.
                <br />
                <i>{error}</i>
            </span>
        ) : meterSpeed >= 1.2 ? (
            'Walking too fast. Try to walk at a pace of less than 1.2 meters per second.'
        ) : undefined;
        return (
            <Space>
                {!!errorMsg ? (
                    <Tooltip title={errorMsg}>
                        <WarningOutlined style={{ color: error ? 'red' : 'orange' }} />
                    </Tooltip>
                ) : undefined}
                {getText()}
            </Space>
        );
    }

    function _renderPathList() {
        const totalPaths = allPathName.current.length;
        const treeData = surveySessions!.map((el) => {
            const deviceInfo = (
                <Space>
                    <MobileOutlined />
                    <span>
                        {el.deviceModel} ({el.deviceOs})
                    </span>
                </Space>
            );
            const isNewSurvey = venue?.lastBuildAt
                ? new Date(el.recordAt) >= new Date(venue.lastBuildAt)
                : true;
            return {
                title: (
                    <Tooltip title={deviceInfo} color="grey" placement="right">
                        <Flex className="tree-title" justify="space-between" align="center">
                            <Space title={el.id} style={{ flex: 1 }}>
                                <FolderOpenFilled style={{ color: '#4164fb' }} />
                                <Typography.Text className="line-clamp">
                                    {el.name ?? moment(el.recordAt).format('YYYY-MM-DD HH:mm')}
                                </Typography.Text>
                            </Space>
                            <Text type="secondary">{`${el.paths.length} paths`}</Text>
                            <Badge dot count={isNewSurvey ? 1 : 0} title="New survey paths" />
                        </Flex>
                    </Tooltip>
                ),
                key: `S:PROJ:${el.id}`,
                children: el.paths.map((x) => {
                    return {
                        title: (
                            <Flex justify="space-between" align="center">
                                <Space title={x.name}>
                                    <NodeIndexOutlined />
                                    <span>{moment(x.recordAt).format('YYYY-MM-DD HH:mm')}</span>
                                </Space>
                                <Text type="secondary" italic>
                                    {_renderSpeedInfo(x.distance, x.duration, x.error)}
                                </Text>
                            </Flex>
                        ),
                        key: x.name,
                        isLeaf: true,
                    };
                }),
            };
        });
        return (
            <ProCard
                title={
                    <Space style={{ marginLeft: 12 }}>
                        <Checkbox
                            indeterminate={
                                checkedPaths.length !== totalPaths && checkedPaths.length > 0
                            }
                            onChange={(e) =>
                                setCheckedPaths(e.target.checked ? allPathName.current : [])
                            }
                            checked={checkedPaths.length === totalPaths && totalPaths > 0}
                        />
                        <Text>Survey paths</Text>
                    </Space>
                }
                subTitle={`${checkedPaths.length}/${totalPaths}`}
                headerBordered
                ghost
                size="small"
                style={{ backgroundColor: 'rgba(0, 0, 0, 0.02)' }}
                bodyStyle={{ padding: 0 }}
                extra={[
                    <Button
                        key="ext-survey-path"
                        type="text"
                        size="small"
                        icon={<SwapOutlined style={{ color: 'grey' }} />}
                        title="Show speed"
                        onClick={() => setSpeedMode((x) => (x + 1) % 5)}
                    />,
                ]}
            >
                <Tree
                    checkable
                    defaultExpandAll
                    showIcon
                    blockNode
                    onSelect={(selectedKeys, info) => {
                        setSelectedPath(
                            info.selected && selectedKeys.length > 0
                                ? (selectedKeys as string[])[0]
                                : undefined,
                        );
                    }}
                    onCheck={(checkedKeys, info) => {
                        setCheckedPaths(
                            (checkedKeys as string[]).filter((x) => !x.startsWith('S:PROJ:')),
                        );
                    }}
                    selectedKeys={selectedPath ? [selectedPath] : undefined}
                    checkedKeys={checkedPaths}
                    treeData={treeData}
                    rootClassName="survey-tree"
                    rootStyle={{
                        backgroundColor: 'transparent',
                        padding: '6px 6px 6px 0',
                    }}
                />
            </ProCard>
        );
    }

    function _renderPanelContent() {
        if (!surveySessions || surveySessions.length == 0) {
            return (
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No survey data."
                    style={{ margin: '2rem 1rem' }}
                />
            );
        }
        if (!isEditor) {
            return (
                <Flex style={{ padding: 12 }} vertical>
                    {_renderPathList()}
                </Flex>
            );
        }

        const hasNewRevision =
            !!lastRevisionAt && !!venue?.lastBuildAt
                ? new Date(lastRevisionAt) > new Date(venue.lastBuildAt)
                : false;
        const hasChange = _hasChange();
        const isAnalyzing = surveyQuality?.analyzeState === 'ANALYZING';
        return (
            <Flex style={{ padding: '0 12px 24px 12px' }} vertical gap={6}>
                <Text style={{ padding: '6px 12px 0 12px' }} type="secondary">
                    Enable the paths which you wish to include in the map, and update analytics to
                    see the effect of the changes on the map.
                </Text>
                <Flex
                    style={{ padding: '6px 12px' }}
                    align="center"
                    justify="space-between"
                    gap={8}
                    wrap="wrap"
                >
                    <span style={{ color: 'grey', fontSize: '80%' }}>
                        {'Last analyze: '}
                        {surveyQuality?.analyzeAt
                            ? moment(surveyQuality?.analyzeAt).format('YYYY-MM-DD HH:mm')
                            : '-'}
                    </span>
                    <Button
                        shape="round"
                        size="small"
                        type={surveyQuality?.analyzeState === 'OUTDATED' ? 'primary' : 'default'}
                        disabled={!surveyQuality || isAnalyzing}
                        loading={isAnalyzing}
                        onClick={handleAnalyzeClick}
                    >
                        Update analytics
                    </Button>
                </Flex>
                {hasNewRevision ? (
                    <Alert
                        banner
                        type="warning"
                        message="You have unapplied survey revisions. Please build a new site package to apply these changes."
                    />
                ) : undefined}
                {hasChange ? <Text type="warning">Note: You have unsaved change.</Text> : undefined}
                {_renderPathList()}
                <Flex gap="1em" style={{ padding: '1rem' }} justify="flex-end">
                    <Button onClick={_handleReset}>Reset</Button>
                    <Button type="primary" disabled={!hasChange} onClick={_handleSubmit}>
                        Save
                    </Button>
                </Flex>
            </Flex>
        );
    }

    function _renderPanel() {
        return (
            <ProCard
                colSpan={isMobile ? undefined : '30%'}
                className="venue-panel"
                style={{ overflowY: isUpdating ? 'hidden' : 'auto' }}
                ghost
                split="horizontal"
            >
                <LoadingOverlay visible={isUpdating || isLoading} spin />
                <FloorSelector
                    warnIds={venue?.maps.filter((x) => !x.surveyRecords).map((y) => y.id)}
                />
                {workingMap?.id && _renderPanelContent()}
            </ProCard>
        );
    }
    function _renderMap() {
        return (
            <div
                style={{
                    height: '100%',
                    width: '100%',
                    backgroundColor: '#ddd',
                    position: 'relative',
                }}
            >
                {venue && workingMap && surveySessions && surveySessions.length > 0 ? (
                    <FloorPlanMapView
                        imageUrl={workingMap.mapImg}
                        mapAlign={workingMap.mapAlign}
                        initCenter={workingMap?.mapAlign?.center ?? venue.center}
                        layerControl={
                            layerConfig.grayscale
                                ? { initTier: 'CartoDB.Positron', visible: false }
                                : false
                        }
                        grayscale={layerConfig.grayscale}
                    >
                        <LayerToolbar
                            options={[
                                { name: 'Waypoint', key: 'waypoint', defaultChecked: true },
                                { name: 'Path', key: 'path', defaultChecked: true },
                                { name: 'Arrow', key: 'arrow', defaultChecked: true },
                                { name: 'Grayscale', key: 'grayscale', defaultChecked: false },
                            ]}
                            onChange={(key, checked) => {
                                setLayerConfig((x: any) => ({ ...x, [key]: checked }));
                            }}
                        />
                        <SurveyPathLayer
                            data={surveySessions}
                            whitelist={checkedPaths}
                            onPathClick={(name) => {
                                setSelectedPath(name);
                                scrollToElement(name);
                            }}
                            selectedName={selectedPath}
                            showWaypoint={layerConfig.waypoint}
                            showArrow={layerConfig.arrow}
                            showPath={layerConfig.path}
                        />
                        <SurveyQualityLayer whitelist={checkedPaths} />
                    </FloorPlanMapView>
                ) : (
                    <Empty
                        image={false}
                        description={
                            !workingMap
                                ? 'Select floor plan to continue.'
                                : !surveySessions || surveySessions.length == 0
                                  ? 'No survey data, please start mapping with our survey app.'
                                  : 'No map data :('
                        }
                    />
                )}
            </div>
        );
    }
    if (!project || !venue) return <Spin />;
    return (
        <ProCard
            style={{ height: '100%' }}
            split={isMobile ? 'horizontal' : 'vertical'}
            className="full-content-height"
        >
            {contextHolder}
            {_renderPanel()}
            {_renderMap()}
        </ProCard>
    );
};

export default VenueSurveyScreen;
