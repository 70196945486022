import { PageContainer, ProCard, ProList } from '@ant-design/pro-components';
import React, { useEffect, useState } from 'react';
import { Avatar, Tag, Typography, Flex, Button, message, Space } from 'antd';
import { ProjectInfoDto, getProjectList } from 'apis/ProjectApi';
import { useAppState } from 'providers/AppStateProvider';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { ProjectInputModal } from 'components/dialogs/InputProjectDialog';
import { useAuth } from 'providers/AuthProvider';
import { ResourceRole } from 'apis/UserApi';
const { Text, Title } = Typography;

const HomeScreen: React.FC = () => {
    const { refreshUser, isSuperAdmin, hasAccess } = useAuth();
    const { activeProject, switchProject, isMobile } = useAppState();
    const [projectList, setProjectList] = useState<ProjectInfoDto[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        _refreshProjList();
    }, []);

    function _refreshProjList() {
        setIsLoading(true);
        getProjectList()
            .then((resp) => {
                setProjectList(resp.data ?? []);
            })
            .catch(() => {
                setProjectList([]);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const data = (projectList ?? []).map((item) => ({
        _item: item,
        title: (
            <Space>
                <Avatar shape="square">{(item.name ?? '-').toUpperCase().charAt(0)}</Avatar>
                <span className="single-line-ellipsis">{item.name ?? '-'}</span>
            </Space>
        ),
        subTitle: activeProject?.projId === item.id ? <Tag color="#8BC34A">Active</Tag> : undefined,
        [isMobile ? 'description' : 'content']: (
            <Flex justify="space-between" style={{ width: '100%' }}>
                <Text type="secondary" ellipsis>
                    {item.description}
                </Text>
            </Flex>
        ),
    }));

    return (
        <PageContainer
            title="Recent projects"
            extra={[
                isSuperAdmin() ? (
                    <ProjectInputModal
                        key="btn-new-proj"
                        mode="ACTION_CREATE"
                        trigger={
                            <Button type="primary" icon={<PlusOutlined />}>
                                New project
                            </Button>
                        }
                        onSuccess={() => {
                            refreshUser();
                            _refreshProjList();
                            message.success('Project created');
                        }}
                    />
                ) : undefined,
            ]}
        >
            <ProList<any>
                ghost={true}
                showActions="hover"
                loading={isLoading}
                grid={
                    isMobile
                        ? undefined
                        : {
                              gutter: 16,
                              xs: 1,
                              sm: 2,
                              md: 3,
                              lg: 4,
                              xl: 4,
                              xxl: 6,
                          }
                }
                onItem={(record: any) => {
                    return {
                        onClick: () => {
                            switchProject(record._item.id);
                            if (hasAccess(ResourceRole.VIEWER, record._item.id)) {
                                navigate('/project/');
                            }
                        },
                    };
                }}
                metas={{
                    title: {},
                    description: {},
                    subTitle: {},
                    content: {},
                }}
                itemCardProps={{ style: { marginBlockEnd: 0, width: '100%' } }}
                dataSource={data}
            />
        </PageContainer>
    );
};

export default HomeScreen;
