import { ExperimentOutlined } from '@ant-design/icons';
import { Button, Divider, Drawer, Form, Skeleton, Typography } from 'antd';
import { useState } from 'react';
import { BaseDialogProps } from './BaseDialogProps.type';
import { ProForm, ProFormGroup, ProFormSelect, ProFormText } from '@ant-design/pro-components';
import { useAppState } from 'providers/AppStateProvider';
import { ConfigSimulateDto, ConfigSimulateResultDto, simulateConfig } from 'apis/VenueApi';

export type ConfigPlaygroundProp = BaseDialogProps & {
    parentId: string;
    isLicense?: boolean;
};

export const ConfigPlaygroundDialog: React.FC<Partial<ConfigPlaygroundProp>> = (props) => {
    const { project } = useAppState();
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [result, setResult] = useState<ConfigSimulateResultDto>();
    const showDrawer = () => setOpen(true);
    const onClose = () => setOpen(false);
    const [configForm] = Form.useForm();

    const _handleSimSConfig = () => {
        if (!props.parentId) return;
        setIsLoading(true);
        simulateConfig(props.parentId, configForm.getFieldsValue())
            .then((resp) => {
                setResult(resp.data);
            })
            .catch((ex) => {
                setResult(undefined);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const _getDefaultKey = () => {
        if (props.isLicense) {
            const found = project?.licenses?.find((el) => el.id === props.parentId);
            const group = found?.accessKey?.split('.') ?? [];
            return group?.length > 0 ? group[0] : undefined;
        } else {
            return undefined;
        }
    };

    return (
        <>
            <Button icon={<ExperimentOutlined />} onClick={showDrawer} title="Config playground">
                Simulate
            </Button>
            <Drawer title="Config playground" onClose={onClose} open={open} width={'50%'}>
                <ProForm<ConfigSimulateDto>
                    form={configForm}
                    layout="vertical"
                    submitter={{
                        render: (props, doms) => {
                            return (
                                <Button type="primary" onClick={props.submit}>
                                    Test
                                </Button>
                            );
                        },
                    }}
                    initialValues={{ keyPrefix: _getDefaultKey() }}
                    onFinish={_handleSimSConfig}
                >
                    <ProFormSelect
                        name="keyPrefix"
                        label="Access Key"
                        width="md"
                        options={project?.licenses?.map((el) => {
                            const group = el.accessKey.split('.');
                            const keyPrefix = group[0];
                            return { value: keyPrefix, label: `${keyPrefix}...` };
                        })}
                        rules={[{ required: true }]}
                        disabled={props.isLicense}
                    />
                    <ProFormGroup>
                        <ProFormSelect
                            name="platform"
                            label="Platform"
                            fieldProps={{ defaultValue: 'ANY' }}
                            options={[
                                { value: 'ANY', label: 'ANY' },
                                { value: 'IOS', label: 'IOS' },
                                { value: 'AOS', label: 'AOS' },
                            ]}
                        />
                        <ProFormText
                            name="brand"
                            width="md"
                            label="Brand"
                            placeholder="Enter device brand"
                        />
                    </ProFormGroup>
                    <ProFormGroup>
                        <ProFormText
                            name="osVersion"
                            label="OS version"
                            placeholder={'Enter OS version'}
                            rules={[
                                {
                                    validator(_, value) {
                                        return /^\d+(\.\d+)*$/.test(value) ||
                                            value == null ||
                                            value == ''
                                            ? Promise.resolve()
                                            : Promise.reject(new Error('Invalid version code'));
                                    },
                                },
                            ]}
                        />
                        <ProFormText
                            name="sdkVersion"
                            label="SDK version"
                            placeholder={'Enter SDK version'}
                            rules={[
                                {
                                    validator(_, value) {
                                        return /^\d+(\.\d+)*$/.test(value) ||
                                            value == null ||
                                            value == ''
                                            ? Promise.resolve()
                                            : Promise.reject(new Error('Invalid version code'));
                                    },
                                },
                            ]}
                        />
                    </ProFormGroup>
                </ProForm>
                <Divider />
                {isLoading ? (
                    <Skeleton />
                ) : (
                    <div>
                        <Typography.Text style={{ fontWeight: 'bold' }}>Result:</Typography.Text>
                        <Typography>
                            {result ? (
                                <>
                                    <Typography.Text type="secondary">
                                        Version: {result?.version ?? '-'}
                                    </Typography.Text>
                                    <pre>
                                        {result?.content
                                            ? JSON.stringify(JSON.parse(result.content), null, 4)
                                            : ''}
                                    </pre>
                                </>
                            ) : (
                                <Typography.Text type="secondary">No result.</Typography.Text>
                            )}
                        </Typography>
                    </div>
                )}
            </Drawer>
        </>
    );
};
