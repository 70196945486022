import { ProCard } from '@ant-design/pro-components';
import React, { useEffect, useState } from 'react';
import { useAppState } from 'providers/AppStateProvider';
import {
    Typography,
    Button,
    Flex,
    Select,
    DatePicker,
    Statistic,
    Badge,
    Tooltip,
    Space,
    Skeleton,
} from 'antd';
import { getProjectStatisticDto, getProjectStatistic } from 'apis/ProjectApi';

import Plot from 'react-plotly.js';
import moment from 'moment';
import dayjs from 'dayjs';

export type ProjectStatisticProp = {};
export const ProjectStatisticModule: React.FC<ProjectStatisticProp> = (props) => {
    const { activeProject, isMobile } = useAppState();

    const [fromDate, setFromDate] = useState<Date>();
    const [toDate, setToDate] = useState<Date>();

    const [fromMonth, setFromMonth] = useState<Date>();
    const [toMonth, setToMonth] = useState<Date>();

    const [rowData, setRowData] = useState<string[]>([]);
    const [iosData, setIosData] = useState<number[]>([]);
    const [androidData, setAndroidData] = useState<number[]>([]);
    const [uniqueCount, setUniqueCount] = useState<{ aos: number; ios: number }>({
        aos: 0,
        ios: 0,
    });

    const [sorting, setSorting] = useState<string>('byDate');

    useEffect(() => {
        getStatistics();
    }, [activeProject?.projId, sorting, fromDate, toDate, fromMonth, toMonth]);

    const baseConfig: Partial<Plotly.Config> = {
        displayModeBar: false,
        responsive: true,
    };

    const baseLayoutConf: Partial<Plotly.Layout> = {
        autosize: true,
        width: undefined,
        height: 250,
        margin: {
            l: 50,
            r: 5,
            b: 50,
            t: 10,
        },
        showlegend: false,
        xaxis: {
            showgrid: false,
            zeroline: false,
        },
        yaxis: {
            title: 'Count',
            showline: false,
            zeroline: true,
        },
    };
    const aosColor = '#96be84';
    const iosColor = '#6db0d8';

    const quickSelect = (_select: string) => {
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        setToDate(today);

        let targetFromDate = new Date();
        if (sorting == 'byDate') {
            if (_select == 'last7days') {
                targetFromDate.setDate(targetFromDate.getDate() - 7);
            } else if (_select == 'last14days') {
                targetFromDate.setDate(targetFromDate.getDate() - 14);
            } else if (_select == 'lastMonth') {
                targetFromDate.setMonth(targetFromDate.getMonth() - 1);
            }
            targetFromDate.setHours(0, 0, 0, 0);
            setFromDate(targetFromDate);
        } else if (sorting == 'byMonth') {
            if (_select == 'last6Month') {
                targetFromDate.setMonth(targetFromDate.getMonth() - 6, 1);
            } else if (_select == 'lastYear') {
                targetFromDate.setMonth(targetFromDate.getMonth() - 12, 1);
            }
            targetFromDate.setHours(0, 0, 0, 0);
            setFromMonth(targetFromDate);
        }
    };

    const getStatistics = async () => {
        const projectId = activeProject?.projId ?? undefined;
        if (projectId == undefined) {
            return;
        }

        setIosData([]);
        setAndroidData([]);

        let gettingFrom;
        let gettingTo;
        let latestRowArray;

        if (sorting == 'byDate') {
            let gettingFromDate = fromDate;
            if (gettingFromDate == undefined) {
                let previous14day = new Date();
                previous14day.setHours(0, 0, 0, 0);
                previous14day.setDate(previous14day.getDate() - 14);
                gettingFromDate = previous14day;
                setFromDate(previous14day);
            }
            let gettingToDate = toDate;
            if (gettingToDate == undefined) {
                let today = new Date();
                today.setHours(0, 0, 0, 0);
                gettingToDate = today;
                setToDate(today);
            }
            let loopingDate = moment(gettingFromDate);
            let targetDate = moment(gettingToDate);
            let newDateArray = [];
            while (loopingDate.isSameOrBefore(targetDate, 'days')) {
                newDateArray.push(loopingDate.format('YYYY-MM-DD'));
                loopingDate.add(1, 'days');
            }
            gettingFrom = gettingFromDate;
            gettingTo = gettingToDate;
            latestRowArray = newDateArray;
            setRowData([...newDateArray]);
        } else if (sorting == 'byMonth') {
            let gettingFromMonth = fromMonth;
            if (gettingFromMonth == undefined) {
                let previousMonth = new Date();
                previousMonth.setHours(0, 0, 0, 0);
                previousMonth.setMonth(previousMonth.getMonth() - 1, 1);
                gettingFromMonth = previousMonth;
                setFromMonth(previousMonth);
            }

            let gettingToMonth = toMonth;
            if (gettingToMonth == undefined) {
                let thisMonth = new Date();
                thisMonth.setHours(0, 0, 0, 0);
                thisMonth.setMonth(thisMonth.getMonth() + 1, 0);
                gettingToMonth = thisMonth;
                setToMonth(thisMonth);
            }

            let loopingMonth = moment(gettingFromMonth);
            let targetMonth = moment(gettingToMonth);
            let newMonthArray = [];
            while (loopingMonth.isSameOrBefore(targetMonth, 'months')) {
                newMonthArray.push(loopingMonth.format('YYYY-MM'));
                loopingMonth.add(1, 'months');
            }
            gettingFrom = gettingFromMonth;
            gettingTo = gettingToMonth;
            latestRowArray = newMonthArray;
            setRowData([...newMonthArray]);
        }

        if (gettingFrom == undefined || gettingTo == undefined || latestRowArray == undefined) {
            return;
        }

        let mData: getProjectStatisticDto = {
            from: gettingFrom,
            to: moment(gettingTo).endOf('day').toDate(),
        };

        try {
            const resp = await getProjectStatistic(projectId, mData);
            if (resp.response?.status === 200 || resp.response?.status === 201) {
                updateData(resp.data?.result, latestRowArray);
                const devices = resp.data?.devices ?? [];
                setUniqueCount({
                    aos: devices.find((el) => el.platform === 'AOS')?.count ?? 0,
                    ios: devices.find((el) => el.platform === 'IOS')?.count ?? 0,
                });
            }
        } catch (ex: any) {}
    };

    const updateData = (result: any, _rowArray: string[]) => {
        let newIosMap = new Map();
        let newAndroidMap = new Map();

        if (sorting == 'byDate') {
            for (let dataLoop = 0; dataLoop < result.length; dataLoop++) {
                let date = result[dataLoop]['date'];
                let count = result[dataLoop]['count'];

                if (result[dataLoop].platform == 'IOS') {
                    newIosMap.set(date, count);
                } else if (result[dataLoop].platform == 'AOS') {
                    newAndroidMap.set(date, count);
                }
            }
        } else if (sorting == 'byMonth') {
            for (let dataLoop = 0; dataLoop < result.length; dataLoop++) {
                let date = result[dataLoop]['date'];
                let month = date.substring(0, 7);
                let count = result[dataLoop]['count'];

                if (result[dataLoop].platform == 'IOS') {
                    if (newIosMap.get(month) == undefined) {
                        newIosMap.set(month, count);
                    } else {
                        newIosMap.set(month, newIosMap.get(month) + count);
                    }
                } else if (result[dataLoop].platform == 'AOS') {
                    if (newAndroidMap.get(month) == undefined) {
                        newAndroidMap.set(month, count);
                    } else {
                        newAndroidMap.set(month, newAndroidMap.get(month) + count);
                    }
                }
            }
        }

        let newIosColumn = [];
        let newAndroidColumn = [];
        for (let rowLoop = 0; rowLoop < _rowArray.length; rowLoop++) {
            let iosValue = newIosMap.get(_rowArray[rowLoop]);
            newIosColumn.push(iosValue ?? 0);

            let androidValue = newAndroidMap.get(_rowArray[rowLoop]);
            newAndroidColumn.push(androidValue ?? 0);
        }
        setIosData([...newIosColumn]);
        setAndroidData([...newAndroidColumn]);
    };

    const _buildSelector = () => {
        let filterOptions = null;
        let dateSelector = null;
        if (sorting == 'byDate') {
            filterOptions = (
                <Space.Compact>
                    <Button size="small" onClick={() => quickSelect('last7days')}>
                        This week
                    </Button>
                    <Button size="small" onClick={() => quickSelect('last14days')}>
                        Two weeks
                    </Button>
                    <Button size="small" onClick={() => quickSelect('lastMonth')}>
                        Last month
                    </Button>
                </Space.Compact>
            );
            dateSelector = (
                <DatePicker.RangePicker
                    key="sel_date"
                    size="small"
                    value={[dayjs(fromDate), dayjs(toDate)]}
                    onChange={(newDate) => {
                        if (newDate != null) {
                            setFromDate(newDate[0]?.toDate());
                            setToDate(newDate[1]?.toDate());
                        }
                    }}
                />
            );
        } else {
            filterOptions = (
                <Space.Compact>
                    <Button size="small" onClick={() => quickSelect('last6Month')}>
                        Last 6 month
                    </Button>
                    <Button size="small" onClick={() => quickSelect('lastYear')}>
                        Last year
                    </Button>
                </Space.Compact>
            );
            dateSelector = (
                <DatePicker.RangePicker
                    picker="month"
                    key="sel_month"
                    size="small"
                    value={[dayjs(fromMonth), dayjs(toMonth)]}
                    onChange={(newMonth) => {
                        if (newMonth != null) {
                            setFromMonth(newMonth[0]?.toDate());
                            let fromMonth = newMonth[0]?.toDate();
                            fromMonth?.setHours(0, 0, 0, 0);
                            fromMonth?.setDate(1);
                            setFromMonth(fromMonth);

                            let toMonth = newMonth[1]?.toDate();
                            toMonth?.setHours(0, 0, 0, 0);
                            toMonth?.setMonth(toMonth?.getMonth() + 1, 0);
                            setToMonth(toMonth);
                        }
                    }}
                />
            );
        }
        return (
            <Flex key="ext_filter" gap="small" align='end' vertical={isMobile}>
                {isMobile ? undefined : filterOptions}
                {dateSelector}
                <Select
                    key="option_select"
                    defaultValue={'By date'}
                    style={{ width: 120 }}
                    size="small"
                    onChange={(setting) => {
                        setSorting(setting);
                    }}
                    options={[
                        { value: 'byDate', label: 'By date' },
                        { value: 'byMonth', label: 'By month' },
                    ]}
                />
            </Flex>
        );
    };

    return (
        <ProCard
            style={{ marginTop: '1em' }}
            title={'User engagement'}
            tooltip="Number of sessions engaged"
            extra={[_buildSelector()]}
            headerBordered
            split={isMobile ? 'horizontal' : 'vertical'}
        >
            <ProCard colSpan={isMobile ? '100%' : '90%'}>
                {iosData.length != 0 || androidData.length != 0 ? (
                    <Plot
                        style={{ flex: 1, marginTop: '0.5rem' }}
                        data={[
                            {
                                x: rowData,
                                y: iosData,
                                name: 'IOS',
                                type: 'scatter',
                                mode: 'lines+markers',
                                marker: { color: iosColor },
                            },
                            {
                                x: rowData,
                                y: androidData,
                                name: 'Android',
                                type: 'scatter',
                                mode: 'lines+markers',
                                marker: { color: aosColor },
                            },
                        ]}
                        layout={baseLayoutConf}
                        config={baseConfig}
                    />
                ) : (
                    <Flex style={{ height: 250, marginTop: '0.5rem' }}>
                        <Skeleton active />
                    </Flex>
                )}
            </ProCard>
            <ProCard split="horizontal" style={{ padding: '1rem' }} gutter={[16, 16]}>
                <p>
                    <Tooltip title="Unique device">
                        <Typography.Text type="secondary">Devices:</Typography.Text>
                    </Tooltip>
                </p>
                <Flex gap="1rem" vertical={!isMobile}>
                    <Statistic
                        title={<Badge color={aosColor} text="Android" />}
                        value={uniqueCount.aos}
                    />
                    <Statistic
                        title={<Badge color={iosColor} text={'iOS'} />}
                        value={uniqueCount.ios}
                    />
                </Flex>
            </ProCard>
        </ProCard>
    );
};
