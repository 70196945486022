import React, { useContext, createContext, useState, useEffect, useLayoutEffect } from 'react';
import { useAuth } from './AuthProvider';
import { ResourceEntry, ResourceRole } from 'apis/UserApi';
import { ProjectDetailDto, ProjectInfoDto, getProject } from 'apis/ProjectApi';
import { useNavigate } from 'react-router-dom';
import { cleanAllImgCache } from 'components/CachedImage';

export type AppContextType = {
    activeProject?: ResourceEntry;
    project?: ProjectDetailDto;

    switchProject: (projId?: string) => void;
    refreshProject: (projId?: string) => void;
    isProjectLoading?: boolean;

    isMobile: boolean;
    isTablet: boolean;

    navCollapsed: boolean;
    setNavCollapsed: (flag: boolean) => void;
};

const appContext = createContext<AppContextType>({
    activeProject: undefined,
    switchProject: (projId?: string) => {},
} as AppContextType);

const AppContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [activeProject, setActiveProj] = useState<ResourceEntry>();
    const [project, setProject] = useState<ProjectDetailDto>();
    const [isProjectLoading, setIsProjectLoading] = useState<boolean>();

    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [isTablet, setIsTablet] = useState<boolean>(false);
    const [navCollapsed, setNavCollapsed] = useState<boolean>(false);

    const { user, isSuperAdmin } = useAuth();
    const navigate = useNavigate();

    useLayoutEffect(() => {
        function updateSize() {
            setIsMobile(window.innerWidth < 598);
            setIsTablet(window.innerWidth < 992);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    useEffect(() => {
        // Clean cache
        cleanAllImgCache();
        if (!!user) {
            // Restore last proj
            const pjId = _getLastProj();
            if (!activeProject && !!pjId) switchProject(pjId);
        } else {
            setProject(undefined);
            setActiveProj(undefined);
        }
    }, [user]);

    function switchProject(proj: string | ProjectInfoDto | undefined) {
        if (proj === undefined) {
            _saveLastProj(undefined);
            setActiveProj(undefined);
            setProject(undefined);
            navigate('/');
            return;
        }
        const mProj = typeof proj === 'string' ? { id: proj } : proj;
        // Check user has access
        const projAccess = isSuperAdmin()
            ? { projId: mProj.id, role: ResourceRole.ADMIN }
            : (user?.resources ?? []).find((el) => el.projId === mProj.id);
        if (projAccess) {
            setActiveProj(projAccess);
            _saveLastProj(mProj.id);

            if (projAccess.role != ResourceRole.VIEWER) {
                refreshProject(mProj.id);
            } else {
                navigate('/');
                // refreshVenue('');
            }
        }
    }

    function refreshProject(id?: string) {
        if (isProjectLoading) return;
        setIsProjectLoading(true);
        const projId = id ?? activeProject?.projId;
        if (!projId) {
            console.warn('Missing project id');
            return;
        }
        getProject(projId)
            .then((res) => {
                if (res.data) setProject(res.data);
                setIsProjectLoading(false);
            })
            .catch(() => {
                setProject(undefined);
                setIsProjectLoading(false);
            });
    }

    function _saveLastProj(projId?: string) {
        if (!projId) {
            localStorage.removeItem('last-proj-id');
        } else {
            localStorage.setItem('last-proj-id', projId);
        }
    }
    function _getLastProj() {
        const res = localStorage.getItem('last-proj-id');
        return res != null && res?.length > 0 ? res : null;
    }

    return (
        <appContext.Provider
            value={{
                activeProject,
                switchProject,
                project,
                refreshProject,
                isProjectLoading,
                isMobile,
                isTablet,
                navCollapsed,
                setNavCollapsed,
            }}
        >
            {children}
        </appContext.Provider>
    );
};

export default AppContextProvider;

export const useAppState = () => {
    return useContext(appContext);
};
