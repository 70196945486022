import { ModalForm, ProFormText } from '@ant-design/pro-components';
import { Form, message } from 'antd';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { useAuth } from 'providers/AuthProvider';

export type ChangePasswordModuleRef = {
    open: () => void;
};

const ChangePasswordModule = React.forwardRef<ChangePasswordModuleRef, any>((props, ref) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [changePwdForm] = Form.useForm();
    const { user, changePassword } = useAuth();
    const [moduleVisible, setModuleVisible] = useState<boolean>(false);

    useImperativeHandle(ref, () => ({
        open() {
            setModuleVisible(true);
        },
    }));

    const confirmChangePassword = async () => {
        const values = changePwdForm.getFieldsValue();
        const changePwdResult = await changePassword(values.oldPassword, values.newPassword);
        if (changePwdResult?.result === true) {
            messageApi.info('Password changed successfully');
        } else {
            messageApi.error(
                changePwdResult?.error ?? 'Something went wrong, please try again later.',
            );
        }
        return changePwdResult.result;
    };

    return (
        <>
            {contextHolder}
            <ModalForm
                form={changePwdForm}
                title="Change Password"
                open={moduleVisible}
                onFinish={async () => {
                    const mPwdResult = await confirmChangePassword();
                    return mPwdResult;
                }}
                initialValues={{
                    oldPassword: null,
                    newPassword: null,
                    confirmPassword: null,
                }}
                onOpenChange={(visible) => {
                    if (!!changePwdForm) changePwdForm.resetFields();
                    setModuleVisible(visible);
                }}
                autoComplete="off"
            >
                <input
                    type="text"
                    name="email"
                    value={user?.email}
                    autoComplete="username email"
                    style={{ display: 'none' }}
                    readOnly
                />
                <ProFormText.Password
                    label="Old password"
                    name="oldPassword"
                    width="md"
                    rules={[{ required: true }]}
                    fieldProps={{ autoComplete: 'current-password' }}
                />
                <ProFormText.Password
                    label="New password"
                    tooltip="At least 8 characters"
                    name="password"
                    width="md"
                    fieldProps={{ autoComplete: 'new-password' }}
                    rules={[
                        { required: true },
                        {
                            type: 'string',
                            min: 8,
                            message: 'Password length must be at least 8 characters',
                        },
                    ]}
                />
                <ProFormText.Password
                    label="Confirm new password"
                    name="confirmPassword"
                    width="md"
                    fieldProps={{ autoComplete: 'new-password' }}
                    rules={[
                        { required: true },
                        {
                            validator(_, value) {
                                const mPwd = changePwdForm.getFieldValue('password') ?? '';
                                return !!value && value === mPwd
                                    ? Promise.resolve()
                                    : Promise.reject(new Error('Confirm password does not match'));
                            },
                        },
                    ]}
                />
            </ModalForm>
        </>
    );
});

export default ChangePasswordModule;
