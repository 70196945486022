import React, { useRef, useState } from 'react';
import type { ProSettings } from '@ant-design/pro-components';
import { ProConfigProvider, ProLayout } from '@ant-design/pro-components';
import { LogoutOutlined, KeyOutlined, DownOutlined, ReadOutlined } from '@ant-design/icons';
import { Button, ConfigProvider, Divider, Dropdown, Typography } from 'antd';
import { useAuth } from 'providers/AuthProvider';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { getPrivateRoute } from 'router/PrivateRouteConfig';
import AliasAvatar from './AliasAvatar';
import { useAppState } from 'providers/AppStateProvider';
import en_US from 'antd/es/locale/en_US';
import { ResourceRole } from 'apis/UserApi';
import LocaiteLightLogo from 'assets/locaite_logo_light.png';
import ChangePasswordModule from './dialogs/ChangePasswordDialog';
import type { MenuProps } from 'antd';

export const ScreenLayout = () => {
    const changePwdRef = useRef<any>();
    const { isAuthorized, user, logout, isSuperAdmin, hasAccess } = useAuth();
    const { activeProject, switchProject, isMobile, navCollapsed, setNavCollapsed } = useAppState();
    if (!isAuthorized) {
        return <Navigate to="/login" />;
    }
    const navigate = useNavigate();
    const location = useLocation();

    const [settings, setSetting] = useState<Partial<ProSettings> | undefined>({
        fixSiderbar: true,
        layout: 'mix',
        splitMenus: false,
        navTheme: 'light',
        contentWidth: 'Fluid',
        fixedHeader: true,
        siderMenuType: 'sub',
    });
    const [pathname, setPathname] = useState<string>();
    React.useEffect(() => {
        if (pathname !== location.pathname) {
            setPathname(location.pathname);
        }
    }, [location]);

    const isEditor = hasAccess(ResourceRole.EDITOR, activeProject?.projId);

    const [privateRoute, setPrivateRoute] = useState<any[]>([]);
    React.useEffect(() => {
        setPrivateRoute(getPrivateRoute(activeProject, isSuperAdmin(), isEditor));
    }, [activeProject, user]);

    function _renderProjSelector() {
        let displayLabel = 'Select a project';
        const mItems = (user?.resources ?? []).map((el) => {
            if (activeProject?.projId === el.projId) displayLabel = el.name ?? '-';
            return { key: el.projId, label: el.name };
        });
        return [
            <Dropdown.Button
                key="header-proj-dd"
                icon={<DownOutlined />}
                menu={{
                    items: mItems,
                    selectable: true,
                    defaultSelectedKeys: activeProject?.projId ? [activeProject!.projId] : [],
                    onClick: ({ key }) => {
                        switchProject(key);
                        navigate('/project/');
                    },
                }}
                onClick={() => {
                    if (!!activeProject?.projId) navigate('/project/');
                }}
            >
                <Typography.Text style={isMobile ? { width: 100 } : undefined} ellipsis>
                    {displayLabel}
                </Typography.Text>
            </Dropdown.Button>,
        ];
    }

    return (
        <div id="main-layout" style={{ height: '100vh' }}>
            <ConfigProvider
                locale={en_US}
                getTargetContainer={() => {
                    return document.getElementById('main-layout') || document.body;
                }}
                theme={{
                    components: {
                        Descriptions: {
                            itemPaddingBottom: 8,
                        },
                        Alert: {
                            withDescriptionPadding: '12px',
                        },
                        Tree: {
                            nodeSelectedBg: '#d3e2fd',
                        },
                    },
                }}
            >
                <ProConfigProvider hashed={true}>
                    <ProLayout
                        token={{
                            header: {
                                heightLayoutHeader: 48,
                            },
                            pageContainer: {
                                paddingBlockPageContainerContent: 16,
                                paddingInlinePageContainerContent: 24,
                            },
                        }}
                        logo={
                            <img
                                src={LocaiteLightLogo}
                                alt="Locaite Portal"
                                title="Locaite Portal"
                                className="cursor-pointer"
                                onClick={() => navigate('/home')}
                            />
                        }
                        title={'Locaite Portal'}
                        headerTitleRender={(logo, title) => <>{logo}</>}
                        locale="en-US"
                        location={{ pathname }}
                        onPageChange={(pageLocation) => {
                            if (
                                !!pageLocation?.pathname &&
                                pageLocation.pathname !== location.pathname
                            ) {
                                navigate(pageLocation.pathname);
                            }
                        }}
                        contentStyle={{ padding: 0 }}
                        siderMenuType="sub"
                        collapsed={navCollapsed}
                        onCollapse={setNavCollapsed}
                        menu={{ collapsedShowGroupTitle: true }}
                        menuItemRender={(item, dom) => (
                            <div onClick={() => setPathname(item.path || '/home')} className="w100">
                                {dom}
                            </div>
                        )}
                        menuExtraRender={(props) => {
                            if (props.isMobile) return _renderProjSelector();
                            return undefined;
                        }}
                        menuFooterRender={(props) => {
                            if (props?.collapsed) return undefined;
                            return (
                                <div style={{ textAlign: 'center', paddingBlockStart: 12 }}>
                                    <Button
                                        style={{ color: '#aaa' }}
                                        type="text"
                                        size="small"
                                        icon={<ReadOutlined />}
                                        href="https://docs.locaite.com"
                                        target="_blank"
                                    >
                                        Docs
                                    </Button>
                                    <div>©2024 by Locaite</div>
                                </div>
                            );
                        }}
                        avatarProps={{
                            src: <AliasAvatar name={user?.name ?? ''} />,
                            size: 'small',
                            title: user?.name,
                            render: (props, dom) => {
                                const mItems: MenuProps['items'] = [
                                    {
                                        key: 'ChangePwd',
                                        icon: <KeyOutlined />,
                                        label: 'Change password',
                                        onClick: () => {
                                            if (!!changePwdRef.current) {
                                                changePwdRef.current.open();
                                            }
                                        },
                                    },
                                    {
                                        type: 'divider',
                                    },
                                    {
                                        key: 'logout',
                                        icon: <LogoutOutlined />,
                                        label: 'Logout',
                                        onClick: logout,
                                    },
                                ];
                                return (
                                    <>
                                        <Dropdown menu={{ items: mItems }}>{dom}</Dropdown>
                                        <ChangePasswordModule ref={changePwdRef} />
                                    </>
                                );
                            },
                        }}
                        actionsRender={(props) => {
                            if (props.isMobile) return [];
                            if (typeof window === 'undefined') return [];
                            return _renderProjSelector();
                        }}
                        {...settings}
                        route={{
                            path: '/',
                            routes: privateRoute,
                            history: { type: 'browser' },
                        }}
                    >
                        <Outlet />
                    </ProLayout>
                </ProConfigProvider>
            </ConfigProvider>
        </div>
    );
};
