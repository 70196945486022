import ApiClient from 'utils/NetworkManager';

enum BaseRole {
    SUPER_ADMIN = 'SUPER_ADMIN',
    USER = 'USER',
}
export enum ResourceRole {
    ADMIN = 'ADMIN',
    EDITOR = 'EDITOR',
    VIEWER = 'VIEWER',
}

export const RoleLevel = {
    [BaseRole.SUPER_ADMIN]: 999,
    [BaseRole.USER]: 1,
    [ResourceRole.ADMIN]: 3,
    [ResourceRole.EDITOR]: 2,
    [ResourceRole.VIEWER]: 1
}

export interface ResourceEntryDto {
    projId: string;
    role: ResourceRole;
}
export interface ResourceEntry extends ResourceEntryDto {
    name?: string;
}

export interface UserInfoDto {
    name: string;
    role: BaseRole;
    resources: ResourceEntry[];
    enabled: boolean;
}

export interface IUserInfo extends UserInfoDto {
    id: string;
    email: string;
}
export interface ResetPasswordDto {
    id: string;
    password: string;
}

export interface CreateUserDto extends UserInfoDto {
    email: string;
    password: string;
}

export const getAllUsers = () => ApiClient.get<IUserInfo[]>(`/user/all`);
export const createUser = (data: CreateUserDto) => ApiClient.post(`/user/create`, data);
export const getUserInfo = (userId: string) => ApiClient.get<IUserInfo>(`/user/${userId}`);
export const getMyInfo = () => getUserInfo('me');
export const updateUserInfo = (userId: string, data: UserInfoDto) => ApiClient.put(`/user/${userId}`, data);
export const resetPassword = (userId: string, data: ResetPasswordDto) => ApiClient.post(`/user/${userId}/reset`, data);
export const updateResource = (userId: string, data: ResourceEntryDto) => ApiClient.post(`/user/${userId}/resource`, data);
export const addResourceByEmail = (email: string, data: ResourceEntryDto) => ApiClient.post(`/user/email/resource`, {...data, email});
export const deleteResource = (userId: string, data: ResourceEntryDto) => ApiClient.put(`/user/${userId}/resource`, data);
