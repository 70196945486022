import ApiClient from 'utils/NetworkManager';
import { AlignmentDto } from './VenueApi';

const statsBaseUrl = process.env.REACT_APP_STATS_API_BASE_URL;
export interface PaginationDto {
    total: number;
    totalPage: number;
}
export interface SessionEnquiryDto {
    limit?: number;
    page?: number;
    traceId?: string;
    appId?: string;
    deviceId?: string;
    platform?: string;
    from?: number;
    to?: number;
    validated?: boolean;
}

export interface SessionInfoDto {
    traceId: string;
    appId: string;
    deviceId: string;
    platform: string;
    date?: Date;
    duration?: number;
    fileSize?: number;
}

export interface SessionDetailDto extends SessionInfoDto {
    reqId?: string;
    projectId?: string;
    projectName?: string;
}

export interface TraceDetail {
    sdkId: string;
    sdkVersion: string;
    appId: string;
    appVersion: string;
    dModel: string;
    dId: string;
    dOsType: string;
    dOsVersion: string;
    timeZone: string;
    startAt: number;
    endAt: number;
    duration: number;
    fileName: string;
    fileSize: number;
}
export interface RouteDataDto {
    acc: number;
    alt: number;
    bea: number;
    lat: number;
    lng: number;
    fid?: string;
}
export interface RouteDto {
    name: string;
    extId: string;
    data: Partial<RouteDataDto>[];
    ts: number[];
    isMock: boolean;
    _coordinates?: [number, number][];
    _color?: string;
}
export interface MarkerDto {
    ts: number;
    name: string;
    data: [number, number];
    extId?: string;
}
export interface HistoryDto {
    category: string;
    extId?: string;
    extra?: any;
    msg?: string;
    ts: number;
}
export interface SampleDto {
    name: string;
    data: any[];
    _color?: string;
}
export interface VenuePreviewDto {
    id: string;
    venueName: string;
    name: string;
    imageUrl?: string;
    mapAlign?: AlignmentDto;
}
export interface TraceSession {
    traceId: string;
    reqId: string;
    detail: TraceDetail;
    route: RouteDto[];
    marker: MarkerDto[];
    history: HistoryDto[];
    venue: VenuePreviewDto[];
    sample: SampleDto[];
    rtConfig: [string, string | number | boolean][];
    dlUrl: string;
}

export interface CrashEnquiryDto {
    limit?: number;
    page?: number;
    appId: string;
    from?: number;
    to?: number;
}

export interface CrashRecordDto {
    id: string;
    appId: string;
    date?: Date;
    detail?: string;
}

export const searchSession = (filter: SessionEnquiryDto) =>
    ApiClient.get<SessionInfoDto[]>(`/session/list`, filter);
export const getTraceDetail = (traceId: string) =>
    ApiClient.get<SessionDetailDto>(`/session/${traceId}`);
export const traceSession = (traceId: string) =>
    ApiClient.get<SessionDetailDto>(`${statsBaseUrl}/analysis/trace?id=${traceId}`);
export const getTraceFileUrl = (fileName: string) =>
    `${statsBaseUrl}/analysis/trace/download?trace_file=${fileName}`;
export const searchCrashLog = (filter: CrashEnquiryDto) =>
    ApiClient.get<CrashRecordDto[]>(`/session/crash`, filter);
export const getCrashDlUrl = (crashId: string) =>
    ApiClient.post<{ dlUrl: string }>(`/session/crash/dl`, { id: crashId });
