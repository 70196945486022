import { Button, Drawer, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { BaseDialogProps } from './BaseDialogProps.type';
import Markdown from 'react-markdown';
import { useAppState } from 'providers/AppStateProvider';

export type ViewDocumentProp = BaseDialogProps & {
    docName: string;
    btnType?: 'link' | 'text' | undefined;
};

export const ViewDocumentModule: React.FC<Partial<ViewDocumentProp>> = (props) => {
    const { isMobile, isTablet } = useAppState();
    const [open, setOpen] = useState(false);
    const showDrawer = () => setOpen(true);
    const onClose = () => setOpen(false);
    const [docContent, setDocContent] = useState<string>();
    useEffect(() => {
        if (open && !docContent && !!props.docName) {
            const docUrl = /^((http|https|ftp):\/\/)/.test(props.docName ?? '')
                ? props.docName
                : `/assets/docs/${props.docName}`;
            fetch(docUrl)
                .then((resp) => resp.text())
                .then((body) => {
                    setDocContent(body);
                })
                .catch(console.error);
        }
    }, [open]);

    function _jumpToHashKey(key: string) {
        const rootDoc = document.getElementById('doc-help-drawer');
        if (!rootDoc) return;
        const headings = rootDoc.querySelectorAll('h1, h2, h3, h4, h5, h6');
        (headings ?? []).forEach(function (heading) {
            const id = heading.innerHTML
                .toLowerCase()
                .replace(/[^\w\s]/g, '')
                .replace(/\s/g, '-');
            if (`#${id}` === key) {
                heading.scrollIntoView({ behavior: 'smooth' });
                return;
            }
        });
    }

    function _getTitle() {
        if (!docContent) return props.docName;
        const match = docContent.match(/^#\s+(.+)/);
        if (match && match.length > 1) {
            return match[1];
        } else {
            return props.docName;
        }
    }
    const mWidth = isMobile ? '100%' : isTablet ? '80%' : '50%';
    return (
        <>
            <Button type={props.btnType ?? 'text'} size="small" onClick={showDrawer} title="Help">
                {props.trigger ?? <QuestionCircleOutlined />}
            </Button>
            <Drawer
                id="doc-help-drawer"
                title={_getTitle()}
                onClose={onClose}
                open={open}
                width={mWidth}
            >
                <Markdown
                    components={{
                        code({ node, className, children, ...elProps }) {
                            const multiLine = `${children ?? ''}`.includes('\n');
                            return multiLine ? (
                                <Typography>
                                    <pre>{children}</pre>
                                </Typography>
                            ) : (
                                <Typography.Text code className={className} {...elProps}>
                                    {children}
                                </Typography.Text>
                            );
                        },
                        a({ node, className, children, ...elProps }) {
                            const mHref = elProps.href;
                            if (!!mHref && mHref.startsWith('#')) {
                                return (
                                    <a className={className} onClick={() => _jumpToHashKey(mHref)}>
                                        {children}
                                    </a>
                                );
                            } else {
                                return (
                                    <a className={className} {...elProps}>
                                        {children}
                                    </a>
                                );
                            }
                        },
                    }}
                >
                    {docContent}
                </Markdown>
            </Drawer>
        </>
    );
};
