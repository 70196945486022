import { PageContainer, ProDescriptions, ProCard } from '@ant-design/pro-components';
import React, { useEffect, useState } from 'react';
import { Button, message, Typography, Tooltip, Tag, Space, Spin, Menu, Flex } from 'antd';
import { EditOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import {
    EditVenueBoundConfigModule,
    AddVenuePackageConfigModule,
} from 'components/dialogs/HandleProjVenueDialog';
import { useAuth } from 'providers/AuthProvider';
import { useVenueState } from 'providers/VenueProvider';
import moment from 'moment';
import { formatDateAgo } from 'utils/DateUtils';
import VenueBoundMapView from 'components/maps/VenueBoundMapView';
import TextArea from 'antd/es/input/TextArea';
import { useAppState } from 'providers/AppStateProvider';
import { ViewDocumentModule } from 'components/dialogs/DocumentDialog';
import RuntimeConfigSection from './RuntimeConfigSection';
const { Text } = Typography;

const BoundConfigSection: React.FC = () => {
    const { venue, venueBoundConfig, fetchBoundConfig } = useVenueState();
    useEffect(() => {
        if (!!venue) fetchBoundConfig(venue.id);
    }, [venue]);
    if (!venue || !venueBoundConfig) return <Spin />;
    return (
        <ProCard
            title={'Bound config'}
            tooltip="Venue detection criteria"
            extra={
                !venueBoundConfig ? undefined : (
                    <EditVenueBoundConfigModule
                        venueId={venue.id}
                        editingConfig={venueBoundConfig}
                        trigger={
                            <Button icon={<EditOutlined />} title="Edit">
                                Edit
                            </Button>
                        }
                        onSuccess={() => {
                            fetchBoundConfig(venue.id);
                            message.success('Bound config updated');
                        }}
                    />
                )
            }
        >
            {!venueBoundConfig ? undefined : (
                <ProDescriptions column={2} layout="vertical">
                    <ProDescriptions.Item label="Doorway beacons">
                        {venueBoundConfig.beacons ? venueBoundConfig.beacons.length : 0}
                    </ProDescriptions.Item>
                    <ProDescriptions.Item label="Radius">
                        {venueBoundConfig.radius}m
                    </ProDescriptions.Item>
                    <ProDescriptions.Item label="Boundary">
                        <div style={{ width: '100%', height: 300 }}>
                            <VenueBoundMapView
                                center={venue.center}
                                radius={venueBoundConfig.radius}
                                polygon={venueBoundConfig.polygon}
                            />
                        </div>
                    </ProDescriptions.Item>
                </ProDescriptions>
            )}
        </ProCard>
    );
};

const PackageConfigSection: React.FC = () => {
    const { venue, venueRtConfig, fetchVenueRtConfig } = useVenueState();
    useEffect(() => {
        if (!!venue) fetchVenueRtConfig(venue.id);
    }, [venue]);
    if (!venue || !venueRtConfig) return <Spin />;

    return (
        <ProCard
            title="Package config"
            tooltip={{
                icon: (
                    <ViewDocumentModule
                        trigger={<QuestionCircleOutlined />}
                        docName="packageConfig.md"
                    />
                ),
            }}
            extra={[
                <Space key="pkg-ext-tag">
                    {venueRtConfig?.package?.enabled === true ? undefined : (
                        <Tag style={{ fontSize: '110%', padding: 5 }} color="gold">
                            Using default config
                        </Tag>
                    )}
                </Space>,
                <AddVenuePackageConfigModule
                    key="pkg-ext-edit"
                    venueId={venue.id}
                    processingConfig={venueRtConfig?.package}
                    isEdit={!!venueRtConfig?.package?.id}
                    trigger={
                        <Button icon={<EditOutlined />} title="Edit">
                            Edit
                        </Button>
                    }
                    onSuccess={() => {
                        fetchVenueRtConfig(venue.id);
                        message.success('Package config saved');
                    }}
                />,
            ]}
        >
            <Flex>
                <Flex gap={'1em'} style={{ flexDirection: 'column' }}>
                    <Flex gap={'1em'}>
                        {venueRtConfig?.package?.desc ?? '-'}
                        <Tag color={venueRtConfig?.package?.enabled ? 'green' : 'lightgrey'}>
                            {venueRtConfig?.package?.enabled ? 'ENABLED' : 'DISABLED'}
                        </Tag>
                    </Flex>
                    <Flex gap={'1em'}>
                        <Text>Last update: </Text>
                        {venueRtConfig?.package?.updatedAt ? (
                            <Tooltip
                                title={moment(new Date(venueRtConfig?.package.updatedAt)).format(
                                    'DD-MMMM-YYYY HH:mm',
                                )}
                            >
                                {formatDateAgo(venueRtConfig?.package.updatedAt)}
                            </Tooltip>
                        ) : (
                            <span>-</span>
                        )}
                    </Flex>
                    {venueRtConfig?.package?.content ? (
                        <TextArea
                            rows={5}
                            contentEditable={false}
                            readOnly={true}
                            value={JSON.stringify(
                                JSON.parse(venueRtConfig?.package?.content),
                                null,
                                3,
                            )}
                        />
                    ) : undefined}
                </Flex>
            </Flex>
        </ProCard>
    );
};

const VenueConfigScreen: React.FC = () => {
    const { isSuperAdmin } = useAuth();
    const { isMobile } = useAppState();
    const { venue } = useVenueState();
    const [configTab, setConfigTab] = useState<string>('runtime');
    if (!isSuperAdmin()) return <span>No permission.</span>;
    return (
        <PageContainer breadcrumb={undefined} title={null} header={{ title: undefined }}>
            <ProCard split={isMobile ? 'horizontal' : 'vertical'}>
                <Menu
                    onClick={(e) => setConfigTab(e.key)}
                    style={
                        isMobile
                            ? { backgroundColor: 'transparent' }
                            : {
                                  width: '20%',
                                  maxWidth: '200px',
                                  padding: '1em',
                                  backgroundColor: 'transparent',
                              }
                    }
                    defaultSelectedKeys={['runtime']}
                    mode={isMobile ? 'horizontal' : 'inline'}
                    items={[
                        { key: 'runtime', label: 'Runtime' },
                        { key: 'bound', label: 'Bound' },
                        { key: 'package', label: 'Package' },
                    ]}
                />
                <ProCard ghost>
                    {configTab === 'runtime' ? (
                        <RuntimeConfigSection parentId={venue?.id} enableSimulate />
                    ) : configTab === 'bound' ? (
                        <BoundConfigSection />
                    ) : configTab === 'package' ? (
                        <PackageConfigSection />
                    ) : undefined}
                </ProCard>
            </ProCard>
        </PageContainer>
    );
};

export default VenueConfigScreen;
