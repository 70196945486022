import { useRef, useContext, createContext, useEffect, useState, memo } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'styles/map-style.css';
import { HK_CENTER } from 'services/BaseMapEngine';
import { tilesList } from 'components/maps/MapTileControl';

export type LngLatPair = [number, number] | { lat: number; lng: number };

export type MapBoxContextType = {
    map?: mapboxgl.Map;
    switchTier: (key: string) => void;
};

const mapBoxContext = createContext<MapBoxContextType>({
    map: undefined,
} as MapBoxContextType);

export type MapBoxContainerProps = {
    center?: LngLatPair;
    initTier?: string;
    children?: React.ReactNode;
};

const MapBoxContainer: React.FC<MapBoxContainerProps> = (props) => {
    const mapContainerRef = useRef<HTMLElement>();
    const [mapRef, setMapRef] = useState<mapboxgl.Map>();
    const [tierKey, switchTier] = useState<string | undefined>(props.initTier);

    useEffect(() => {
        mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_KEY!;
        const mCustomTier = tierKey ? tilesList.find((el) => el.key === tierKey) : undefined;
        const mMap = new mapboxgl.Map({
            container: mapContainerRef.current!,
            style: mCustomTier
                ? _getTierStyleConfig(mCustomTier)
                : 'mapbox://styles/mapbox/streets-v12',
            center: props.center ?? HK_CENTER,
            zoom: 12,
        });
        mMap.on('load', () => {
            setMapRef(mMap);
        });
        return () => {};
    }, []);

    useEffect(() => {
        if (!!mapRef && !!tierKey) {
            const mCustomTier = tierKey ? tilesList.find((el) => el.key === tierKey) : undefined;
            const updatedMap = mapRef.setStyle(
                mCustomTier
                    ? _getTierStyleConfig(mCustomTier)
                    : 'mapbox://styles/mapbox/streets-v12',
            );
            updatedMap.on('load', () => {
                setMapRef(updatedMap);
            });
        }
    }, [tierKey]);

    function _getTierStyleConfig(tierConf: any): mapboxgl.Style {
        return {
            version: 8,
            sources: {
                'custom-tile-source': {
                    type: 'raster',
                    tiles: (tierConf.subdomains ?? ['a', 'b', 'c']).map((x: string) =>
                        tierConf.url.replace('{s}', x),
                    ),
                    tileSize: 256,
                    attribution: tierConf.attribution,
                },
            },
            layers: [
                {
                    id: 'custom-tile-layer',
                    type: 'raster',
                    source: 'custom-tile-source',
                    minzoom: 0,
                    maxzoom: 19,
                },
            ],
        };
    }

    return (
        <mapBoxContext.Provider value={{ map: mapRef, switchTier }}>
            <div
                ref={mapContainerRef as any}
                id="map-view"
                style={{ width: '100%', height: '100%' }}
            />
            {props.children}
        </mapBoxContext.Provider>
    );
};

export default memo(MapBoxContainer);

export const useMapBox = () => {
    return useContext(mapBoxContext);
};
