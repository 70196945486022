import React, { useRef, useEffect, useImperativeHandle } from 'react';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import 'styles/map-style.css';
import { useMapBox } from './MapBoxContainer';
import { Feature, GeoJsonProperties } from 'geojson';
import { Polygon } from '@turf/turf';

export interface MapBoxDrawLayerProps {
    onChanged?: (id?: string, feature?: Feature<Polygon, GeoJsonProperties>) => void;
}

export type MapBoxDrawLayerRef = {
    resetAll: () => void;
    addPolygon: () => void;
    removeById: (ids: string | string[]) => void;
};

const MapBoxDrawLayer = React.forwardRef<MapBoxDrawLayerRef, MapBoxDrawLayerProps>((props, ref) => {
    const layerLoaded = useRef<boolean>(false);
    const drawRef = useRef<MapboxDraw>(
        new MapboxDraw({
            displayControlsDefault: false,
        }),
    );
    const { map } = useMapBox();

    useEffect(() => {
        _initMapLayers();
    }, [map]);

    const _initMapLayers = () => {
        if (!map || layerLoaded.current == true) return;
        map.addControl(drawRef.current as any);
        map.on('draw.create', onDrawChanged);
        map.on('draw.update', onDrawChanged);
        layerLoaded.current = true;
    };

    const onDrawChanged = (ev: any) => {
        const feat = ev.features && ev.features.length === 1 ? ev.features[0] : undefined;
        if (props.onChanged) props.onChanged(feat?.id, feat);
    };

    useImperativeHandle(ref, () => ({
        resetAll() {
            if (layerLoaded.current && !!drawRef.current.deleteAll) {
                drawRef.current.deleteAll();
            }
        },
        addPolygon() {
            if (layerLoaded.current && !!drawRef.current.changeMode)
                drawRef.current.changeMode('draw_polygon');
        },
        removeById(ids: string | string[]) {
            if (layerLoaded.current && !!drawRef.current.delete) drawRef.current.delete(ids);
        },
    }));

    return null;
});

export default React.memo(MapBoxDrawLayer);
