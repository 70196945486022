import React, { useState } from 'react';
import type { FormProps } from 'antd';
import { Button, Form, Input, Typography } from 'antd';
import { useAuth } from '../providers/AuthProvider';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

type FieldType = {
    username?: string;
    password?: string;
};

const LoginScreen: React.FC = () => {
    const [hasError, setHasError] = useState<boolean>(false);
    const { login } = useAuth();
    const { Title, Text } = Typography;

    const onFinish: FormProps<FieldType>['onFinish'] = async (values) => {
        if (!!values.username && !!values.password) {
            login(values.username, values.password).then((result) => {
                if (!result) setHasError(true);
            });
        }
    };

    return (
        <div className="bg-wrapper" style={{ paddingTop: '5%' }}>
            <Form
                name="basic"
                layout="vertical"
                className="login-form"
                onFinish={onFinish}
                onChange={() => setHasError(false)}
                autoComplete="off"
            >
                <Form.Item style={{ textAlign: 'left' }}>
                    <Title>Sign in</Title>
                    <span>to continue to Locaite</span>
                </Form.Item>
                <Form.Item<FieldType>
                    label="Email"
                    name="username"
                    validateStatus={hasError ? 'error' : undefined}
                    rules={[{ required: true, message: 'Please input your email address!' }]}
                >
                    <Input
                        placeholder="Email address"
                        size="large"
                        autoComplete="email username"
                        prefix={<UserOutlined className={'prefixIcon'} />}
                    />
                </Form.Item>
                <Form.Item<FieldType>
                    label="Password"
                    name="password"
                    validateStatus={hasError ? 'error' : undefined}
                    rules={[{ required: true, message: 'Please input your password!' }]}
                >
                    <Input.Password
                        placeholder="Password"
                        size="large"
                        autoComplete="current-password"
                        prefix={<LockOutlined className={'prefixIcon'} />}
                    />
                </Form.Item>
                {hasError ? (
                    <Text type="danger" style={{ fontWeight: 'bold' }}>
                        Invalid email or password.
                    </Text>
                ) : undefined}
                <br />
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        shape="round"
                        style={{ width: '100%' }}
                    >
                        Login
                    </Button>
                </Form.Item>
                <p>
                    <Text type="secondary">Forgot password? Contact your admin.</Text>
                </p>
            </Form>
        </div>
    );
};

export default LoginScreen;
