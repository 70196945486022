import { ModalForm, ProFormSelect, ProFormText } from '@ant-design/pro-components';
import { Form, Typography } from 'antd';
import { useState } from 'react';
import { ResetPasswordDto, resetPassword } from 'apis/UserApi';
import { BaseDialogProps } from './BaseDialogProps.type';
import { BasicListTier } from '../BasicTier';
import { Link } from 'react-router-dom';
const { Text } = Typography;

export type ResetPasswordProp = BaseDialogProps & {
    // projectId: string;
    userId : string;
};

export const ResetPasswordModule: React.FC<ResetPasswordProp> = (props) => {
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [notFoundErr, setNotFoundErr] = useState<boolean>(false);
    const [addUserForm] = Form.useForm();
    return (
        <ModalForm
            form={addUserForm}
            title={"Reset password"}
            trigger={props.trigger}
            
            initialValues={{
                role: 'EDITOR',
            }}
            submitter={{
                searchConfig: {
                    submitText: 'Confirm',
                    resetText: 'Cancel',
                },
            }}
            loading={formLoading}
            onOpenChange={() => {
                //addUserForm.resetFields();
            }}
            onFinish={async (values) => {
                setFormLoading(true);
                const mData: ResetPasswordDto = {
                    id: props.userId,
                    password: values.passwordField
                }
                let result = false;
                try {
                    const resp = await resetPassword(props.userId, mData);
                    if (resp.response?.status === 200) {
                        if (props.onSuccess) props.onSuccess();
                        result = true;
                    }
                } catch (ex: any) {
                    
                }

                setFormLoading(false);
                return result;
            }}
        >
            <ProFormText
                width="lg"
                name="passwordField"
                label="Password"
                initialValue={""}
                placeholder="Enter password"
                tooltip="At least 8 characters"
                rules={[
                    { required: true, message: 'Please enter a password!' },
                    {
                        type: 'string',
                        min: 8,
                        message: 'Password must be at least 8 characters.',
                    },
                ]}
            />
        </ModalForm>
    );
};
