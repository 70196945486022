import {
    PaginationDto,
    RouteDataDto,
    SessionDetailDto,
    SessionEnquiryDto,
    SessionInfoDto,
    TraceSession,
    getTraceDetail,
    searchSession,
    traceSession,
} from 'apis/TraceApi';
import React, { createContext, useContext, useState } from 'react';
import { Outlet } from 'react-router-dom';

const plotlyColorSet = [
    '#1f77b4',
    '#ff7f0e',
    '#2ca02c',
    '#d62728',
    '#9467bd',
    '#8c564b',
    '#e377c2',
    '#7f7f7f',
    '#bcbd22',
    '#17becf',
];

const useTraceState = () => {
    // Search session related
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [sessionList, setSessionList] = useState<SessionInfoDto[]>([]);
    const [pageConfig, setPageConfig] = useState<PaginationDto>({ total: 0, totalPage: 0 });
    const [searchMode, setSearchMode] = useState<string>('search-trace');
    const [traceFilter, setTraceFilter] = useState<SessionEnquiryDto>({
        page: 0,
        limit: 20,
    });
    // Session detail related
    const [traceInfo, setTraceInfo] = useState<SessionDetailDto>();
    const [traceDetail, setTraceDetail] = useState<TraceSession>();

    function querySession(filter: SessionEnquiryDto) {
        setTraceFilter(filter);
        setIsLoading(true);
        searchSession(filter)
            .then((resp) => {
                if (resp.data) {
                    setPageConfig(resp.response?.data?._metadata?.pagination);
                    setSessionList(resp.data);
                }
            })
            .catch(console.error)
            .finally(() => {
                setIsLoading(false);
            });
    }

    function fetchSession(input: string | SessionInfoDto) {
        const mTraceInfo =
            typeof input === 'string' ? { traceId: input, appId: '-', deviceId: '-' } : input;
        setTraceInfo(mTraceInfo as SessionInfoDto);
        _viewSession(mTraceInfo.traceId).catch(console.error);
    }

    async function _viewSession(traceId: string) {
        setIsLoading(true);
        try {
            const resp = await getTraceDetail(traceId);
            setTraceInfo(resp.data);
            const traceResp = await traceSession(traceId);

            const randomColor = () => `#${Math.floor(Math.random() * 16777215).toString(16)}`;
            const clonedData = traceResp.response?.data
                ? { ...traceResp.response.data }
                : undefined;
            // Fill sample data
            if (clonedData?.sample) {
                for (let j = 0; j < clonedData.sample.length; j++) {
                    clonedData.sample[j]._color = plotlyColorSet[j % 10];
                }
            }
            // Fill route data
            if (clonedData?.route) {
                for (let j = 0; j < clonedData.route.length; j++) {
                    const _coordinates: [number, number][] = [];
                    let last = {} as RouteDataDto;
                    for (let i = 0; i < clonedData.route[j].data.length; i++) {
                        clonedData.route[j].data[i] = { ...last, ...clonedData.route[j].data[i] };
                        last = clonedData.route[j].data[i];
                        if (last?.lat && last?.lng) _coordinates.push([last.lat, last.lng]);
                    }
                    clonedData.route[j]._coordinates = _coordinates;
                    clonedData.route[j]._color = plotlyColorSet[j % 10];
                }
            }
            setTraceDetail(clonedData);
        } catch (ex) {
            setTraceDetail(undefined);
            console.error(ex);
        } finally {
            setIsLoading(false);
        }
    }

    function switchSearchMode(mode: string) {
        setSearchMode(mode);
    }

    function resetDetail() {
        setTraceDetail(undefined);
    }

    return {
        sessionList,
        pageConfig,
        querySession,
        isLoading,
        fetchSession,
        traceInfo,
        traceDetail,
        searchMode,
        switchSearchMode,
        traceFilter,
        resetDetail,
    };
};

export type TraceContextType = {
    sessionList: SessionInfoDto[];
    pageConfig: PaginationDto;
    querySession: (filter: SessionEnquiryDto) => void;
    isLoading: boolean;
    fetchSession: (input: string | SessionInfoDto) => void;
    traceInfo?: SessionDetailDto;
    traceDetail?: TraceSession;
    searchMode: string;
    switchSearchMode: (mode: string) => void;
    traceFilter: SessionEnquiryDto;
    resetDetail: () => void;
};
const TraceContext = createContext<TraceContextType>({} as TraceContextType);
const TraceContextProvider: React.FC = () => {
    return (
        <TraceContext.Provider value={useTraceState()}>
            <Outlet />
        </TraceContext.Provider>
    );
};
export default TraceContextProvider;
export const useTraceContext = () => {
    const ctx = useContext(TraceContext);
    if (!ctx) {
        throw new Error('useTraceContext has to be used within <TraceContextProvider>');
    }
    return ctx;
};
