import React, { useContext, createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    User,
    setPersistence,
    getAuth,
    updatePassword,
    signInWithEmailAndPassword,
    onAuthStateChanged,
    browserLocalPersistence,
} from 'firebase/auth';
import { IUserInfo, ResourceRole, RoleLevel, getMyInfo } from 'apis/UserApi';

export type AuthUser = User & {
    role: string;
};

export type AuthContextType = {
    isReady: boolean;
    isAuthorized: boolean;
    user: IUserInfo | null;
    isSuperAdmin: () => boolean;
    hasAccess: (targetRole: ResourceRole, resId?: string) => boolean;
    refreshUser: () => void;
    login: (username: string, password: string) => Promise<boolean>;
    logout: () => void;
    changePassword: (
        oldPassword: string,
        password: string,
    ) => Promise<{ result: boolean; error?: string }>;
    downgrade: (targetRole: ResourceRole) => void;
};

const authContext = createContext<AuthContextType>({
    isReady: false,
    isAuthorized: false,
    user: null,
} as AuthContextType);

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isReady, setReady] = useState<boolean>(false);
    const [isAuthorized, seAuthorized] = useState<boolean>(false);
    const [authUser, setAuthUser] = useState<AuthUser | null>(null);
    const [user, setUser] = useState<IUserInfo | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, async (mUser) => {
            if (!!mUser) {
                _setAuthData(mUser, false);
            }
            setReady(true);
        });
    }, []);

    function _decodeUser(input: any): AuthUser {
        let baseRole = 'USER';
        try {
            const attrStr = input?.reloadUserInfo?.customAttributes;
            const attrObj = JSON.parse(attrStr);
            baseRole = attrObj['role'];
        } catch (ex) {}
        return { ...input, role: baseRole };
    }

    async function _fetchMyInfo(): Promise<IUserInfo | null> {
        try {
            const result = await getMyInfo();
            if (!!result.data?.id) {
                const mResources = result.data.resources ?? [];
                mResources.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));
                return { ...result.data, resources: mResources };
            } else {
                return null;
            }
        } catch (ex) {
            console.error('Cannot fetch my info', ex);
            return null;
        }
    }

    async function _setAuthData(fbUser: User | null, redirect: boolean = true) {
        if (fbUser) {
            seAuthorized(true);
            setAuthUser(_decodeUser(fbUser));
            const portalUser = await _fetchMyInfo();
            setUser(portalUser);
            if (redirect) navigate('/', { replace: true });
        } else {
            seAuthorized(false);
            setAuthUser(null);
            setUser(null);
            if (redirect) navigate('/login', { replace: true });
        }
    }

    async function login(username: string, password: string): Promise<boolean> {
        const auth = getAuth();
        try {
            await setPersistence(auth, browserLocalPersistence);
            const userCredential = await signInWithEmailAndPassword(auth, username, password);
            // Signed in
            _setAuthData(userCredential.user);
            return true;
        } catch (error) {
            console.error('Signed in failed: ', (error as any)['code']);
            return false;
        }
    }

    async function logout(): Promise<void> {
        try {
            await getAuth().signOut();
        } catch (ex) {}
        _setAuthData(null).then(() => {});
    }

    async function changePassword(
        oldPassword: string,
        password: string,
    ): Promise<{ result: boolean; error?: string }> {
        try {
            if (authUser?.email) {
                const userCredential = await signInWithEmailAndPassword(
                    getAuth(),
                    authUser.email,
                    oldPassword,
                );
                await updatePassword(userCredential.user, password);
                return { result: true };
            }
            return { result: false, error: 'Missing email address.' };
        } catch (ex) {
            if (ex?.toString().toLowerCase().includes('auth/invalid-credential')) {
                return { result: false, error: 'Incorrect old password' };
            }
            return { result: false, error: ex?.toString() ?? 'unknown' };
        }
    }

    function isSuperAdmin(): boolean {
        return user?.role === 'SUPER_ADMIN';
    }

    function hasAccess(resRole: ResourceRole, resId?: string): boolean {
        if (isSuperAdmin()) return true;
        if (!resId) {
            // Check max role
            const maxRole = (user?.resources ?? []).reduce(
                (max, el) => Math.max(max, RoleLevel[el.role]),
                -Infinity,
            );
            return maxRole >= RoleLevel[resRole];
        } else {
            const mResConf = (user?.resources ?? []).find((el) => el.projId === resId);
            return !!mResConf && RoleLevel[mResConf.role] >= RoleLevel[resRole];
        }
    }
    function downgrade(targetRole: ResourceRole) {
        if (process.env.NODE_ENV !== 'development' || !user || !isSuperAdmin()) return;
        const mUser = { ...user, role: 'USER' };
        mUser.resources.forEach((el) => {
            el.role = targetRole;
        });
        setUser(mUser as IUserInfo);
        alert('Downgraded to: ' + targetRole);
    }

    function refreshUser(): void {
        _fetchMyInfo()
            .then((info) => {
                setUser(info);
            })
            .catch(() => {});
    }

    return (
        <authContext.Provider
            value={{
                isReady,
                isAuthorized,
                user,
                login,
                logout,
                changePassword,
                isSuperAdmin,
                refreshUser,
                hasAccess,
                downgrade,
            }}
        >
            {children}
        </authContext.Provider>
    );
};

export default AuthProvider;

export const useAuth = () => {
    return useContext(authContext);
};
