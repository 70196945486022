import {
    PageContainer,
    ProCard,
    ProFormText,
    ProFormDateTimeRangePicker,
    ProForm,
    ProFormSelect,
    ProFormCheckbox,
} from '@ant-design/pro-components';
import { Input, Tabs, Form, Space, Tooltip } from 'antd';
import React, { useEffect } from 'react';
import { Tag, Typography, Table } from 'antd';
import {
    AppleFilled,
    MobileOutlined,
    AndroidFilled,
    WarningTwoTone,
    FileUnknownTwoTone,
} from '@ant-design/icons';
import { TableProps } from 'antd/lib';
import { useAuth } from 'providers/AuthProvider';
import { useTraceContext } from 'providers/TraceProvider';
import { SessionEnquiryDto, SessionInfoDto } from 'apis/TraceApi';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import { useAppState } from 'providers/AppStateProvider';
const { Title } = Typography;

const TraceSearchScreen: React.FC = () => {
    const { isMobile } = useAppState();
    const { isSuperAdmin } = useAuth();
    const navigate = useNavigate();
    const [searchForm] = Form.useForm();
    const {
        sessionList,
        pageConfig,
        querySession,
        isLoading,
        searchMode,
        switchSearchMode,
        traceFilter,
    } = useTraceContext();

    useEffect(() => {
        if (!!traceFilter) {
            searchForm.setFieldsValue(traceFilter);
        }
    }, []);

    function _handleQueryChange(paging?: { limit?: number; page?: number }) {
        const mLimit = paging?.limit ?? traceFilter.limit ?? 20;
        const mPage = paging?.page ?? traceFilter.page ?? 0;
        const inAppId = searchForm.getFieldValue('appId');
        const inDeviceId = searchForm.getFieldValue('deviceId');
        const inPlatform = searchForm.getFieldValue('platform');
        const inDate = searchForm.getFieldValue('date');
        const inValidated = searchForm.getFieldValue('validated');
        const mFilter: SessionEnquiryDto = { limit: mLimit, page: mPage };
        if (inAppId?.length > 0) mFilter.appId = inAppId;
        if (inDeviceId?.length > 0) mFilter.deviceId = inDeviceId;
        if (inPlatform?.length > 0) mFilter.platform = inPlatform;
        if (inDate?.length == 2) {
            mFilter.from = new Date(inDate[0]).getTime();
            mFilter.to = new Date(inDate[1]).getTime();
        }
        mFilter.validated = inValidated == undefined ? false : inValidated;
        querySession(mFilter);
    }

    const tabItems = [{ key: 'search-trace', label: 'Trace ID' }];
    if (isSuperAdmin()) tabItems.push({ key: 'search-advance', label: 'Advance search' });

    const columns: TableProps<SessionInfoDto>['columns'] = [
        {
            title: 'Trace ID',
            dataIndex: 'traceId',
            key: 'traceId',
            render: (field, record, index) => (
                <Space>
                    <Link key={`tt-${index}`} to={`/analysis/session/${field}`} state={record}>
                        {field}
                    </Link>
                    {record?.fileSize == null ? (
                        <FileUnknownTwoTone twoToneColor="red" title="Trace file not found" />
                    ) : (record?.duration ?? -1) < 5000 || (record?.fileSize ?? -1) < 100000 ? (
                        <WarningTwoTone twoToneColor="orange" title="Issue detected" />
                    ) : undefined}
                </Space>
            ),
        },
        {
            title: 'App Id',
            dataIndex: 'appId',
            key: 'appId',
            render: (field, _, index) => <Tag key={`ta-${index}`}>{field}</Tag>,
        },
        {
            title: 'Device Id',
            dataIndex: 'deviceId',
            key: 'deviceId',
            render: (field, record, index) => (
                <Space>
                    {record.platform === 'AOS' ? (
                        <AndroidFilled style={{ color: '#4faf53' }} />
                    ) : record.platform === 'IOS' ? (
                        <AppleFilled />
                    ) : (
                        <MobileOutlined />
                    )}
                    <span key={`tde-${index}`}>{field}</span>
                </Space>
            ),
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (field, entity, index) => {
                const twoDigit = (val: number) => (val < 10 ? `0${val}` : val);
                const duration = moment.duration(entity.duration);
                const strDuration =
                    duration.hours() > 0
                        ? `${twoDigit(duration.hours())}:${twoDigit(duration.minutes())}:${twoDigit(duration.seconds())}`
                        : `${twoDigit(duration.minutes())}:${twoDigit(duration.seconds())}`;
                return (
                    <Tooltip title={`Estimated duration: ${strDuration}`}>
                        <span key={`tda-${index}`}>
                            {moment(field).format('YYYY-MM-DD HH:mm:ss Z')}
                        </span>
                    </Tooltip>
                );
            },
        },
    ];

    return (
        <PageContainer title={'View Session'}>
            <ProCard>
                <Tabs activeKey={searchMode} onChange={switchSearchMode} items={tabItems} />
                {searchMode === 'search-advance' ? (
                    <ProForm
                        form={searchForm}
                        submitter={{
                            searchConfig: {
                                submitText: 'Query',
                                resetText: 'Reset',
                            },
                        }}
                        onFinish={(values) =>
                            _handleQueryChange({ page: 0, limit: traceFilter.limit })
                        }
                    >
                        <ProForm.Group>
                            <ProFormText width="md" name="appId" label="App ID" />
                            <ProFormText width="md" name="deviceId" label="Device ID" />
                            <ProFormSelect
                                name="platform"
                                label="Platform"
                                options={[
                                    { value: 'AOS', label: 'Android' },
                                    { value: 'IOS', label: 'iOS' },
                                ]}
                            />
                        </ProForm.Group>
                        <ProForm.Group>
                            <ProFormDateTimeRangePicker width="lg" name="date" label="Date time" />
                            <ProFormCheckbox name="validated" label="Validated" />
                        </ProForm.Group>
                    </ProForm>
                ) : (
                    <ProForm form={searchForm} submitter={false} style={{ margin: '1em' }}>
                        <Title level={5}>Input your trace id:</Title>
                        <Input.Search
                            size="large"
                            placeholder="Trace Id"
                            enterButton="Search"
                            onSearch={(traceId) => {
                                navigate(`/analysis/session/${traceId}`);
                            }}
                            loading={isLoading}
                        />
                    </ProForm>
                )}
            </ProCard>
            {searchMode === 'search-advance' && isSuperAdmin() ? (
                <ProCard style={{ marginTop: '1em' }} ghost={isMobile}>
                    <Table
                        loading={isLoading}
                        rowKey="traceId"
                        columns={columns}
                        dataSource={sessionList}
                        scroll={{ x: true }}
                        pagination={{
                            defaultCurrent: (traceFilter?.page ?? 0) + 1,
                            defaultPageSize: 20,
                            total: pageConfig?.total ?? 1,
                            current: (traceFilter?.page ?? 0) + 1,
                            showTotal: (total) => `Total ${total} items`,
                            onChange: (page, size) => {
                                _handleQueryChange({ limit: size, page: page - 1 });
                            },
                        }}
                        size="small"
                    />
                </ProCard>
            ) : undefined}
        </PageContainer>
    );
};

export default TraceSearchScreen;
