import React from 'react';
import { Timeline } from 'antd';
import { BasicListTier } from './BasicTier';
import moment from 'moment';
import { ProCard } from '@ant-design/pro-components';
import { useTraceContext } from 'providers/TraceProvider';

export const TraceTimelineSection: React.FC<any> = (props) => {
    const traceDetail = useTraceContext().traceDetail!
    return (
        <ProCard title="Timeline" headerBordered>
            <Timeline
                items={(traceDetail.history ?? []).map((el) => {
                    return {
                        color: props?.ts >= el.ts ? 'blue' : 'grey',
                        children: (
                            <BasicListTier
                                title={moment(el.ts)
                                    .tz(traceDetail.detail.timeZone)
                                    .format('MMMM Do YYYY, h:mm:ss a')}
                                description={el.msg ?? ''}
                                markdown={true}
                            />
                        ),
                    };
                })}
            />
        </ProCard>
    );
};
