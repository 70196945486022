import { FeatureCollection, Feature, GeoJsonProperties, Geometry } from 'geojson';
import { GeoJSONSourceRaw } from 'mapbox-gl';

export const LayerName = {
    accuracy: 'accuracy-c-layer',
};
export const SourceName = {
    accuracy: 'accuracy-c-source',
};

export function buildPolyline(data: number[][], properties?: GeoJsonProperties): Feature {
    const routeJson: Feature = {
        type: 'Feature',
        geometry: { type: 'LineString', coordinates: data },
        properties: properties ?? {},
    };
    return routeJson;
}

export function getBlueDotAccuracyLayer(): mapboxgl.FillLayer {
    const fillLayer: mapboxgl.FillLayer = {
        id: LayerName.accuracy,
        type: 'fill',
        source: SourceName.accuracy,
        paint: {
            'fill-color': '#18CAD6',
            'fill-opacity': 0.2,
        },
    };
    return fillLayer;
}

export function getGeoJson(list: any) {
    const features: FeatureCollection = {
        type: 'FeatureCollection',
        features: list
    };
    const sourceJson: GeoJSONSourceRaw = {
        type: 'geojson',
        data: features as FeatureCollection
    };

    return sourceJson;
}
