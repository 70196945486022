import {
    ModalForm,
    ProFormText,
    ProFormSwitch,
    ProFormDatePicker,
    ProFormGroup,
    ProFormList,
} from '@ant-design/pro-components';
import { Descriptions, Form, Typography } from 'antd';
import { useState } from 'react';

import {
    LicenseConfigDto,
    updateLicenseConfigDto,
    createLicense,
    updateLicense,
} from 'apis/ProjectApi';

import { BaseDialogProps } from './BaseDialogProps.type';
const { Text, Paragraph } = Typography;

export type AddProjLicenseProp = BaseDialogProps & {
    projectId: string;
    isEdit: boolean;
    editingLicense: any;
};

export const AddProjLicenseModule: React.FC<AddProjLicenseProp> = (props) => {
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [notFoundErr, setNotFoundErr] = useState<boolean>(false);

    const [licenseForm] = Form.useForm();
    const isEdit = props.isEdit;
    let editingLicense = props.editingLicense;

    let licenseArray = [];
    if (editingLicense.whitelist) {
        for (
            let whitelistLoop = 0;
            whitelistLoop < editingLicense.whitelist.length;
            whitelistLoop++
        ) {
            let newObject = { value: editingLicense.whitelist[whitelistLoop] };
            licenseArray.push(newObject);
        }
    }

    return (
        <ModalForm
            form={licenseForm}
            title={isEdit ? 'Edit license ' : 'Add license'}
            trigger={props.trigger}
            initialValues={{}}
            submitter={{
                searchConfig: {
                    submitText: isEdit ? 'Update' : 'Confirm',
                    resetText: 'Cancel',
                },
            }}
            loading={formLoading}
            onOpenChange={(isOpen) => {
                if (isOpen) {
                    licenseForm.resetFields();
                }
            }}
            onFinish={async (values) => {
                setFormLoading(true);

                let finalExpiredAt = new Date(values.expiredAt);
                let finalWhiteList = [];
                for (
                    let whitelistLoop = 0;
                    whitelistLoop < values.whitelist.length;
                    whitelistLoop++
                ) {
                    finalWhiteList.push(values.whitelist[whitelistLoop].value);
                }

                let finalEnabled = values.enabled ? true : false;

                let result = false;
                if (!isEdit) {
                    const mData: LicenseConfigDto = {
                        expiredAt: finalExpiredAt,
                        enabled: finalEnabled,
                        whitelist: finalWhiteList,
                    };

                    try {
                        const resp = await createLicense(props.projectId, mData);
                        if (resp.response?.status === 200 || resp.response?.status === 201) {
                            if (props.onSuccess) props.onSuccess();
                            result = true;
                        }
                    } catch (ex: any) {}
                } else {
                    const mData: updateLicenseConfigDto = {
                        expiredAt: finalExpiredAt,
                        enabled: values.enabled,
                        whitelist: finalWhiteList,
                        accessKey: editingLicense.accessKey,
                    };
                    try {
                        const resp = await updateLicense(props.projectId, mData);
                        if (resp.response?.status === 200 || resp.response?.status === 201) {
                            if (props.onSuccess) props.onSuccess();
                            result = true;
                        }
                    } catch (ex: any) {}
                }

                setFormLoading(false);
                return result;
            }}
        >
            {isEdit ? (
                <Descriptions column={1} layout="vertical">
                    <Descriptions.Item label="Access key">
                        {editingLicense.accessKey}
                    </Descriptions.Item>
                    <Descriptions.Item label="Access secret">
                        {editingLicense.accessSecret}
                    </Descriptions.Item>
                </Descriptions>
            ) : undefined}

            <ProFormDatePicker
                colProps={{ xl: 8, md: 12 }}
                label="Expiry date"
                name="expiredAt"
                initialValue={editingLicense.expiredAt}
                rules={[{ required: true }]}
                width="lg"
            />

            <ProFormList
                name="whitelist"
                label="Whitelist"
                initialValue={!isEdit ? [{ value: '*' }] : licenseArray}
                copyIconProps={{ tooltipText: 'Duplicate record' }}
                deleteIconProps={{ tooltipText: 'Remove record' }}
                creatorButtonProps={{ creatorButtonText: 'Add license' }}
            >
                <ProFormText name="value" label="App package name" width="lg" />
            </ProFormList>

            <ProFormSwitch
                name="enabled"
                colProps={{ span: 4 }}
                fieldProps={{ onChange: undefined }}
                initialValue={editingLicense.enabled}
                label="Enabled"
            />
        </ModalForm>
    );
};
