import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import * as turf from '@turf/turf';
import { MapContainer, Marker, Polygon, GeoJSON } from 'react-leaflet';
import L from 'leaflet';
import ImageOverlayRotated from 'components/maps/ImageOverlayRotated';
import { LatLngDto } from 'apis/VenueApi';
import { Direction } from './PositionOffsetPanel';
import MapTileControl from './MapTileControl';
import { ImgSizeDto, PolygonUtil } from 'utils/PolygonUtil';

export interface AlignResult {
    center: LatLngDto;
    angle: number;
    scale: number;
    corners: [number, number][];
    sizeInMeters: { width: number; height: number };
    size: ImgSizeDto;
}
interface MapAlignDto {
    angle: number;
    scale: number;
    opacity?: number;
    stroke?: boolean;
    showBound?: boolean;
    grayscale?: boolean;
}

interface IPreciseResult {
    center: LatLngDto;
    bearing: number;
    scale: number;
    corners: [number, number][];
}

export type AlignmentMapViewRef = {
    offset: (direction: Direction, meter: number) => void;
    calculateFromCorners: (polygon: turf.Feature<turf.Polygon>, rerender: boolean) => IPreciseResult;
    updateConfig: (options: Partial<MapAlignDto>) => void;
};

type AlignmentMapViewProps = {
    ref?: AlignmentMapViewRef;
    editable?: boolean;
    initCenter: LatLngDto;
    imageUrl: string;
    onChange?: (result: AlignResult, precise?: boolean) => void;
    geojsonData?: any;
    children?: string | JSX.Element | JSX.Element[];
};

const DirectionAngle = {
    N: 0,
    E: 90,
    S: 180,
    W: 270,
};

const EditorMoveIcon = L.icon({
    iconUrl:
        'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzQiIGhlaWdodD0iMzQiIHZpZXdCb3g9IjAgMCAzNCAzNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxjaXJjbGUgZmlsbD0iIzNBM0EzQSIgY3g9IjE3IiBjeT0iMTciIHI9IjE3Ii8+PHBhdGggZD0iTTE3Ljk3NTYwOTggMTYuMDI0MzkwMmg0Ljg3ODA0ODd2LTMuMTcwNzMxN0wyNyAxN2wtNC4xNDYzNDE1IDQuMTQ2MzQxNXYtMy4xNzA3MzE3aC00Ljg3ODA0ODd2NC44NzgwNDg3aDMuMTcwNzMxN0wxNyAyN2wtNC4xNDYzNDE1LTQuMTQ2MzQxNWgzLjE3MDczMTd2LTQuODc4MDQ4N2gtNC44NzgwNDg3djMuMTcwNzMxN0w3IDE3bDQuMTQ2MzQxNS00LjE0NjM0MTV2My4xNzA3MzE3aDQuODc4MDQ4N3YtNC44NzgwNDg3aC0zLjE3MDczMTdMMTcgN2w0LjE0NjM0MTUgNC4xNDYzNDE1aC0zLjE3MDczMTd2NC44NzgwNDg3eiIgZmlsbD0iI0ZGRiIvPjwvZz48L3N2Zz4K',
    iconSize: [34, 34],
});
const AlignmentMapView = React.forwardRef<AlignmentMapViewRef, AlignmentMapViewProps>(
    (props, ref) => {
        const preciseResult = useRef<IPreciseResult | undefined>();
        const [polygon, setPolygon] = useState<any>();
        const [mapCenter, setMapCenter] = useState<LatLngDto | undefined>(props?.initCenter);
        const [imgSize, setImgSize] = useState<ImgSizeDto>();
        const [geoJsonObj, setGeoJsonObj] = useState<any | undefined>(undefined);
        const [isDragging, setIsDragging] = useState<boolean>(false);
        const [config, setConfig] = useState<Partial<MapAlignDto>>({});

        function _toLatLngDto(lngLatPair: turf.helpers.Position) {
            return { lat: lngLatPair[1], lng: lngLatPair[0] };
        }
        function _getImageSize(url: string): Promise<ImgSizeDto> {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = function () {
                    const imageSize = PolygonUtil.getRatio(img.width, img.height);
                    resolve(imageSize);
                };
                img.onerror = function () {
                    reject(new Error('Failed to load image.'));
                };
                img.src = url;
            });
        }
        function _calculateBaseBbox(
            mapCenter: LatLngDto,
            mapImgSize: ImgSizeDto,
            scaleFactor: number = 1,
        ) {
            // Define the center point
            const center = turf.point([mapCenter.lng, mapCenter.lat]);

            // Calculate the coordinates for the polygon (Assume 1 px = 0.1 meter)
            const halfWidth = (mapImgSize.width / 2) * 0.1 * scaleFactor;
            const halfHeight = (mapImgSize.height / 2) * 0.1 * scaleFactor;

            // Calculate center top, right, bottom and left as bounty box
            const boxTop = turf.destination(center, halfHeight, 0, { units: 'meters' });
            const boxRight = turf.destination(center, halfWidth, 90, { units: 'meters' });
            const boxBottom = turf.destination(center, halfHeight, 180, { units: 'meters' });
            const boxLeft = turf.destination(center, halfWidth, 270, { units: 'meters' });

            const bountyBox = turf.bbox(
                turf.featureCollection([boxTop, boxRight, boxBottom, boxLeft]),
            );
            const _basePolygon = turf.bboxPolygon(bountyBox);
            return _basePolygon;
        }

        function handleConfigChange(basePolygon: turf.Feature<turf.Polygon>) {
            if (!basePolygon?.bbox || Object.keys(config).length === 0) {
                return;
            }
            const centerPoint = turf.center(basePolygon);

            // Scale the bbox
            const scalePrecent = config?.scale ?? 100; // value from 0 to N; 100 = no resize
            const rotateAngle = config?.angle ?? 0;
            const cornerDistance = turf.distance(
                centerPoint,
                basePolygon.geometry.coordinates[0][3],
            );
            // Calculate the scaled distance based on the scale factor (%)
            const scaledDistance = cornerDistance * (scalePrecent / 100);
            const scalingRatio = scaledDistance / cornerDistance;

            try {
                const _centerDto = {
                    lat: centerPoint.geometry.coordinates[1],
                    lng: centerPoint.geometry.coordinates[0],
                };
                // Create a buffer around the bbox polygon
                const scaledBboxPolygon = _calculateBaseBbox(_centerDto, imgSize!, scalingRatio);

                // Rotate the polygon
                const rotatedPolygon = turf.transformRotate(scaledBboxPolygon, rotateAngle, {
                    pivot: centerPoint,
                });
                setPolygon(rotatedPolygon);

                if (!mapCenter) {
                    setMapCenter(_centerDto);
                }
                if (props.onChange) {
                    const mCorners = getCoordinates(rotatedPolygon);
                    const mWidth = turf.distance(
                        [mCorners[0][1], mCorners[0][0]],
                        [mCorners[1][1], mCorners[1][0]],
                        { units: 'meters' },
                    );
                    const mHeight = turf.distance(
                        [mCorners[1][1], mCorners[1][0]],
                        [mCorners[2][1], mCorners[2][0]],
                        { units: 'meters' },
                    );

                    props.onChange(
                        {
                            center: {
                                lat: centerPoint.geometry.coordinates[1],
                                lng: centerPoint.geometry.coordinates[0],
                            },
                            corners: mCorners,
                            angle: rotateAngle,
                            scale: scalePrecent,
                            size: imgSize ?? { width: 0, height: 0, angle: 0, aspectRatio: 0 },
                            sizeInMeters: { width: mWidth, height: mHeight },
                        },
                        false,
                    );
                }
            } catch (ex) {
                console.warn('scaleFactor too small: ', ex);
            }
        }

        function handlePreciseResult(mPreciseResult: IPreciseResult) {
            if (!!mPreciseResult && props.onChange) {
                const mCorners = mPreciseResult.corners;
                const mWidth = turf.distance(
                    [mCorners[0][1], mCorners[0][0]],
                    [mCorners[1][1], mCorners[1][0]],
                    { units: 'meters' },
                );
                const mHeight = turf.distance(
                    [mCorners[1][1], mCorners[1][0]],
                    [mCorners[2][1], mCorners[2][0]],
                    { units: 'meters' },
                );
                props.onChange(
                    {
                        center: mPreciseResult.center,
                        corners: mCorners,
                        angle: mPreciseResult.bearing,
                        scale: mPreciseResult.scale,
                        size: imgSize ?? { width: 0, height: 0, angle: 0, aspectRatio: 0 },
                        sizeInMeters: { width: mWidth, height: mHeight },
                    },
                    true,
                );
            }
            preciseResult.current = undefined;
        }

        useEffect(() => {
            // Load image, get image size
            if (props.imageUrl) {
                _getImageSize(props.imageUrl)
                    .then(setImgSize)
                    .catch((ex) => {
                        console.error('Cannot get image size');
                    });
            }
            if (props.geojsonData) {
                if (
                    typeof props.geojsonData === 'string' &&
                    props.geojsonData?.includes('Feature')
                ) {
                    try {
                        const obj = JSON.parse(props.geojsonData);
                        setGeoJsonObj(obj);
                    } catch (ex) {
                        /* do nothing */
                    }
                } else if (Array.isArray(props.geojsonData)) {
                    setGeoJsonObj({
                        type: 'FeatureCollection',
                        features: [
                            {
                                type: 'Feature',
                                geometry: {
                                    coordinates: [props.geojsonData],
                                    type: 'Polygon',
                                },
                            },
                        ],
                    });
                }
            }
        }, [props.imageUrl, props.geojsonData]);

        useEffect(() => {
            if (!!imgSize) {
                if (!!preciseResult.current) {
                    handlePreciseResult(preciseResult.current);
                } else {
                    const venueCenter: LatLngDto = mapCenter ??
                        props.initCenter ?? { lat: 0, lng: 0 };
                    // Calculate base bbox
                    handleConfigChange(_calculateBaseBbox(venueCenter, imgSize));
                }
            }
        }, [config?.angle, config?.scale, imgSize, mapCenter]);

        function getCoordinates(inputPolygon?: any): [number, number][] {
            /**
             * 3---2       0---1
             * | X |  ===> |   |
             * 0---1       3---2
             * return [lat, lng]
             */
            const coor = (inputPolygon ?? polygon).geometry.coordinates[0];
            return [
                [coor[3][1], coor[3][0]],
                [coor[2][1], coor[2][0]],
                [coor[1][1], coor[1][0]],
                [coor[0][1], coor[0][0]],
                [coor[3][1], coor[3][0]],
            ];
        }

        function cornersToPolygon(corners: [number, number][]): turf.Feature<turf.Polygon> {
            /**
             * corners to turf polygon
             */
            return turf.polygon([
                [
                    [corners[3][1], corners[3][0]], // bottom left (lng, lat)
                    [corners[2][1], corners[2][0]],
                    [corners[1][1], corners[1][0]],
                    [corners[0][1], corners[0][0]],
                    [corners[3][1], corners[3][0]],
                ],
            ]);
        }

        useImperativeHandle(ref, () => ({
            calculateFromCorners(
                inputPolygon: turf.Feature<turf.Polygon>,
                rerender: boolean = false,
            ) {
                if (!inputPolygon || inputPolygon.geometry.coordinates.length != 1) {
                    throw new Error('positions array less then 4 item');
                }

                const inputCenter = turf.center(inputPolygon);

                const centerDto: LatLngDto = {
                    lat: inputCenter.geometry.coordinates[1],
                    lng: inputCenter.geometry.coordinates[0],
                };
                const _basePolygon = _calculateBaseBbox(centerDto, imgSize!);

                // Calculate the angle between the centroids
                // Define the top right coordinates of the points
                const pointA = turf.point(inputPolygon.geometry.coordinates[0][0]);
                const pointB = turf.point(_basePolygon.geometry.coordinates[0][3]);
                // Calculate the vectors of OA and convert into angle (offset center top as 0 deg)
                const bearing = turf.bearing(inputCenter, pointA) + imgSize!.angle / 2;
                const angle = turf.bearingToAzimuth(bearing);

                // Calculate the scale factor by dividing the distance of OA and OB:
                const distanceOA = turf.distance(inputCenter, pointA);
                const distanceOB = turf.distance(inputCenter, pointB);

                // Calculate the scale factor based on the buffer distance
                // 100% = no resize;
                const scaleOffset = distanceOA - distanceOB;
                const scale = 100 * (1 + scaleOffset / distanceOB);

                const mCorners = inputPolygon.geometry.coordinates[0];
                const shiftedCorners: [number, number][] = [
                    [mCorners[0][1], mCorners[0][0]], // top left
                    [mCorners[1][1], mCorners[1][0]],
                    [mCorners[2][1], mCorners[2][0]],
                    [mCorners[3][1], mCorners[3][0]],
                    [mCorners[0][1], mCorners[0][0]],
                ]
                const mResult: IPreciseResult = {
                    center: centerDto,
                    bearing: angle,
                    scale,
                    corners: shiftedCorners,
                };

                if (rerender) {
                    preciseResult.current = mResult;
                    setMapCenter(centerDto);
                    setPolygon(cornersToPolygon(shiftedCorners));
                }
                console.log('Calculated result: ', mResult);
                return mResult;
            },
            offset(direction, meter) {
                if (!mapCenter) return;
                const directionDeg = DirectionAngle[direction];
                const point = turf.point([mapCenter.lng, mapCenter.lat]);
                const translatedPoint = turf.transformTranslate(point, meter, directionDeg, {
                    units: 'meters',
                });
                setMapCenter({
                    lat: translatedPoint.geometry.coordinates[1],
                    lng: translatedPoint.geometry.coordinates[0],
                });
            },
            updateConfig(optinos) {
                if (!optinos) return;
                setConfig(optinos);
            },
        }));

        const markerRef = useRef(null);
        const eventHandlers = useMemo(
            () => ({
                mousedown() {
                    setIsDragging(true);
                },
                mousemove() {
                    const marker = markerRef.current as any;
                    if (marker != null && isDragging) {
                        setMapCenter(marker.getLatLng());
                    }
                },
                mouseup() {
                    setIsDragging(false);
                },
            }),
            [isDragging],
        );

        if (!polygon || !mapCenter) {
            return null;
        }
        const mPositions = getCoordinates() as any;
        return (
            <MapContainer
                center={props.initCenter}
                zoom={18}
                scrollWheelZoom={true}
                zoomControl={false}
                style={{ height: '100%', flex: 1 }}
            >
                <MapTileControl ruler={false} />
                {config?.showBound && geoJsonObj ? (
                    <GeoJSON
                        key={`geo-json-layer`}
                        data={geoJsonObj}
                        style={{ color: 'purple' }}
                        interactive={false}
                    />
                ) : undefined}
                <Polygon
                    positions={mPositions}
                    pathOptions={{
                        fillOpacity: 0,
                        stroke: props.editable && config?.stroke !== false,
                        lineCap: 'round',
                    }}
                    interactive={false}
                />
                <ImageOverlayRotated
                    imgSrc={props.imageUrl}
                    positions={mPositions}
                    opacity={config?.opacity ?? 1}
                    grayscale={config?.grayscale}
                />
                {props.editable ? (
                    <Marker
                        draggable={true}
                        eventHandlers={eventHandlers}
                        position={mapCenter}
                        ref={markerRef}
                        icon={EditorMoveIcon}
                    />
                ) : undefined}
                {props.children}
            </MapContainer>
        );
    },
);

export default AlignmentMapView;
