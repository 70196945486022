import { PageContainer, ProList } from '@ant-design/pro-components';
import React, { useEffect, useRef, useState } from 'react';
import { Space, Tag, Button, Badge, message } from 'antd';
import { PlusOutlined, SafetyCertificateFilled } from '@ant-design/icons';
import { IUserInfo, getAllUsers } from 'apis/UserApi';
import AliasAvatar from 'components/AliasAvatar';
import { useNavigate } from 'react-router-dom';
import { ResetPasswordModule } from 'components/dialogs/ResetPasswordDialog';
import UserAccessDrawer, { UserAccessDrawerRef } from 'components/dialogs/UserAccessDrawer';

const UserListScreen: React.FC = () => {
    const [users, setUsers] = useState<IUserInfo[]>();
    const [filterRole, setFilterRole] = useState<React.Key | undefined>('ALL');
    const [filterKeyword, setFilterKeyword] = useState<string | undefined>();
    const userAccessDrawerRef = useRef<UserAccessDrawerRef>();
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        _refreshUserList();
    }, []);

    function _refreshUserList() {
        getAllUsers()
            .then((resp) => {
                setUsers(resp.data ?? []);
            })
            .catch(() => {
                setUsers([]);
            });
    }
    const navigate = useNavigate();

    const _toggleEditDrawer = (record: IUserInfo) => {
        if (!!userAccessDrawerRef.current) {
            userAccessDrawerRef.current?.open(record);
        }
    };

    return (
        <PageContainer
            title="IAM"
            subTitle="Identity access management"
            breadcrumb={{
                items: [{ title: 'Access' }, { title: 'IAM' }],
            }}
            extra={[
                <Button
                    key="1"
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => {
                        navigate('/auth/user/create', {
                            state: {
                                createUserRedirect: '/auth/users',
                            },
                        });
                    }}
                >
                    Create user
                </Button>,
            ]}
        >
            {contextHolder}
            <ProList<IUserInfo>
                search={false}
                rowKey="id"
                headerTitle="All users"
                rowClassName="cursor-pointer"
                pagination={false}
                showActions="hover"
                onItem={(record, index) => {
                    return { onClick: (event) => _toggleEditDrawer(record) };
                }}
                metas={{
                    title: {
                        dataIndex: 'name',
                        title: 'Name',
                        search: true,
                    },
                    avatar: {
                        dataIndex: 'name',
                        search: false,
                        render: (_, row) =>
                            row.role === 'SUPER_ADMIN' ? (
                                <Badge
                                    count={
                                        <SafetyCertificateFilled
                                            style={{ color: '#FFC107', fontSize: '1.5em' }}
                                        />
                                    }
                                >
                                    <AliasAvatar name={row.name} shape="square" />
                                </Badge>
                            ) : (
                                <AliasAvatar name={row.name} shape="square" />
                            ),
                    },
                    description: {
                        dataIndex: 'email',
                        title: 'Email',
                        search: true,
                    },
                    subTitle: {
                        dataIndex: 'role',
                        title: 'Role',
                        render: (role, row, index) => {
                            return (
                                <Space size={0}>
                                    <Tag key={`${index}-proj-c`}>
                                        {row.resources.length} projects
                                    </Tag>
                                </Space>
                            );
                        },
                        search: false,
                    },
                    actions: {
                        render: (text, row, index) => [
                            <Button key="btn-edit" onClick={() => _toggleEditDrawer(row)}>
                                Edit
                            </Button>,
                            <ResetPasswordModule
                                key="btn-reset-password"
                                userId={row.id}
                                trigger={<Button>Reset password</Button>}
                                onSuccess={() => {
                                    messageApi.success('Password updated');
                                }}
                            />,
                        ],
                        search: false,
                    },
                }}
                dataSource={(users ?? []).filter((el) => {
                    if (filterRole != 'ALL' && el.role !== filterRole) {
                        return false;
                    }
                    if (
                        !!filterKeyword &&
                        !(
                            el.name.toLowerCase().includes(filterKeyword) ||
                            el.email.toLowerCase().includes(filterKeyword)
                        )
                    ) {
                        return false;
                    }
                    return true;
                })}
                toolbar={{
                    menu: {
                        activeKey: filterRole,
                        items: [
                            {
                                key: 'ALL',
                                label: 'All',
                            },
                            {
                                key: 'USER',
                                label: 'User',
                            },
                            {
                                key: 'SUPER_ADMIN',
                                label: 'Super admin',
                            },
                        ],
                        onChange(key) {
                            setFilterRole(key);
                        },
                    },
                    search: {
                        onSearch: (value: string) => {
                            setFilterKeyword(value.toLowerCase());
                        },
                    },
                }}
            />
            <UserAccessDrawer
                ref={userAccessDrawerRef as any}
                onClosed={(updated?: boolean) => {
                    if (updated) {
                        _refreshUserList();
                        messageApi.success('User updated');
                    }
                }}
            />
        </PageContainer>
    );
};

export default UserListScreen;
