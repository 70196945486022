import ApiClient from 'utils/NetworkManager';
import { ResourceRole } from './UserApi';
import { VenueInfoDto } from './VenueApi';

export interface ProjectInfoDto {
    id: string;
    name: string;
    description: string;
    createdAt: string;
}

export interface ProjectEditDto {
    name: string;
    description: string;
}

export interface cloneProjectDto {
    name: string;
    description: string;
    venueIds : string[],
}

export interface ProjectDetailDto extends ProjectInfoDto {
    licenses: LicenseDto[];
    venues: VenueInfoDto[];
}

export interface getProjectStatisticDto {
    from : Date;
    to: Date;
}

export interface LicenseConfigDto {
    expiredAt: Date;
    enabled: boolean;
    whitelist: string[];
}

export interface updateLicenseConfigDto {
    expiredAt: Date;
    enabled: boolean;
    whitelist: string[];
    accessKey: string;
}

export interface LicenseDto extends LicenseConfigDto {
    id: string;
    accessKey: string;
    accessSecret: string;
}

export interface ProjectUserDto {
    userId: string;
    name: string;
    email: string;
    role: ResourceRole;
}

export interface StatisticResultDto {
    result: { platform: string; date: string; count: number }[];
    devices: { platform: string; count: number }[];
}

export const getProjectList = () => ApiClient.get<ProjectInfoDto[]>(`/project/list`);
export const createProject = (data: ProjectEditDto) => ApiClient.post<ProjectDetailDto>(`/project`, data);
export const getProject = (id: string) => ApiClient.get<ProjectDetailDto>(`/project/${id}`);
export const deleteProject = (id: string) => ApiClient.delete(`/project/${id}`);
export const getProjectUsers = (id: string) => ApiClient.get<ProjectUserDto[]>(`/project/${id}/users`);
export const getProjectStatistic = (id: string, data: getProjectStatisticDto) => ApiClient.get<StatisticResultDto>(`/project/${id}/statistic`, data);
export const updateProject = (id: string, data: ProjectEditDto) => ApiClient.put(`/project/${id}`, data);
export const cloneProject = (id: string, data: cloneProjectDto) => ApiClient.post<cloneProjectDto>(`/project/${id}/clone`, data);
export const createLicense = (id: string, data: LicenseConfigDto) => ApiClient.post<ProjectDetailDto>(`/project/${id}/license`, data);
export const updateLicense = (id: string, data: updateLicenseConfigDto) => ApiClient.put(`/project/${id}/license`, data);