import { message } from 'antd';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { getAuth } from 'firebase/auth';

const _showNetworkLog = false;

const SEND_REQUEST_POST = 'SEND_REQUEST_POST';
const SEND_REQUEST_GET = 'SEND_REQUEST_GET';
const SEND_REQUEST_PUT = 'SEND_REQUEST_PUT';
const SEND_REQUEST_DELETE = 'SEND_REQUEST_DELETE';

export interface RequestConfig extends AxiosRequestConfig<any> {
    preventDefaultHeaders?: boolean;
    handleException?: boolean;
}

export interface INetworkResponse<T> {
    message?: string;
    error?: any | AxiosError;
    response?: AxiosResponse;
    data?: T;
}

class NetworkManager {
    http: AxiosInstance;
    constructor() {
        const BASE_URL = process.env.REACT_APP_API_BASE_URL;
        this.http = axios.create({
            // withCredentials: true,
            timeout: 45 * 1000,
            baseURL: BASE_URL,
        });

        this.http.interceptors.request.use(async (config) => {
            // Do not add any default header for this request if preventDefaultHeaders=true
            if ((config as any)?.preventDefaultHeaders === true) return config;

            const mUser = getAuth().currentUser;
            const mAccessToken = mUser ? await mUser.getIdToken() : null;
            if (!!mAccessToken)
                config.headers.Authorization = mAccessToken ? `Bearer ${mAccessToken}` : '';

            return config;
        });

        this.http.interceptors.response.use(
            async (response) => {
                if (_showNetworkLog)
                    console.log('[NetworkManager - ResponseInterceptor]', response);
                return response;
            },
            async (error) => {
                try {
                    // Request failed, e.g. HTTP code 500

                    const { httpMetric } = error.config.metadata;

                    // add any extra metric attributes if needed
                    // httpMetric.putAttribute('userId', '12345678');

                    httpMetric.setHttpResponseCode(error.response.status);
                    httpMetric.setResponseContentType(error.response.headers['content-type']);
                    await httpMetric.stop();
                    return Promise.reject(error);
                } catch {
                    // Ensure failed requests throw after interception
                    return Promise.reject(error);
                }
            },
        );
    }

    get<T>(url: string, data?: any, config?: RequestConfig): Promise<INetworkResponse<T>> {
        return this.sendRequest<T>(SEND_REQUEST_GET, url, data, config);
    }
    post<T>(url: string, data?: any, config?: RequestConfig): Promise<INetworkResponse<T>> {
        return this.sendRequest<T>(SEND_REQUEST_POST, url, data, config);
    }
    put<T>(url: string, data?: any, config?: RequestConfig): Promise<INetworkResponse<T>> {
        return this.sendRequest<T>(SEND_REQUEST_PUT, url, data, config);
    }
    delete<T>(url: string, data?: any, config?: RequestConfig): Promise<INetworkResponse<T>> {
        return this.sendRequest<T>(SEND_REQUEST_DELETE, url, data, config);
    }
    putFile<T>(url: string, data: File, config?: RequestConfig): Promise<INetworkResponse<T>> {
        const mFormData = new FormData();
        mFormData.append('file', data);
        return this.sendRequest<T>(SEND_REQUEST_PUT, url, data, {
            preventDefaultHeaders: true, 
            url,
            data: mFormData,
            ...config,
        });
    }

    sendRequest<T>(
        method: string,
        url: string,
        data: any,
        config?: RequestConfig,
    ): Promise<INetworkResponse<T>> {
        if (_showNetworkLog) console.log('sendRequest', method, url, data);
        return new Promise<INetworkResponse<T>>(async (resolve, reject) => {
            let result: AxiosResponse | undefined;
            try {
                switch (method) {
                    case SEND_REQUEST_GET:
                        result = await this.http.get(url, { ...config, params: data });
                        break;
                    case SEND_REQUEST_POST:
                        result = await this.http.post(url, data, config);
                        break;
                    case SEND_REQUEST_PUT:
                        result = await this.http.put(url, data, config);
                        break;
                    case SEND_REQUEST_DELETE:
                        result = await this.http.delete(url, { ...config, params: data });
                        break;
                }
                const mData = result?.data?.data;
                resolve({ message: undefined, error: undefined, response: result, data: mData });
            } catch (error: any | AxiosError) {
                const errorResp = this.handleError(error);
                if (config?.handleException === undefined || config?.handleException === true) {
                    this.__showErrorToast(errorResp);
                }
                reject(errorResp);
            } finally {
            }
        });
    }

    handleError(error: AxiosError | any): INetworkResponse<any> {
        const result = { message: 'unknown', error, response: error?.response };

        if (axios.isAxiosError(error)) {
            let description = error.message ?? error;
            let response;

            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                response = error.response;
                description = `Server Connection Issue: ${error.response.status})`;
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                // Likely connection issue either on device or server
                description = 'Likely connection issue either on device or server';
            } else {
                // Something happened in setting up the request that triggered an Error
                description = 'Invalid request';
            }
            result.message = description;
        }
        return result;
    }

    private __showErrorToast(error: INetworkResponse<any>) {
        let errMsg = '';
        if (process.env.NODE_ENV === 'development') {
            errMsg = `\n${error.response?.data?.message} (${error.response?.data?.code ?? ''})`;
        }
        message.error('Something went wrong.' + errMsg);
    }
}

export default new NetworkManager();
