import { ModalForm, ProFormSelect, ProFormText } from '@ant-design/pro-components';
import { Form, Typography } from 'antd';
import { useState } from 'react';
import { ResourceEntryDto, ResourceRole, addResourceByEmail } from 'apis/UserApi';
import { BaseDialogProps } from './BaseDialogProps.type';
import { BasicListTier } from '../BasicTier';
import { Link } from 'react-router-dom';
import { accessRoleOptions } from './UserAccessDrawer';
const { Text } = Typography;

export type AddProjUserProp = BaseDialogProps & {
    projectId: string;
};

export const AddProjUserModule: React.FC<AddProjUserProp> = (props) => {
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [notFoundErr, setNotFoundErr] = useState<boolean>(false);
    const [addUserForm] = Form.useForm();
    return (
        <ModalForm
            form={addUserForm}
            title="Add user"
            trigger={props.trigger}
            initialValues={{
                role: 'EDITOR',
            }}
            submitter={{
                searchConfig: {
                    submitText: 'Confirm',
                    resetText: 'Cancel',
                },
            }}
            loading={formLoading}
            onOpenChange={() => {
                addUserForm.resetFields();
            }}
            onFinish={async (values) => {
                setFormLoading(true);
                const mData: ResourceEntryDto = {
                    projId: props.projectId,
                    role: values.role,
                };
                try {
                    const resp = await addResourceByEmail(values.email, mData);
                    if (resp.response?.status === 200) {
                        if (props.onSuccess) props.onSuccess();
                        return true;
                    }
                } catch (ex: any) {
                    if (ex?.response?.status === 404 && ex?.response?.data?.code === 5043) {
                        // User not exist
                        setNotFoundErr(true);
                    }
                    /* do nothing */
                }
                setFormLoading(false);
                return false;
            }}
        >
            <ProFormText
                name="email"
                label="Email address"
                placeholder="Enter email address"
                rules={[
                    { required: true, message: 'Please enter email address!' },
                    {
                        pattern: RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$'),
                        message: 'Invalid email address!',
                    },
                ]}
            />

            <ProFormSelect
                style={{ height: 'auto' }}
                name="role"
                label="Access role"
                options={accessRoleOptions}
                fieldProps={{
                    optionItemRender(item: any) {
                        return (
                            <BasicListTier
                                style={{ padding: '0.5em 0' }}
                                title={item.label}
                                description={item.desc}
                            />
                        );
                    },
                }}
                rules={[{ required: true, message: 'Please select a role!' }]}
            />
            {notFoundErr ? (
                <Text type="danger">
                    User does not exist. Click{' '}
                    <Link
                        to="/auth/user/create"
                        state={{
                            inputEmail: addUserForm.getFieldValue('email'),
                            redirectPath: '/project/permission',
                        }}
                    >
                        here
                    </Link>{' '}
                    to create a user first.
                </Text>
            ) : undefined}
        </ModalForm>
    );
};
