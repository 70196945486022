import { useEffect, useState, useRef } from 'react';
import { PlayerControl } from './PlayerControl';
import { VenuePreviewDto } from 'apis/TraceApi';
import moment from 'moment';
import { ClockCircleOutlined, ImportOutlined, CloseOutlined } from '@ant-design/icons';
import { Alert, Flex, Space, Tag, Tooltip, UploadProps, Upload, Button, Typography } from 'antd';
import { PlayerTooltips } from 'components/PlayerControl';
import { ProCard, ProForm, ProFormText, ProFormSwitch, ProFormDigit } from '@ant-design/pro-components';
import { PlaybackMapView } from 'components/maps/PlaybackMapView';
import { useTraceContext } from 'providers/TraceProvider';
import { VideoRecordControl } from './maps/VideoRecordControl';
import { VideoRecordView } from './maps/VideoRecordView';
import CustomIcon from './CustomIcon';

type TracePlaybackSectionProps = {
    onProgressChange?: (index: number, ts: number) => void;
};

export const TracePlaybackSection: React.FC<TracePlaybackSectionProps> = ({ onProgressChange }) => {
    let reqId = '';
    const [playIndex, setPlayIndex] = useState<number>(0);
    const [routeIndex, setRouteIndex] = useState<number>(0);
    const [floorInfo, setFloorInfo] = useState<VenuePreviewDto>();
    const traceDetail = useTraceContext().traceDetail!;
    const [isBlueDotPlaying , setIsBlueDotPlaying] = useState<boolean>(false);
    const [latestMs, setLatestMs] = useState<number>(0);


    const [videoRecord, setVideoRecord] = useState<any>();
    const [videoRecordDuration, setVideoRecordDuration] = useState<number>(0);
    const [videoRecordTime, setVideoRecordTime] = useState<number>(0);
    const [videoRecordPlaySpeed, setVideoRecordPlaySpeed] = useState<number>(1);
    const [isSynchronizePlaying , setIsSynchronizePlaying] = useState<boolean>(false);
    const [videoRecordDelay , setVideoRecordDelay] = useState<number>(0);

    const handleChangeVideoRecord: UploadProps['onChange'] = (info) => {
        if(info.fileList[info.fileList.length - 1].originFileObj){
            setVideoRecord(info.fileList[info.fileList.length - 1].originFileObj);
        }
    };

    const updateVideoPlayingTime = (time : number, isEffectByBlueDot : boolean = false) => {
        setVideoRecordTime(time);

        if(isSynchronizePlaying && !isEffectByBlueDot){
            _handleProgressChange(0, videoRecordTime - videoRecordDelay ,true);
        }
    }
    
    const updateIsSynchronizePlaying = (isSync : boolean) => {
        setIsSynchronizePlaying(isSync);
        if(isSync){
            setVideoRecordDelay(videoRecordTime - latestMs);
        }
    }


    useEffect(() => {
        if (reqId !== traceDetail.traceId) {
            // Reset all index
            setPlayIndex(0);
            setRouteIndex(0);
        }
        reqId = traceDetail.traceId;
        return () => {
            reqId = '';
        };
    }, [traceDetail]);

    function findClosestValueIndex(array: number[], target: number) {
        let left = 0;
        let right = array.length - 1;
        let closestIndex = null;

        while (left <= right) {
            const mid = Math.floor((left + right) / 2);
            const current = array[mid];
            if (current === target) return mid;
            if (
                closestIndex === null ||
                Math.abs(current - target) < Math.abs(array[closestIndex] - target)
            ) {
                closestIndex = mid;
            }
            if (current < target) {
                left = mid + 1;
            } else {
                right = mid - 1;
            }
        }

        return closestIndex;
    }

    function _handleProgressChange(progress: number, ms: number, isEffectByVideo: boolean = false) {
        // if (ms < 0) return;

        // if (ms < 0){
        //     ms = 0;
        // }
        // else if (ms > traceDetail?.detail.duration){
        //     ms = traceDetail?.detail.duration;
        // }

        // find closest index
        const selectedTs = traceDetail.detail.startAt + ms;
        const target = traceDetail.route[routeIndex];
        const mIndex = findClosestValueIndex(target.ts, selectedTs) ?? -1;
        if (playIndex !== mIndex) {
            setPlayIndex(mIndex);
            const mFId = target.data[mIndex]?.fid;
            if (!!mFId && mFId !== floorInfo?.id) {
                const info = traceDetail.venue.find((el) => el.id === mFId);
                setFloorInfo(info);
            } else if (!mFId && !!floorInfo) {
                setFloorInfo(undefined);
            }
        }
        if (onProgressChange) onProgressChange(mIndex, selectedTs);

        setLatestMs(ms);

        if(!isEffectByVideo && isSynchronizePlaying){
            updateVideoPlayingTime(ms + videoRecordDelay , true);
        }

    }

    function _renderCurrentInfo() {
        const target = traceDetail.route[routeIndex];
        const targetData = target.data[playIndex];
        if (!targetData) return undefined;
        const currTs = traceDetail.detail.startAt + latestMs;
        return (
            <Flex gap="large" align="center" style={{ margin: '8px 0' }}>
                <Space style={{ width: '16em' }}>
                    <ClockCircleOutlined title="Time" />
                    {moment(currTs).tz(traceDetail.detail.timeZone).format()}
                </Space>
                <Space style={{ width: '6em' }}>
                    <Flex align="center">
                        <CustomIcon icon="altitude" size={20} title="Altitude" />
                    </Flex>
                    {targetData.alt ? `${targetData.alt.toFixed(3)}m` : '-'}
                </Space>
                <Space style={{ width: '6em' }}>
                    <Flex align="center">
                        <CustomIcon icon="accuracy" size={20} title="Altitude" />
                    </Flex>
                    {targetData.acc ? `${targetData.acc.toFixed(3)}m` : '-'}
                </Space>

                {floorInfo ? (
                    <Tooltip title={floorInfo.id}>
                        <Tag color="blue">
                            {floorInfo.venueName}: {floorInfo.name}
                        </Tag>
                    </Tooltip>
                ) : undefined}
                {target.isMock ? <Tag color="error">Mock Location</Tag> : undefined}
            </Flex>
        );
    }

    if (!traceDetail || (traceDetail.route[routeIndex]?.data ?? []).length == 0) {
        return <Alert message="No data for playback" type="warning" showIcon />;
    }

    return (


        <ProCard title="Playback" subTitle={<PlayerTooltips />} headerBordered
            extra={
                !videoRecord ?
                    <Upload
                        showUploadList={false}
                        beforeUpload={()=>{return false}}
                        fileList={[]}
                        onChange={handleChangeVideoRecord}
                    >
                        <Button
                            icon={<ImportOutlined />}
                        >Import video record</Button>
                    </Upload> 
                :
                <Button
                    icon={<CloseOutlined />}
                    onClick={()=>{
                        setVideoRecord(undefined)
                    }}
                >Remove video record</Button>
            }
        >

            <div style={{display:"flex"}}>
                <div style={{width: videoRecord ? "calc(100% - 250px)" : "100%"}}>
                    <PlaybackMapView
                        route={traceDetail.route[routeIndex]}
                        progressIndex={playIndex}
                        traceDetail={traceDetail}
                        duration={traceDetail?.detail.duration ?? 0}
                        overrideMs={latestMs}
                        style={{ width: '100%', height: '30em' }}
                    />
                </div>
                <div>
                {
                    !videoRecord ? <></> : <VideoRecordView
                        onUpdateVideoDuration={(duration)=>{
                            setVideoRecordDuration(duration);
                        }}
                        currentVideoTime={videoRecordTime}
                        videoRecord={videoRecord}
                        ms={latestMs}
                    />
                }
                </div>
            </div>
            
            {_renderCurrentInfo()}
            <PlayerControl
                title={videoRecord ? "Blue dot" : ""}
                style={{ margin: '1em 0px' }}
                duration={traceDetail?.detail.duration ?? 0}
                overrideMs={latestMs}
                onProgressChange={_handleProgressChange}
                onUpdateSpeed={setVideoRecordPlaySpeed}
                onUpdateIsPlaying={setIsBlueDotPlaying}
            />
            {
                !videoRecord ? <></> : <VideoRecordControl 
                    duration={videoRecordDuration} 
                    playSpeed={videoRecordPlaySpeed} 
                    videoRecord={videoRecord}
                    currentVideoTime={videoRecordTime}
                    isSynchronizePlaying={isSynchronizePlaying}
                    isBlueDotPlaying={isBlueDotPlaying}
                    onUpdateVideo={updateVideoPlayingTime} 
                    onUpdateIsSynchronizePlaying={updateIsSynchronizePlaying}
                />
            }
        </ProCard>
    );
};
