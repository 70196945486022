import { memo } from "react";
const IconNames = [
    'topCorner',
    'bevelCorner',
    'area',
    'size',
    'altitude',
    'accuracy',
    'ruler',
    'target',
    'wifi',
    'bluetooth',
    'compass',
    'layer',
    'stars',
    'select',
] as const;
type IconKey = (typeof IconNames)[number];
interface CustomIconProp {
    icon: IconKey | string;
    size?: number;
    title?: string;
    style?: React.CSSProperties;
}
const CustomIcon: React.FC<CustomIconProp> = (props) => {
    const iconIndex = IconNames.indexOf(props.icon as IconKey);
    const mSize = props.size ?? 24;

    return (
        <div
            style={{ display: 'inline-block', width: mSize, height: mSize, ...props.style }}
            title={props.title}
        >
            <span
                className="ic-group"
                style={{
                    backgroundSize: `auto ${mSize}px`,
                    width: `${mSize}px`,
                    height: `${mSize}px`,
                    backgroundPosition: `-${iconIndex * mSize}px 0`,
                }}
            />
        </div>
    );
};

export default memo(CustomIcon);
