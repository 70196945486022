import { ProCard } from '@ant-design/pro-components';
import React, { useEffect, useState, useRef } from 'react';
import {
    Button,
    message,
    Typography,
    Empty,
    Flex,
    Select,
    Input,
    Switch,
    Upload,
    Modal,
    Tag,
    Badge,
    Space,
    Tooltip,
} from 'antd';
import {
    PlusOutlined,
    UploadOutlined,
    RetweetOutlined,
    LeftOutlined,
    RightOutlined,
    ToolOutlined,
    DeleteOutlined,
    CloseOutlined,
} from '@ant-design/icons';
import { useVenueState } from 'providers/VenueProvider';
import {
    deleteBeaconPlan,
    activeBeaconPlan,
    BeaconDto,
    BeaconPlanCreateDto,
    createBeaconPlan,
    BeaconPlanUpdateDto,
    updateBeaconPlan,
} from 'apis/BeaconApi';
import { useAuth } from 'providers/AuthProvider';
import { Link } from 'react-router-dom';
import { ViewDocumentModule } from 'components/dialogs/DocumentDialog';
import LoadingOverlay from 'components/LoadingOverlay';
import FloorSelector from 'components/FloorSelector';
import { exportAsCsv, parseCsv } from 'services/BeaconPlanHelper';
import MapBoxContainer from 'components/maps/MapBoxContainer';
import MapBoxImageLayer from 'components/maps/MapBoxImageLayer';
import MapBoxMarkerLayer from 'components/maps/MapBoxMarkerLayer';
import BeaconTableSection, { BeaconTableRef } from 'components/BeaconTableSection';
import { useAppState } from 'providers/AppStateProvider';
import { ResourceRole } from 'apis/UserApi';
import MapBoxDrawLayer, { MapBoxDrawLayerRef } from 'components/maps/MapBoxDrawLayer';
import { Feature, inside, Polygon } from '@turf/turf';
import CustomIcon from 'components/CustomIcon';
const { Text } = Typography;

const VenueBeaconPlannerScreen: React.FC = () => {
    const { isSuperAdmin, hasAccess } = useAuth();
    const { activeProject } = useAppState();
    const {
        venue,
        workingMap,
        fetchVenue,
        fetchBeaconList,
        beaconPlanList,
        fetchBeaconPlan,
        beaconPlan,
        isLoading,
    } = useVenueState();
    const [messageApi, contextHolder] = message.useMessage();

    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [selectedPlanId, setSelectPlanId] = useState<string>();
    const autoFillConfRef = useRef({
        uuid: '',
        weight: 1,
        threshold: -75,
        isLowPower: false,
    });
    const [panelCollapsed, setPanelCollapsed] = useState<boolean>(false);

    const sourceIdRef = useRef<string>('');
    const [planName, setPlanName] = useState<string>();
    const [planStatus, setPlanStatus] = useState<number>(0); // 0: not changed, 1: changed, 2: active
    const [beaconData, setBeaconData] = useState<BeaconDto[]>([]);
    const [errorMsg, setErrorMsg] = useState<string | undefined>();

    const tableRef = useRef<BeaconTableRef>();
    const drawRef = useRef<any>();
    const selectorRef = useRef<MapBoxDrawLayerRef>();
    const [selectedArea, setSelectedArea] = useState<Feature<Polygon> | undefined | null>();
    const allowHover = beaconData.length < 1000;
    const isEditor = hasAccess(ResourceRole.EDITOR, activeProject?.projId);
    const initDataRef = useRef<string>('');
    const hasChangeRef = useRef<boolean>(false);

    // Handle when working venue or map changed
    useEffect(() => {
        resetAllState();
        fetchBeaconList();
    }, [venue, workingMap]);

    // Handle when working venue or map changed
    useEffect(() => {
        if (!!beaconPlanList && !selectedPlanId) {
            const activatedPlanItem = beaconPlanList.find((el) => el.isActive);
            if (activatedPlanItem != null) {
                fetchBeaconPlan(activatedPlanItem.id);
            }
        }
    }, [beaconPlanList]);

    const resetAllState = () => {
        setSelectPlanId(undefined);
        setPlanName(undefined);
        setPlanStatus(0);
        setBeaconData([]);
        setErrorMsg(undefined);
        if (drawRef.current?.resetAll) {
            drawRef.current.resetAll();
        }
        setSelectedArea(undefined);
        if (selectorRef.current) selectorRef.current.resetAll();
    };

    // Handle when beacon plan downloaded
    useEffect(() => {
        sourceIdRef.current = beaconPlan?.id ?? '';
        if (beaconPlan == undefined) {
            if (drawRef.current?.resetAll) drawRef.current.resetAll();
            return;
        }
        const mBeaconData = [...beaconPlan.beacons];
        setPlanName(beaconPlan.name);
        setSelectPlanId(beaconPlan.id);
        setPlanStatus(beaconPlan.isActive ? 2 : 1);
        setBeaconData(mBeaconData);
        setPanelCollapsed(false);
        setErrorMsg(undefined);
        setSelectedArea(undefined);
        if (selectorRef.current) selectorRef.current.resetAll();
        if (tableRef.current) tableRef.current.reset();

        setTimeout(() => {
            updateBeaconMap(mBeaconData);
        }, 200);
        initDataRef.current = JSON.stringify(mBeaconData);
        hasChangeRef.current = false;
    }, [beaconPlan]);

    useEffect(() => {
        if (drawRef.current) {
            drawRef.current.selectItems(selectedArea);
        }
    }, [selectedArea]);

    const updateBeaconMap = (data: BeaconDto[]) => {
        if (drawRef.current?.resetAll) {
            const entity = data
                .filter((el) => el.lat > 0 && el.lng > 0)
                .map((el) => {
                    return {
                        id: el.id,
                        label: el.major,
                        lat: el.lat,
                        lng: el.lng,
                    };
                });
            drawRef.current.resetAll(entity);
        }
    };

    /**
     * Listeners
     */

    const onCreateNewPlan = () => {
        const newPlanId = '<NEW>';
        sourceIdRef.current = new Date().getTime().toString();
        setSelectPlanId(newPlanId);
        setPlanName('New Beacon Plan');
        setPlanStatus(0);
        setBeaconData([]);
        fetchBeaconPlan(undefined);
        setErrorMsg(undefined);
        // Reset the map
        if (drawRef.current?.resetAll) {
            drawRef.current.resetAll();
        }
    };

    const handleOnFileChange = (file: any) => {
        if (file.status !== 'uploading') {
            const reader = new FileReader();
            const fileName = file.file.name;
            setIsSaving(true);
            reader.onload = (e) => {
                const csv = e.target?.result?.toString() as string;
                const result = parseCsv(fileName, csv);
                if (selectedPlanId === '<NEW>') setPlanName(result.name);
                sourceIdRef.current = new Date().getTime().toString();
                setPlanStatus(0);
                setBeaconData(result.beacons);
                updateBeaconMap(result.beacons);
                messageApi.success(`${fileName} uploaded successfully`);
                setIsSaving(false);
            };
            reader.readAsText(file.file as any);
        }
    };

    const onClickAddBeacon = () => {
        const autoConf = autoFillConfRef.current;
        const targetId = Math.round(new Date().getTime() + Math.random() * 1000000);
        setBeaconData((prev) => [
            ...prev,
            {
                id: targetId,
                uuid: autoConf.uuid ?? '',
                major: 0,
                minor: 0,
                weight: autoConf.weight ?? 1,
                threshold: autoConf.threshold ?? -75,
                txPower: undefined,
                isLowPower: autoConf.isLowPower ?? false,
                isDoorway: false,
                lat: 0,
                lng: 0,
            },
        ]);

        setTimeout(() => {
            if (tableRef.current) tableRef.current.scrollToId(targetId);
        }, 100);
    };

    const onClickSave = async () => {
        if (!workingMap) return;
        const { isValid, tableData } = getAndValidate();
        if (!isValid) return;

        setIsSaving(true);
        try {
            if (selectedPlanId == '<NEW>') {
                const mData: BeaconPlanCreateDto = {
                    mapId: workingMap.id,
                    name: planName!,
                    beacons: tableData as BeaconDto[],
                };
                const resp = await createBeaconPlan(mData);
                messageApi.info('Beacon plan created');
                const planId = resp.data?.id;
                if (planId) {
                    fetchBeaconList();
                    fetchBeaconPlan(planId);
                }
            } else if (selectedPlanId !== undefined) {
                const mData: BeaconPlanUpdateDto = {
                    id: selectedPlanId,
                    mapId: workingMap.id,
                    name: planName!,
                    beacons: tableData as BeaconDto[],
                };
                await updateBeaconPlan(mData);
                messageApi.success('Beacon plan updated');
                fetchBeaconPlan(selectedPlanId);
            }
            fetchVenue();
        } catch (ex) {
            messageApi.error('Failed to submit beacon plan.');
        } finally {
            setIsSaving(false);
        }
    };

    const onClickDeletePlan = () => {
        const _deletePlan = () => {
            if (!selectedPlanId) return;
            setIsSaving(true);
            deleteBeaconPlan(selectedPlanId)
                .then((resp) => {
                    fetchBeaconList();
                    setSelectPlanId(undefined);
                    setBeaconData([]);
                    messageApi.info('Beacon plan deleted');
                })
                .finally(() => {
                    setIsSaving(false);
                });
        };

        Modal.warning({
            title: 'Delete Beacon Plan',
            content: `Are you sure to delete ${planName} ?`,
            okCancel: true,
            okButtonProps: { danger: true },
            onOk: _deletePlan,
        });
    };

    const onToggleActivePlan = (flag: boolean) => {
        if (!selectedPlanId) return;

        const _activePlan = () => {
            if (!workingMap || !selectedPlanId) return;
            setIsSaving(true);
            activeBeaconPlan(workingMap.venueId, {
                mapId: workingMap.id,
                planId: flag ? selectedPlanId : null,
            })
                .then((resp) => {
                    fetchBeaconList();
                    fetchBeaconPlan(selectedPlanId);
                    fetchVenue();
                    messageApi.info('Beacon plan activated');
                })
                .finally(() => {
                    setIsSaving(false);
                });
        };

        Modal.info({
            title: flag ? `Activate beacon plan` : 'Deactivate beacon plan',
            content: `Are you sure to ${flag ? 'activate' : 'deactivate'} '${planName}' Beacon plan?`,
            okCancel: true,
            onOk: _activePlan,
        });
    };

    const onClickExport = () => {
        if (!workingMap) return;
        const { isValid, tableData } = getAndValidate();
        if (!isValid) return;
        exportAsCsv(planName!, tableData!);
    };

    const onClickDeleteRow = (id: string | number) => {
        if (drawRef.current) drawRef.current.removeById(id);
        setBeaconData((prevData) => prevData.filter((item) => item.id !== id));
    };

    /**
     * Helper methods
     */
    const replaceAllByKey = (key: string) => {
        const autoConf = autoFillConfRef.current;
        const mValue = (autoConf as any)[key];
        if (tableRef.current) {
            const targetIds = selectedArea
                ? beaconData
                      .filter((el) => inside([el.lng, el.lat], selectedArea))
                      .map((x) => x.id.toString())
                : undefined;
            const replaced = tableRef.current.replaceAll(key, mValue, targetIds);
            if (replaced > 0) messageApi.info(`${replaced} records replaced.`);
        }
    };
    const removeSelectedBeacons = () => {
        if (tableRef.current) {
            const insideIds: any[] = [];
            const outsideBeacons = selectedArea
                ? beaconData.filter((el) => {
                      const isInside = inside([el.lng, el.lat], selectedArea);
                      if (isInside) insideIds.push(el.id);
                      return !isInside;
                  })
                : beaconData;
            const diffCount = beaconData.length - outsideBeacons.length;
            if (diffCount > 0) {
                if (drawRef) drawRef.current.removeById(insideIds);
                setBeaconData(outsideBeacons);
                messageApi.info(`${diffCount} beacon removed.`);
            }
        }
    };

    const reconstructData = () => {
        if (!tableRef.current) return undefined;
        const mData = tableRef.current.getData();
        if (!mData?.result) return undefined;
        const result = [...beaconData];
        Object.entries(mData.result).forEach(([key, value]) => {
            const mIndex = result.findIndex((x) => `${x.id}` === `${value.id}`);
            if (mIndex > -1) {
                result[mIndex] = {
                    ...result[mIndex],
                    ...value,
                    id: result[mIndex].id,
                    weight: value?.weight ?? result[mIndex].weight,
                    threshold: value?.threshold ?? result[mIndex].threshold,
                };
            } else {
                result.push(value);
            }
        });
        return result;
    };

    const getAndValidate = () => {
        setErrorMsg(undefined);
        const _showError = (msg: string) => {
            messageApi.error(msg);
            setErrorMsg(msg);
            return { isValid: false, tableData: undefined, planName: undefined };
        };

        if (!planName || planName.trim() === '') {
            return _showError('Please assign a beacon plan name');
        }
        const tableData = reconstructData();
        if (!tableData || tableData.length == 0) {
            return _showError('Please add at least one beacon');
        }
        const isValid = tableData.every(
            (data) =>
                data.uuid &&
                data.major !== undefined &&
                data.minor !== undefined &&
                data.weight !== undefined &&
                data.threshold !== undefined &&
                data.lat !== 0 &&
                data.lng !== 0,
        );
        if (!isValid) {
            return _showError('Please fill all required fields in the table.');
        }

        return { isValid, tableData };
    };

    /**
     * Panel component (Left)
     */
    const renderPanel = () => {
        return (
            <ProCard
                colSpan={panelCollapsed ? '12px' : '25%'}
                style={{ height: '100%', overflowY: 'auto', borderRadius: 0, minWidth: '20vw' }}
                ghost
                split="horizontal"
            >
                <FloorSelector
                    warnIds={venue?.maps.filter((x) => !x.beaconPlanId).map((y) => y.id)}
                />
                {workingMap ? renderPanelContent() : undefined}
                {selectedPlanId ? (
                    <div
                        className="collapse-btn-wrapper"
                        onClick={() => setPanelCollapsed((x) => !x)}
                        style={panelCollapsed ? { left: 0 } : { right: 0 }}
                        title={panelCollapsed ? 'Expand' : 'Collapse'}
                    >
                        {panelCollapsed ? <RightOutlined /> : <LeftOutlined />}
                    </div>
                ) : undefined}
            </ProCard>
        );
    };

    const renderPanelContent = () => {
        const planSelectSection = (
            <div style={{ padding: '12px 12px 0 12px' }}>
                <Text strong>Beacon plan:</Text>
                <Flex gap={16}>
                    <Select
                        placeholder="Select a beacon plan"
                        style={{ flex: 1 }}
                        onChange={(beaconPlanId) => {
                            setSelectPlanId(beaconPlanId);
                            fetchBeaconPlan(beaconPlanId);
                        }}
                        options={(beaconPlanList ?? []).map((beaconPlan) => ({
                            label: (
                                <Badge
                                    color={beaconPlan.isActive ? 'green' : 'transparent'}
                                    text={beaconPlan.name}
                                />
                            ),
                            value: beaconPlan.id,
                        }))}
                        value={selectedPlanId}
                        size="small"
                    />
                    {isEditor ? (
                        <Button
                            size="small"
                            onClick={onCreateNewPlan}
                            style={{ marginLeft: 'auto' }}
                        >
                            New
                        </Button>
                    ) : undefined}
                </Flex>
                {isEditor && selectedPlanId ? (
                    <Space className="w100" style={{ padding: '8px 0' }}>
                        <Upload
                            name="beaconsCsv"
                            showUploadList={false}
                            beforeUpload={() => false}
                            fileList={[]}
                            onChange={handleOnFileChange}
                            accept=".csv"
                        >
                            <Button size="small" className="w100" icon={<UploadOutlined />}>
                                {selectedPlanId === '<NEW>' ? 'Import' : 'Upload'} beacon plan
                            </Button>
                        </Upload>
                        <ViewDocumentModule docName="beaconPlanFormat.md" />
                    </Space>
                ) : undefined}
            </div>
        );

        return (
            <Flex style={{ padding: '0 12px 24px 12px' }} vertical gap="1em">
                {planSelectSection}
                {isEditor && selectedPlanId ? renderPlanConfig() : undefined}
            </Flex>
        );
    };

    const renderPlanConfig = () => {
        const infoSection = (
            <>
                <Flex gap="1em" align="center">
                    <Text>Name</Text>
                    <Input
                        style={{ width: '60%', marginLeft: 'auto' }}
                        size="small"
                        value={planName}
                        onChange={(e) => setPlanName(e.target.value)}
                    />
                </Flex>
                <Flex gap="0.5em" align="center" style={{ marginTop: 6 }}>
                    <Text>Status</Text>
                    <span style={{ marginLeft: 'auto' }}>
                        {planStatus == 0 ? (
                            <Tag color="red">NOT SAVED</Tag>
                        ) : planStatus == 1 ? (
                            <Tag>SAVED</Tag>
                        ) : (
                            <Tag color="green">LIVE</Tag>
                        )}
                    </span>
                </Flex>
            </>
        );
        const activeBtn = !!beaconPlan ? (
            <Button
                onClick={() => onToggleActivePlan(!beaconPlan.isActive)}
                size="small"
                className="w100"
                style={{ marginTop: 6 }}
            >
                {beaconPlan.isActive ? 'Deactivate beacon plan' : 'Active beacon plan'}
            </Button>
        ) : (
            <></>
        );
        const paramSection = (
            <Flex vertical gap={4}>
                <Flex gap="0.5em" align="center">
                    <Text>Proximity UUID</Text>
                    <Input
                        style={{ width: '50%', marginLeft: 'auto' }}
                        size="small"
                        placeholder="UUID to replace"
                        defaultValue={autoFillConfRef.current.uuid}
                        onChange={(e) => {
                            autoFillConfRef.current.uuid = e.target.value;
                        }}
                    />
                    <Button
                        size="small"
                        title="Replace all"
                        onClick={() => replaceAllByKey('uuid')}
                        icon={<RetweetOutlined />}
                    />
                </Flex>

                {!isSuperAdmin() ? undefined : (
                    <>
                        <Flex gap="0.5em" align="center">
                            <Text>Scan Threshold</Text>
                            <Input
                                type="digit"
                                style={{ width: '5em', marginLeft: 'auto' }}
                                size="small"
                                placeholder="Threshold to replace"
                                defaultValue={autoFillConfRef.current.threshold}
                                onChange={(e) => {
                                    if (!isNaN(parseFloat(e.target.value))) {
                                        autoFillConfRef.current.threshold = parseFloat(
                                            e.target.value,
                                        );
                                    }
                                }}
                            />
                            <Button
                                size="small"
                                title="Replace all"
                                onClick={() => replaceAllByKey('threshold')}
                                icon={<RetweetOutlined />}
                            />
                        </Flex>
                        <Flex gap="0.5em" align="center">
                            <Text>Weight</Text>
                            <Input
                                type="digit"
                                style={{ width: '5em', marginLeft: 'auto' }}
                                size="small"
                                placeholder="Weight to replace"
                                defaultValue={autoFillConfRef.current.weight}
                                onChange={(e) => {
                                    if (!isNaN(parseFloat(e.target.value))) {
                                        autoFillConfRef.current.weight = parseFloat(e.target.value);
                                    }
                                }}
                            />
                            <Button
                                size="small"
                                title="Replace all"
                                onClick={() => replaceAllByKey('weight')}
                                icon={<RetweetOutlined />}
                            />
                        </Flex>
                    </>
                )}

                <Flex gap="0.5em" align="center">
                    <Text>Low Power Beacon</Text>
                    <Switch
                        style={{ marginLeft: 'auto' }}
                        size="small"
                        defaultChecked={autoFillConfRef.current.isLowPower}
                        onChange={(e) => {
                            autoFillConfRef.current.isLowPower = e;
                        }}
                    />
                    <Button
                        size="small"
                        title="Replace all"
                        onClick={() => replaceAllByKey('isLowPower')}
                        icon={<RetweetOutlined />}
                    />
                </Flex>

                {selectedArea ? (
                    <Button
                        size="small"
                        title="Remove"
                        danger
                        icon={<DeleteOutlined />}
                        onClick={removeSelectedBeacons}
                    >
                        Remove selected Beacons
                    </Button>
                ) : undefined}
            </Flex>
        );
        const actionSection = (
            <Flex gap="1em" align="center" style={{ marginTop: '2em' }}>
                {beaconPlan ? (
                    <Button onClick={onClickDeletePlan} danger type="text">
                        Delete
                    </Button>
                ) : undefined}

                <Button style={{ marginLeft: 'auto' }} onClick={onClickExport}>
                    Export
                </Button>
                <Button type="primary" onClick={onClickSave}>
                    Save
                </Button>
            </Flex>
        );
        if (!hasChangeRef.current) {
            hasChangeRef.current = initDataRef.current !== JSON.stringify(beaconData ?? []);
        }
        return (
            <>
                {hasChangeRef.current ? (
                    <Text type="warning">Note: You have unsaved change.</Text>
                ) : undefined}
                <ProCard
                    size="small"
                    direction="column"
                    style={{
                        backgroundColor: 'rgba(0, 0, 0, 0.02)',
                        display: panelCollapsed ? 'none' : 'block',
                    }}
                    bordered
                >
                    {infoSection}
                    {activeBtn}
                    {actionSection}
                    {errorMsg ? <Text type="danger">{errorMsg}</Text> : undefined}
                </ProCard>
                <ProCard
                    size="small"
                    direction="column"
                    style={{
                        display: panelCollapsed ? 'none' : 'block',
                        backgroundColor: selectedArea ? '#fdf6ea' : undefined,
                        borderColor: selectedArea ? '#fbb03c' : undefined,
                    }}
                    bordered
                    title={
                        <Space style={{ fontWeight: 'bold' }}>
                            <ToolOutlined />
                            <Text>{selectedArea ? 'Selected' : 'Default'} Beacon parameters</Text>
                        </Space>
                    }
                    extra={[
                        <Tooltip
                            key="def-ext-select"
                            title="Click to draw an area"
                            open={selectedArea === null}
                            placement="right"
                        >
                            <Button
                                title={selectedArea ? 'Cancel selection' : 'Polygonal Lasso Tool'}
                                size="small"
                                icon={
                                    selectedArea ? (
                                        <CloseOutlined />
                                    ) : (
                                        <CustomIcon icon="select" size={16} />
                                    )
                                }
                                onClick={() => {
                                    if (selectorRef.current) {
                                        if (selectedArea) {
                                            selectorRef.current.resetAll();
                                            setSelectedArea(undefined);
                                        } else {
                                            selectorRef.current.addPolygon();
                                            setSelectedArea(null);
                                        }
                                    }
                                }}
                            />
                        </Tooltip>,
                    ]}
                >
                    {paramSection}
                </ProCard>
            </>
        );
    };

    /**
     * Panel component (Right)
     */
    const renderContent = () => {
        if (!selectedPlanId) {
            return (
                <div style={{ width: '100%', padding: 24, backgroundColor: '#eee' }}>
                    <span>Select floor plan and beacon plan to continue.</span>
                </div>
            );
        }
        return (
            <Flex vertical style={{ flex: 1 }}>
                <div style={{ minHeight: '25vh', boxShadow: '3px 0px 5px 0px #69696980' }}>
                    {renderTableArea()}
                </div>
                <Flex style={{ flex: 1 }}>{renderMapSection()}</Flex>
            </Flex>
        );
    };

    const renderTableArea = () => {
        return (
            <ProCard
                title={`Beacons (${beaconData.length})`}
                extra={
                    isEditor
                        ? [
                              <Button
                                  key="add-b-btn"
                                  onClick={onClickAddBeacon}
                                  icon={<PlusOutlined />}
                                  size="small"
                              >
                                  Add beacon
                              </Button>,
                          ]
                        : undefined
                }
                bodyStyle={{ margin: 0, padding: 0, width: '100%' }}
                size="small"
                headerBordered
            >
                <BeaconTableSection
                    ref={tableRef as any}
                    editable={isEditor}
                    data={beaconData}
                    sourceId={sourceIdRef.current}
                    onLocate={(id) => {
                        if (drawRef.current) drawRef.current.flyTo(id);
                    }}
                    onDelete={onClickDeleteRow}
                    onHover={(id) => {
                        if (drawRef.current) drawRef.current.setHoverItem(id);
                    }}
                    onSetMarker={(id, label) => {
                        if (drawRef.current) drawRef.current.addMarker(id, label);
                    }}
                    onChanged={setBeaconData}
                />
            </ProCard>
        );
    };

    const renderMapSection = () => {
        return (
            <div style={{ width: '100%', backgroundColor: '#eee' }}>
                {workingMap?.mapImg && workingMap?.mapAlign ? (
                    <MapBoxContainer center={workingMap?.mapAlign?.center}>
                        <MapBoxImageLayer
                            imgSrc={workingMap.mapImg}
                            positions={[
                                workingMap.mapAlign.corners.tl,
                                workingMap.mapAlign.corners.tr,
                                workingMap.mapAlign.corners.br,
                                workingMap.mapAlign.corners.bl,
                            ]}
                            fitBounds
                        />
                        <MapBoxMarkerLayer
                            ref={drawRef as any}
                            editable={isEditor}
                            onHover={(id) => {
                                if (tableRef.current) tableRef.current.setHoverId(id);
                            }}
                            onClick={({ id }) => {
                                if (tableRef.current) tableRef.current.scrollToId(id);
                            }}
                            onChanged={(item) => {
                                setBeaconData((prev) =>
                                    prev.map((el, idx) => {
                                        return `${el.id}` === `${item.id}`
                                            ? { ...el, lat: item.lat, lng: item.lng }
                                            : el;
                                    }),
                                );
                            }}
                        />
                        <MapBoxDrawLayer
                            ref={selectorRef as any}
                            onChanged={(id, feat) => {
                                setSelectedArea(feat);
                            }}
                        />
                    </MapBoxContainer>
                ) : (
                    <Empty image={false} description="No map data :(" style={{ marginTop: '10%' }}>
                        Click <Link to={`/project/venue/${workingMap?.venueId}/map`}>here</Link> to
                        add a floor plan first.
                    </Empty>
                )}
            </div>
        );
    };

    return (
        <ProCard style={{ height: '100%' }} split={'vertical'} className="full-content-height">
            {contextHolder}
            {renderPanel()}
            {renderContent()}
            <LoadingOverlay visible={isLoading || isSaving} />
        </ProCard>
    );
};

export default VenueBeaconPlannerScreen;
