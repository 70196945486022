import 'mapbox-gl/dist/mapbox-gl.css';
import 'styles/map-style.css';
import { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';
import { RouteDataDto, RouteDto, TraceSession, VenuePreviewDto } from 'apis/TraceApi';
import L from 'leaflet';
import { useLeafletContext } from '@react-leaflet/core';
import { MapContainer, Marker, Popup, Polyline, Circle, Tooltip } from 'react-leaflet';
import 'leaflet-rotatedmarker';
import * as turf from '@turf/turf';
import moment from 'moment';
import ImageOverlayRotated from './ImageOverlayRotated';
import { Space, Typography } from 'antd';
const { Text } = Typography;
import { formatTime, formatTimeEvenNagative } from 'utils/DateUtils';
import MapTileControl from './MapTileControl';

const BlueDotIcon = L.icon({
    iconUrl:
        'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNzgiIGhlaWdodD0iNzgiIHZpZXdCb3g9IjAgMCA3OCA3OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMzkiIGN5PSIzOSIgcj0iMjYiIGZpbGw9ImJsYWNrIiBmaWxsLW9wYWNpdHk9IjAuMSIvPgo8Y2lyY2xlIGN4PSIzOSIgY3k9IjM5IiByPSIyNSIgZmlsbD0iYmxhY2siIGZpbGwtb3BhY2l0eT0iMC4xIi8+CjxjaXJjbGUgY3g9IjM5IiBjeT0iMzkiIHI9IjI0IiBmaWxsPSJ3aGl0ZSIvPgo8Y2lyY2xlIGN4PSIzOSIgY3k9IjM5IiByPSIxNiIgZmlsbD0iIzFGNjVFQyIvPgo8L3N2Zz4K',
    iconSize: [39, 39],
});
const BlueDotDirectionIcon = L.icon({
    iconUrl:
        'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgwIiBoZWlnaHQ9IjE4MCIgdmlld0JveD0iMCAwIDE4MCAxODAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMDUuNzEgNjQuNDM3MUw4OS45OTk5IDQwTDc0LjI5MDQgNjQuNDM3MUM3OC44NTk1IDYxLjYyMzIgODQuMjQwMiA2MCA4OS45OTk5IDYwQzk1Ljc1OTcgNjAgMTAxLjE0IDYxLjYyMzIgMTA1LjcxIDY0LjQzNzFaIiBmaWxsPSJ3aGl0ZSIvPgo8cGF0aCBkPSJNMTA1LjE4NSA2NS4yODg2QzEwNS41ODMgNjUuNTMzMyAxMDYuMDk3IDY1LjQ3MDYgMTA2LjQyMyA2NS4xMzczQzEwNi43NSA2NC44MDQxIDEwNi44MDMgNjQuMjg5IDEwNi41NTEgNjMuODk2M0w5MC44NDExIDM5LjQ1OTJDOTAuNjU3MSAzOS4xNzMgOTAuMzQwMiAzOSA4OS45OTk5IDM5Qzg5LjY1OTcgMzkgODkuMzQyOCAzOS4xNzMgODkuMTU4OCAzOS40NTkyTDczLjQ0OTIgNjMuODk2M0M3My4xOTY4IDY0LjI4OSA3My4yNDk2IDY0LjgwNCA3My41NzY1IDY1LjEzNzNDNzMuOTAzNCA2NS40NzA2IDc0LjQxNzMgNjUuNTMzMyA3NC44MTQ4IDY1LjI4ODVDNzkuMjMwNyA2Mi41NjkgODQuNDMwNiA2MSA4OS45OTk5IDYxQzk1LjU2OTMgNjEgMTAwLjc2OSA2Mi41NjkgMTA1LjE4NSA2NS4yODg2WiIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLW9wYWNpdHk9IjAuMSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0xMDUuNzEgNjQuNDM3MUw4OS45OTk5IDQwTDc0LjI5MDQgNjQuNDM3MUM3OC44NTk1IDYxLjYyMzIgODQuMjQwMiA2MCA4OS45OTk5IDYwQzk1Ljc1OTcgNjAgMTAxLjE0IDYxLjYyMzIgMTA1LjcxIDY0LjQzNzFaIiBmaWxsPSJ3aGl0ZSIvPgo8cGF0aCBkPSJNMTA1LjQ0NyA2NC44NjI4QzEwNS42NDYgNjQuOTg1MiAxMDUuOTAzIDY0Ljk1MzggMTA2LjA2NiA2NC43ODcyQzEwNi4yMyA2NC42MjA2IDEwNi4yNTYgNjQuMzYzIDEwNi4xMyA2NC4xNjY3TDkwLjQyMDUgMzkuNzI5NkM5MC4zMjg1IDM5LjU4NjUgOTAuMTcwMSAzOS41IDg5Ljk5OTkgMzkuNUM4OS44Mjk4IDM5LjUgODkuNjcxNCAzOS41ODY1IDg5LjU3OTQgMzkuNzI5Nkw3My44Njk4IDY0LjE2NjdDNzMuNzQzNiA2NC4zNjMgNzMuNzcgNjQuNjIwNiA3My45MzM0IDY0Ljc4NzJDNzQuMDk2OSA2NC45NTM4IDc0LjM1MzkgNjQuOTg1MiA3NC41NTI2IDY0Ljg2MjhDNzkuMDQ1MSA2Mi4wOTYxIDg0LjMzNTQgNjAuNSA4OS45OTk5IDYwLjVDOTUuNjY0NSA2MC41IDEwMC45NTUgNjIuMDk2MSAxMDUuNDQ3IDY0Ljg2MjhaIiBzdHJva2U9ImJsYWNrIiBzdHJva2Utb3BhY2l0eT0iMC4xIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=',
    iconSize: [90, 90],
});

const getGroundTruthIcon = (num: number, color?: string): L.DivIcon => {
    const mHtml = `<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="${color ?? '#7272cf'}"> <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0" /> <circle cx="12" cy="9" r="5.5" fill="#fff" /> <text x="12" y="12" text-anchor="middle" font-family="monospace" font-stretch="condensed" font-size="7.5">${num}</text></svg>`;
    const encodedData = 'data:image/svg+xml;base64,' + window.btoa(mHtml);
    return L.icon({
        iconUrl: encodedData,
        iconSize: [48, 48],
        iconAnchor: [24, 48],
        shadowUrl: 'https://unpkg.com/leaflet@1.9.4/dist/images/marker-shadow.png',
        shadowSize: [50, 50],
        shadowAnchor: [16, 54],
        popupAnchor: [0, -36],
    });
};

const JourneyLayer: React.FC<{
    placeholder: [number, number][];
    fillRoute: [number, number][];
}> = ({ placeholder, fillRoute }) => {
    const context = useLeafletContext();
    useEffect(() => {
        if (placeholder.length > 1) {
            const bbox = turf.bbox(turf.lineString(placeholder));
            context.map.flyToBounds(
                [
                    [bbox[0], bbox[1]],
                    [bbox[2], bbox[3]],
                ],
                { duration: 1, padding: [10, 10] },
            );
        }
    }, [placeholder]);
    return (
        <>
            <Polyline
                key={'r-placeholder'}
                pathOptions={{ color: '#888888', opacity: 0.7 }}
                positions={placeholder}
            />
            <Polyline
                key={'r-progress'}
                pathOptions={{ color: '#1f91ff', opacity: 1 }}
                positions={fillRoute}
            />
        </>
    );
};

const BlueDotLayer: React.FC<{ current: RouteDataDto }> = ({ current }) => {
    const directionRef: any = useRef();
    useEffect(() => {
        directionRef.current.setRotationAngle(current.bea);
    }, [current.bea]);
    return (
        <>
            <Circle
                key={'mak-acc'}
                center={[current.lat, current.lng]}
                pathOptions={{
                    fillColor: '#18CAD6',
                    fillOpacity: 0.2,
                    color: '#8ED5DE',
                    opacity: 0.5,
                }}
                radius={current.acc}
                interactive={false}
            />
            <Marker
                ref={directionRef}
                key={'mak-dir'}
                position={[current.lat, current.lng]}
                icon={BlueDotDirectionIcon}
                rotationOrigin="center"
                interactive={false}
            />
            <Marker
                key={'mak-dot'}
                position={[current.lat, current.lng]}
                icon={BlueDotIcon}
                interactive={false}
            />
        </>
    );
};

export type PlaybackMapViewProps = {
    route?: RouteDto;
    progressIndex?: number;
    traceDetail?: TraceSession;
    style?: CSSProperties;
    overrideMs: number;
    duration: number;
};

export const PlaybackMapView: React.FC<PlaybackMapViewProps> = ({
    route,
    progressIndex,
    style,
    traceDetail,
    overrideMs,
    duration,
}) => {
    const [subRoute, setSubRoute] = useState<[number, number][]>([]);
    const [current, setCurrent] = useState<RouteDataDto | undefined>(undefined);
    const [floorPlan, setFloorPlan] = useState<VenuePreviewDto>();
    const mStartTs = traceDetail?.detail.startAt ?? 0;

    useEffect(() => {
        if (!!route && progressIndex != undefined) {
            const subList = (route._coordinates ?? []).slice(0, progressIndex + 1 ?? Infinity);
            setSubRoute(subList ?? []);
            setCurrent(route.data[progressIndex] as RouteDataDto);

            const mFId = route.data[progressIndex]?.fid;
            if (!!traceDetail && !!mFId && mFId !== floorPlan?.id) {
                const info = traceDetail.venue.find((el) => el.id === mFId);
                if (!!info && info.imageUrl && info.mapAlign) {
                    setFloorPlan(info);
                } else {
                    setFloorPlan(undefined);
                }
            } else if (!mFId && !!floorPlan) {
                setFloorPlan(undefined);
            }
        }
    }, [progressIndex]);

    return (
        <div id="leaflet-map" style={{ position: 'relative', height: '300px', ...style }}>
            <MapContainer
                center={[22.3193, 114.1694]}
                zoom={10}
                scrollWheelZoom={true}
                zoomControl={false}
                style={{ height: '100%', flex: 1 }}
            >
                <MapTileControl initTier="CartoDB.Positron" visible={false} scale />
                <JourneyLayer placeholder={route?._coordinates ?? []} fillRoute={subRoute} />
                {(traceDetail?.marker ?? []).map((el, index) => {
                    if (!el?.data) return undefined;
                    const currTs = mStartTs + overrideMs;
                    return (
                        <Marker
                            position={el.data}
                            key={`mk-${index}`}
                            icon={getGroundTruthIcon(
                                index + 1,
                                currTs >= el.ts ? undefined : '#999999',
                            )}
                        >
                            <Tooltip direction="top" offset={[0, -48]}>
                                {moment(el.ts).tz(traceDetail!.detail.timeZone).format()}
                            </Tooltip>
                            <Popup>
                                {moment(el.ts).tz(traceDetail!.detail.timeZone).format()}
                                <p style={{ whiteSpace: 'pre-line' }}>{el.name}</p>
                            </Popup>
                        </Marker>
                    );
                })}
                {floorPlan?.imageUrl && floorPlan.mapAlign ? (
                    <ImageOverlayRotated
                        imgSrc={floorPlan.imageUrl}
                        positions={[
                            floorPlan.mapAlign.corners?.tl,
                            floorPlan.mapAlign.corners?.tr,
                            floorPlan.mapAlign.corners?.br,
                            floorPlan.mapAlign.corners?.bl,
                        ]}
                        fitBounds={false}
                    />
                ) : undefined}
                {current?.lat && current?.lng ? <BlueDotLayer current={current} /> : undefined}
            </MapContainer>

            {overrideMs < 0 ? (
                <Space
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        backgroundColor: '#000000',
                        justifyContent: 'center',
                        color: 'white',
                        fontSize: 20,
                        opacity: 0.7,
                        pointerEvents: 'none',
                        zIndex: 999,
                    }}
                >
                    <Text style={{ color: 'white', fontSize: 20, textAlign: 'center' }}>
                        <div>Playback start in</div>
                        <div>{formatTimeEvenNagative(overrideMs - 1000)}</div>
                    </Text>
                </Space>
            ) : overrideMs > duration ? (
                <Space
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        backgroundColor: '#000000',
                        justifyContent: 'center',
                        color: 'white',
                        fontSize: 20,
                        opacity: 0.7,
                        pointerEvents: 'none',
                        zIndex: 999,
                    }}
                >
                    <Text style={{ color: 'white', fontSize: 20, textAlign: 'center' }}>
                        <div>Playback start in</div>
                        <div>{formatTimeEvenNagative(overrideMs - duration)}</div>
                    </Text>
                </Space>
            ) : (
                <></>
            )}
        </div>
    );
};
