import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useLeafletContext } from '@react-leaflet/core';
import * as L from 'leaflet';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'styles/map-style.css';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import { Button, Flex, Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import * as turf from '@turf/turf';
import CustomIcon from 'components/CustomIcon';
import { CONTROL_POSITION_CLASSES } from 'services/BaseMapEngine';
const { Text } = Typography;

export type MeasurementToolRef = {
    startMeasurement: () => void;
};

type MeasurementToolProps = {
    ref?: MeasurementToolRef;
    onChange?: (distance: number) => void;
    showControl?: boolean;
};

const font95 = { fontSize: 12 };
const MeasurementTool = React.forwardRef<MeasurementToolRef, MeasurementToolProps>((props, ref) => {
    const context = useLeafletContext();
    const polyline = useRef<L.Polyline>();
    const [distance, setDistance] = useState<number>(0);
    const [visible, setVisible] = useState<boolean>(false);

    function _handleClose() {
        setVisible(false);
        setDistance(0);
        const container = context.map;
        if (!!polyline.current) {
            polyline.current.removeFrom(container);
            polyline.current = undefined;
        }
    }

    useEffect(() => {
        L.PM.setOptIn(true);
        const container = context.map;
        // Setup listeners
        container.on('pm:create', (e) => {
            if (e.shape !== 'Line') return;
            const mPolyline = e.layer as L.Polyline;
            polyline.current = mPolyline;
            const length = _calculateDistance(mPolyline.getLatLngs() as L.LatLng[]);
            mPolyline.bindTooltip(`${length.toFixed(2)} m`, { sticky: true }).openTooltip();
            if (props.onChange) props.onChange(distance);
        });
        container.on('pm:drawstart', ({ workingLayer }) => {
            workingLayer.on('pm:change', (e) => {
                if (e.shape !== 'Line') return;
                const length = _calculateDistance(e.latlngs as L.LatLng[]);
                setDistance(length);
            });
        });
        return () => L.PM.setOptIn(false);
    }, []);

    function _calculateDistance(positions: L.LatLng[]) {
        if (positions.length > 1) {
            const line = turf.lineString(positions.map((el) => [el.lng, el.lat]));
            return turf.length(line, { units: 'meters' });
        }
        return 0;
    }

    function _startMeasure() {
        const container = context.map;
        if (!!polyline.current) {
            polyline.current.removeFrom(container);
            polyline.current = undefined;
        }
        if (!container?.pm?.enableDraw) return;
        setDistance(0);
        setVisible(true);
        container.pm.enableDraw('Line', {
            markerEditable: true,
            continueDrawing: false,
            pathOptions: { color: '#f71b7e' },
        });
    }

    useImperativeHandle(ref, () => ({
        startMeasurement() {
            _startMeasure();
        },
    }));

    function _renderDistancePopup() {
        const msg = `Total distance: ${distance.toFixed(2)} m (${(distance * 3.28084).toFixed(2)} ft)`;
        return (
            <Flex className="map-toolbar map-center-toolbar" style={{ width: 300 }} vertical>
                <Flex justify="space-between" align="center">
                    <Text style={font95} strong>
                        Measure distance
                    </Text>
                    <Button
                        type="text"
                        size="small"
                        icon={<CloseOutlined />}
                        title="Close"
                        style={{ color: 'gray' }}
                        onClick={_handleClose}
                    />
                </Flex>
                <Flex vertical style={{ textAlign: 'left' }}>
                    <Text style={font95}>{msg}</Text>
                    <sub style={{ color: 'gray' }}>
                        Click on the map to trace a path you want to measure
                    </sub>
                </Flex>
            </Flex>
        );
    }

    return (
        <>
            {props.showControl ? (
                <div className={CONTROL_POSITION_CLASSES.topleft}>
                    <div className="leaflet-control">
                        <Button
                            icon={<CustomIcon icon="ruler" size={24} />}
                            size="large"
                            onClick={_startMeasure}
                            title="Measure distance"
                        />
                    </div>
                </div>
            ) : undefined}
            {visible ? _renderDistancePopup() : undefined}
        </>
    );
});

export default MeasurementTool;
