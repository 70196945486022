import React from 'react';
import { MapContainer, Circle, Polygon, Marker } from 'react-leaflet';
import { LatLngDto } from 'apis/VenueApi';
import MapTileControl from './MapTileControl';

type VenueBoundMapViewProps = {
    center: LatLngDto;
    radius?: number;
    polygon?: [number, number][];
    zoom?: number;
};

const VenueBoundMapView: React.FC<VenueBoundMapViewProps> = (props) => {
    return (
        <MapContainer
            center={[props.center.lat, props.center.lng]}
            zoom={props.zoom ?? 15}
            scrollWheelZoom={true}
            zoomControl={false}
            style={{ height: '100%', flex: 1 }}
        >
            <MapTileControl visible={false} />
            {props.polygon ? (
                <Polygon
                    positions={props.polygon.map((el) => [el[1], el[0]])}
                    interactive={false}
                />
            ) : undefined}
            <Circle
                center={[props.center.lat, props.center.lng]}
                radius={props.radius ?? 100}
                color="purple"
                interactive={false}
            />
            <Marker position={[props.center.lat, props.center.lng]} interactive={false} />
        </MapContainer>
    );
};

export default VenueBoundMapView;
