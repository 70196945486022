import React, { memo, useEffect, useState } from 'react';
import { MapContainer, GeoJSON } from 'react-leaflet';
import ImageOverlayRotated from 'components/maps/ImageOverlayRotated';
import { AlignmentDto, LatLngDto } from 'apis/VenueApi';
import MapTileControl, { MapTileControlProps } from './MapTileControl';

type FloorPlanMapViewProps = {
    imageUrl?: string;
    mapAlign?: AlignmentDto;
    geojson?: any;
    initCenter: LatLngDto;
    opacity?: number;
    grayscale?: boolean;
    layerControl?: boolean | MapTileControlProps;
    children?: string | JSX.Element | JSX.Element[];
};

const FloorPlanMapView: React.FC<FloorPlanMapViewProps> = (props) => {
    const [geoJsonObj, setGeoJsonObj] = useState<any | undefined>(undefined);
    useEffect(() => {
        if (props.geojson) {
            if (typeof props.geojson === 'string' && props.geojson?.includes('Feature')) {
                try {
                    const obj = JSON.parse(props.geojson);
                    setGeoJsonObj(obj);
                } catch (ex) {
                    /* do nothing */
                }
            } else if (Array.isArray(props.geojson)) {
                setGeoJsonObj({
                    type: 'FeatureCollection',
                    features: [
                        {
                            type: 'Feature',
                            geometry: {
                                coordinates: [props.geojson],
                                type: 'Polygon',
                            },
                        },
                    ],
                });
            }
        }
    }, [props.geojson]);

    const layerControlProps =
        typeof props.layerControl === 'object'
            ? props.layerControl
            : { visible: props.layerControl !== false };
    return (
        <MapContainer
            center={[props.initCenter.lat, props.initCenter.lng]}
            zoomSnap={0.7}
            zoom={18}
            scrollWheelZoom={true}
            zoomControl={false}
            style={{ height: '100%', flex: 1 }}
        >
            <MapTileControl {...layerControlProps} />
            {props.children}
            {props?.geojson && geoJsonObj ? (
                <GeoJSON
                    key={`geo-json-layer`}
                    data={geoJsonObj}
                    style={{ color: 'purple' }}
                    interactive={false}
                />
            ) : undefined}
            {props.imageUrl && props.mapAlign ? (
                <ImageOverlayRotated
                    imgSrc={props.imageUrl}
                    opacity={props.opacity}
                    positions={[
                        props.mapAlign.corners.tl,
                        props.mapAlign.corners.tr,
                        props.mapAlign.corners.br,
                        props.mapAlign.corners.bl,
                    ]}
                    fitBounds={true}
                    grayscale={props.grayscale}
                />
            ) : undefined}
        </MapContainer>
    );
};

export default memo(FloorPlanMapView);
