import React from 'react';
import { HashRouter } from 'react-router-dom';
import './styles/App.css';
import AuthProvider from './providers/AuthProvider';
import AppRouter from './router';
import AppContextProvider from 'providers/AppStateProvider';

function App() {
    return (
        <HashRouter>
            <AuthProvider>
                <AppContextProvider>
                    <AppRouter />
                </AppContextProvider>
            </AuthProvider>
        </HashRouter>
    );
}

export default App;
