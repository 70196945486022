import moment from 'moment';

export function formatDateAgo(dateString: string | Date): string {
    const currentDate = moment();
    const date = moment(dateString);

    const diff = currentDate.diff(date);

    const duration = moment.duration(diff);
    const years = duration.years();
    const months = duration.months();
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();

    let formattedString = '';
    if (years > 0) {
        formattedString += `${years} year${years > 1 ? 's' : ''} `;
    } else if (months > 0) {
        formattedString += `${months} month${months > 1 ? 's' : ''} `;
    } else if (days > 0) {
        formattedString += `${days} day${days > 1 ? 's' : ''} `;
    } else if (hours > 0) {
        formattedString += `${hours} hour${hours > 1 ? 's' : ''} `;
    } else if (minutes >= 0) {
        formattedString += `${minutes} minute${minutes > 1 ? 's' : ''} `;
    }
    formattedString += 'ago';

    return formattedString.trim();
}

export function formatTime(input: number) {
    let isNagative = false;
    if(input < 0){
        isNagative = true;
        input *= -1;
        input += 1000;
    }
    const twoDigit = (val: number) => (val < 10 ? `0${val}` : val);
    const duration = moment.duration(input);
    if (duration.hours() > 0) {
        return `${isNagative?"-":""}${twoDigit(duration.hours())}:${twoDigit(duration.minutes())}:${twoDigit(duration.seconds())}`;
    } else {
        return `${isNagative?"-":""}${twoDigit(duration.minutes())}:${twoDigit(duration.seconds())}`;
    }
}

export function formatTimeEvenNagative(input: number) {
    if(input < 0){
        input *= -1;
    }
    const twoDigit = (val: number) => (val < 10 ? `0${val}` : val);
    const duration = moment.duration(input);
    if (duration.hours() > 0) {
        return `${twoDigit(duration.hours())}:${twoDigit(duration.minutes())}:${twoDigit(duration.seconds())}`;
    } else {
        return `${twoDigit(duration.minutes())}:${twoDigit(duration.seconds())}`;
    }
}