import React, { useState, useEffect } from 'react';

interface CachedImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    src: string;
}
const CachedImage: React.FC<CachedImageProps> = ({ src, ...props }) => {
    const [imageSrc, setImageSrc] = useState<string | null>(null);

    useEffect(() => {
        const loadImage = async () => {
            if ('caches' in window) {
                try {
                    const cache = await caches.open('map-img-cache');
                    const cachedResponse = await cache.match(src);

                    if (cachedResponse) {
                        const blob = await cachedResponse.blob();
                        setImageSrc(URL.createObjectURL(blob));
                    } else {
                        const response = await fetch(src);
                        const blob = await response.blob();
                        setImageSrc(URL.createObjectURL(blob));

                        cache.put(src, new Response(blob));
                    }
                } catch (error) {
                    console.error('Error caching image:', error);
                    setImageSrc(src);
                }
            } else {
                setImageSrc(src);
            }
        };

        loadImage();

        return () => {
            if (imageSrc && imageSrc.startsWith('blob:')) {
                URL.revokeObjectURL(imageSrc);
            }
        };
    }, [src]);

    return imageSrc ? <img src={imageSrc} {...props} /> : null;
};

export function cleanAllImgCache() {
    // Check if the caches API is available in the browser
    if ('caches' in window) {
        // Clear all caches
        caches
            .keys()
            .then((cacheNames) => {
                cacheNames.forEach((cacheName) => {
                    caches.delete(cacheName);
                });
            })
            .catch((error) => {
                /** do nothing */
            });
    }
}

export default CachedImage;
